import MainView from "../views/MainView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    children: [
      {
        path: "/oauth",
        name: "AuthOauthView",
        component: () => import("../views/AuthOauthView.vue"),
        children: [
          // 3rd party auth (eg. instagram basic api)
          {
            path: "instagram-basic-display",
            name: "AuthInstagramBasicDisplayView",
            component: () => import("../views/AuthInstagramBasicDisplayView.vue"),
          },
        ],
      },

      // booking
      {
        path: "booking",
        name: "BookingView",
        component: () => import("../views/BookingView.vue"),
        meta: {
          pageTitleKey: "view-title-booking",
        },
        children: [
          {
            path: "reservations",
            name: "BookingReservationsView",
            component: () => import("../views/BookingReservationsView.vue"),
            meta: {
              pageTitleKey: "view-title-booking-reservations",
            },
            children: [
              {
                path: "add",
                name: "BookingReservationsAddView",
                component: () => import("../views/BookingReservationsDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-reservations-add",
                },
              },
              {
                path: ":serviceId",
                name: "BookingReservationsDetailView",
                component: () => import("../views/BookingReservationsDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-reservations-detail",
                },
              },
            ],
          },
          {
            path: "slots",
            name: "BookingSlotsView",
            component: () => import("../views/BookingSlotsView.vue"),
            meta: {
              pageTitleKey: "view-title-booking-slots",
            },
            children: [
              {
                path: "add",
                name: "BookingSlotsAddView",
                component: () => import("../views/BookingSlotsDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-slots-add",
                },
              },
              {
                path: ":slotId",
                name: "BookingSlotsDetailView",
                component: () => import("../views/BookingSlotsDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-slots-detail",
                },
                children: [
                  {
                    path: "edit",
                    name: "BookingSlotsEditView",
                    component: () => import("../views/BookingSlotsDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-booking-slots-edit",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "services",
            name: "BookingServicesView",
            component: () => import("../views/BookingServicesView.vue"),
            meta: {
              pageTitleKey: "view-title-booking-services",
            },
            children: [
              {
                path: "add",
                name: "BookingServicesAddView",
                component: () => import("../views/BookingServicesDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-services-add",
                },
              },
              {
                path: ":serviceId",
                name: "BookingServicesDetailView",
                component: () => import("../views/BookingServicesDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-services-detail",
                },
                children: [
                  {
                    path: "edit",
                    name: "BookingServicesEditView",
                    component: () => import("../views/BookingServicesDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-booking-services-edit",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "resources",
            name: "BookingResourcesView",
            component: () => import("../views/BookingResourcesView.vue"),
            meta: {
              pageTitleKey: "view-title-booking-resources",
            },
            children: [
              {
                path: "add",
                name: "BookingResourcesAddView",
                component: () => import("../views/BookingResourcesDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-resources-add",
                },
              },
              {
                path: ":resourceId",
                name: "BookingResourcesDetailView",
                component: () => import("../views/BookingResourcesDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-booking-resources-detail",
                },
              },
            ],
          },
        ],
      },

      // headless cms
      {
        path: "cms",
        name: "CmsView",
        component: () => import("../views/CmsView.vue"),
        meta: {
          pageTitleKey: "view-title-cms",
        },
        children: [
          {
            path: "models",
            name: "CmsModelsView",
            component: () => import("../views/CmsModelsView.vue"),
            meta: {
              pageTitleKey: "view-title-cms-models",
            },
            children: [
              {
                path: ":modelId",
                name: "CmsModelsDetailView",
                component: () => import("../views/CmsModelsDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-cms-models-detail",
                },
                children: [
                  {
                    path: "fields",
                    name: "CmsModelsFieldsView",
                    component: () => import("../views/CmsModelsFieldsView.vue"),
                    meta: {
                      pageTitleKey: "view-title-cms-models-fields",
                    },
                    children: [
                      {
                        path: "add",
                        name: "CmsModelsFieldsAddView",
                        component: () => import("../views/CmsModelsFieldsAddView.vue"),
                        meta: {
                          pageTitleKey: "view-title-cms-models-fields-add",
                        },
                      },
                      {
                        path: ":fieldId",
                        name: "CmsModelsFieldsDetailView",
                        component: () => import("../views/CmsModelsFieldsAddView.vue"),
                        meta: {
                          pageTitleKey: "view-title-cms-models-fields-detail",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "add",
                name: "CmsModelsAddView",
                component: () => import("../views/CmsModelsAddView.vue"),
                meta: {
                  pageTitleKey: "view-title-cms-models-add",
                },
              },
            ],
          },
          {
            path: "entries",
            name: "CmsEntriesView",
            component: () => import("../views/CmsEntriesView.vue"),
            meta: {
              pageTitleKey: "view-title-cms-models-entries",
            },
            children: [
              {
                path: "add/locales/:localeSlug?",
                name: "CmsEntriesAddView",
                component: () => import("../views/CmsEntriesAddView.vue"),
                meta: {
                  pageTitleKey: "view-title-cms-models-entries-add",
                },
              },
              {
                path: ":entryId/versions/:entryVersionId/locales/:localeSlug?",
                name: "CmsEntriesVersionDetailView",
                component: () => import("../views/CmsEntriesVersionDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-cms-models-entries-version-detail",
                },
              },
            ],
          },
          {
            path: "tokens",
            name: "CmsTokensView",
            component: () => import("../views/CmsTokensView.vue"),
            meta: {
              pageTitleKey: "view-title-cms-tokens",
            },
            children: [
              {
                path: "add",
                name: "CmsTokensAddView",
                component: () => import("../views/CmsTokensAddView.vue"),
                meta: {
                  pageTitleKey: "view-title-cms-tokens-add",
                },
              },
            ],
          },
          {
            path: "tags",
            name: "CmsTagsView",
            component: () => import("../views/CmsTagsView.vue"),
            meta: {
              pageTitleKey: "view-title-cms-tags",
            },
            children: [
              {
                path: "add",
                name: "CmsTagsAddView",
                component: () => import("../views/CmsTagsAddView.vue"),
                meta: {
                  pageTitleKey: "view-title-cms-tags-add",
                },
              },
            ],
          },
        ],
      },

      // crm
      {
        path: "crm",
        name: "CrmView",
        component: () => import("../views/CrmView.vue"),
        meta: {
          pageTitleKey: "view-title-crm",
        },
        children: [
          {
            path: "objects/:objectSlug",
            name: "CrmObjectView",
            component: () => import("../views/CrmObjectView.vue"),
            meta: {
              pageTitleKey: "view-title-crm-object",
            },
          },
          {
            path: "mailing",
            name: "CrmMailingView",
            component: () => import("../views/CrmMailingView.vue"),
            meta: {
              pageTitleKey: "view-title-crm-mailing",
            },
            children: [
              // {
              //   path: "settings",
              //   name: "CrmMailingSettingsView",
              //   component: () => import("../views/CrmMailingSettingsView.vue"),
              //   meta: {
              //     pageTitleKey: "view-title-crm-mailing-settings",
              //   },
              // },
              {
                path: "templates",
                name: "CrmMailingTemplatesView",
                component: () => import("../views/CrmMailingTemplatesView.vue"),
                meta: {
                  pageTitleKey: "view-title-crm-mailing-templates",
                },
                children: [
                  {
                    path: "add",
                    name: "CrmMailingTemplatesAddView",
                    component: () => import("../views/CrmMailingTemplatesDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-crm-mailing-templates-add",
                    },
                  },
                  {
                    path: ":templateId",
                    name: "CrmMailingTemplatesDetailView",
                    component: () => import("../views/CrmMailingTemplatesDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-crm-mailing-templates-detail",
                    },
                  },
                ],
              },
              {
                path: "queue",
                name: "CrmMailingQueueView",
                component: () => import("../views/CrmMailingQueueView.vue"),
                meta: {
                  pageTitleKey: "view-title-crm-mailing-queue",
                },
              },
              {
                path: "workers",
                name: "CrmMailingWorkersView",
                component: () => import("../views/CrmMailingWorkersView.vue"),
                meta: {
                  pageTitleKey: "view-title-crm-mailing-workers",
                },
                children: [
                  {
                    path: ":workerId",
                    name: "CrmMailingWorkersDetailView",
                    component: () => import("../views/CrmMailingWorkersDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-crm-mailing-worker",
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "import",
            name: "CrmImportView",
            component: () => import("../views/CrmImportView.vue"),
            meta: {
              pageTitleKey: "view-title-crm-import",
            },
          },
        ],
      },

      // sites
      {
        path: "sites",
        name: "SitesView",
        component: () => import("../views/SitesView.vue"),
        meta: {
          pageTitleKey: "view-title-sites",
        },
      },

      // site
      {
        path: "site/:siteName?",
        component: () => import("../views/SiteView.vue"),
        children: [
          // dashboard
          {
            path: "dashboard",
            name: "DashboardView",
            component: () => import("../views/DashboardView.vue"),
            meta: {
              pageTitleKey: "view-title-dashboard",
            },
          },
          {
            path: "",
            name: "SiteMainView",
            component: () => import("../views/SiteMainView.vue"),
          },

          {
            path: "settings",
            name: "SiteSettingsView",
            component: () => import("../views/SiteSettingsView.vue"),
            meta: {
              pageTitleKey: "view-title-site-settings",
            },
            children: [
              {
                path: "sections",
                name: "SiteSettingsSectionsView",
                component: () => import("../views/SiteSettingsSectionsView.vue"),
                meta: {
                  pageTitleKey: "view-title-site-settings-sections",
                },
              },
              {
                path: "domains",
                name: "SiteSettingsDomainsView",
                component: () => import("../views/SiteSettingsDomainsView.vue"),
                meta: {
                  pageTitleKey: "view-title-site-settings-domains",
                },
              },
            ],
          },

          // pages
          {
            path: "pages",
            name: "SitePagesView",
            component: () => import("../views/SitePagesView.vue"),
            meta: {
              pageTitleKey: "view-title-pages",
            },
          },
          {
            path: "page/:pageId/editor",
            name: "EditorView",
            component: () => import("../views/EditorView.vue"),
          },
          {
            path: "page/:pageId/settings",
            name: "SitePageSettingsView",
            component: () => import("../views/SitePageSettingsView.vue"),
            meta: {
              pageTitleKey: "view-title-page-settings",
            },
          },

          // posts - temporary
          {
            path: "posts",
            name: "SitePostsView",
            component: () => import("../views/SitePostsView.vue"),
            meta: {
              pageTitleKey: "view-title-posts",
            },
            children: [
              {
                path: ":postId",
                name: "SitePostsPostView",
                component: () => import("../views/SitePostsPostView.vue"),
              },
            ],
          },

          // shop
          {
            path: "commerce",
            name: "ShopView",
            component: () => import("../views/ShopView.vue"),
            children: [
              {
                path: "",
                name: "ShopMenuView",
                component: () => import("../views/ShopMenuView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce",
                },
              },
              {
                path: "carts",
                name: "ShopCartsView",
                component: () => import("../views/ShopCartsView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-carts",
                },
              },
              {
                path: "orders",
                name: "ShopOrdersView",
                component: () => import("../views/ShopOrdersView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-orders",
                },
                children: [
                  {
                    path: ":orderId",
                    name: "ShopOrderDetailView",
                    component: () => import("../views/ShopOrderDetailView.vue"),
                  },
                ],
              },
              {
                path: "inventory",
                name: "ShopInventoryView",
                component: () => import("../views/ShopInventoryView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-inventory",
                },
                children: [
                  {
                    path: "all",
                    name: "ShopInventoryItemsDetailView",
                    component: () => import("../views/ShopInventoryItemsDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-inventory-products",
                    },
                  },
                  {
                    path: ":itemId",
                    name: "ShopInventoryItemDetailView",
                    component: () => import("../views/ShopInventoryItemDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-inventory-product",
                    },
                  },
                ],
              },

              {
                path: "settings",
                name: "ShopSettingsView",
                component: () => import("../views/ShopSettingsView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-settings",
                },
                children: [
                  {
                    path: "general",
                    name: "ShopSettingsGeneralView",
                    component: () => import("../views/ShopSettingsGeneralView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-general",
                    },
                  },
                  {
                    path: "invoicing",
                    name: "ShopSettingsInvoicingView",
                    component: () => import("../views/ShopSettingsInvoicingView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-invoicing",
                    },
                  },
                  {
                    path: "payment",
                    name: "ShopSettingsPaymentView",
                    component: () => import("../views/ShopSettingsPaymentView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-payment",
                    },
                  },
                  {
                    path: "delivery",
                    name: "ShopSettingsDeliveryView",
                    component: () => import("../views/ShopSettingsDeliveryView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-delivery",
                    },
                    children: [
                      {
                        path: ":id",
                        name: "ShopSettingsDeliveryMethodView",
                        component: () => import("../views/ShopSettingsDeliveryMethodView.vue"),
                        meta: {
                          pageTitleKey: "view-title-commerce-settings-delivery-method",
                        },
                      },
                    ],
                  },
                  {
                    path: "cart",
                    name: "ShopSettingsCartView",
                    component: () => import("../views/ShopSettingsCartView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-cart",
                    },
                  },
                ],
              },

              {
                path: "flow",
                name: "ShopFlowView",
                component: () => import("../views/ShopFlowView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-order-flow",
                },
              },
              {
                path: "promocodes",
                name: "ShopPromoCodesView",
                component: () => import("../views/ShopPromoCodesView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-promocodes",
                },
                children: [
                  {
                    path: "import",
                    name: "ShopPromoCodeImportView",
                    component: () => import("../views/ShopPromoCodeImportView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-promocode-import",
                    },
                  },
                  {
                    path: ":codeId",
                    name: "ShopPromoCodeDetailView",
                    component: () => import("../views/ShopPromoCodeDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-promocode-detail",
                    },
                  },
                ],
              },
              {
                path: "giftcards",
                name: "ShopGiftCardsView",
                component: () => import("../views/ShopGiftCardsView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-giftcards",
                },
                children: [
                  // {
                  //   path: "import",
                  //   name: "ShopGiftCardImportView",
                  //   component: () => import("../views/ShopGiftCardImportView.vue"),
                  //   meta: {
                  //     pageTitleKey: "view-title-commerce-giftcard-import",
                  //   },
                  // },
                  {
                    path: ":giftCardId",
                    name: "ShopGiftCardDetailView",
                    component: () => import("../views/ShopGiftCardDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-giftcard-detail",
                    },
                    children: [
                      {
                        path: "validate",
                        name: "ShopGiftCardValidationView",
                        component: () => import("../views/ShopGiftCardValidationView.vue"),
                        meta: {
                          pageTitleKey: "view-title-commerce-giftcard-validation",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "deliveryplanning",
                name: "ShopDeliveryPlanningView",
                component: () => import("../views/ShopDeliveryPlanningView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-delivery-planning",
                },
              },
              {
                path: "feereports",
                name: "ShopFeeReportsView",
                component: () => import("../views/ShopFeeReportsView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-fee-reports",
                },
              },
              {
                path: "billing",
                name: "ShopBillingView",
                component: () => import("../views/ShopBillingView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-fee-billing",
                },
              },
              {
                path: "export",
                name: "ShopExportView",
                component: () => import("../views/ShopExportView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-export",
                },
              },
            ],
          },
        ],
      },

      // user profile
      {
        path: "user",
        name: "UserView",
        component: () => import("../views/UserView.vue"),
        meta: {
          pageTitleKey: "view-title-profile",
        },
      },
      {
        path: "user/changePassword",
        name: "UserChangePasswordView",
        component: () => import("../views/UserChangePasswordView.vue"),
        meta: {
          pageTitleKey: "view-title-profile-changepassword",
        },
      },
      {
        path: "user/passkeys",
        name: "UserPasskeysView",
        component: () => import("../views/UserPasskeysView.vue"),
        meta: {
          pageTitleKey: "view-title-profile-passkeys",
        },
      },

      {
        path: "user/notifications",
        name: "NotificationsView",
        component: () => import("../views/NotificationsView.vue"),
        meta: {
          pageTitleKey: "view-title-profile-notifications",
        },
      },

      // platform
      {
        path: "platform",
        name: "PlatformView",
        component: () => import("../views/PlatformView.vue"),
        meta: {
          pageTitleKey: "view-title-platform",
        },
        children: [
          {
            path: "wizard",
            name: "PlatformAddWizardView",
            component: () => import("../views/PlatformAddWizardView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-wizard",
            },
          },
          {
            path: "clients",
            name: "PlatformClientsView",
            component: () => import("../views/PlatformClientsView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-clients",
            },
            children: [
              {
                path: ":clientId",
                name: "PlatformClientDetailView",
                component: () => import("../views/PlatformClientDetailView.vue"),
              },
            ],
          },
          {
            path: "sites",
            name: "PlatformSitesView",
            component: () => import("../views/PlatformSitesView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-sites",
            },
            children: [
              {
                path: ":siteId",
                name: "PlatformSiteDetailView",
                component: () => import("../views/PlatformSiteDetailView.vue"),
              },
            ],
          },
          {
            path: "users",
            name: "PlatformUsersView",
            component: () => import("../views/PlatformUsersView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-users",
            },
            children: [
              {
                path: "add",
                name: "PlatformUserAddView",
                component: () => import("../views/PlatformUserAddView.vue"),
              },
              {
                path: ":userId",
                name: "PlatformUserDetailView",
                component: () => import("../views/PlatformUserDetailView.vue"),
              },
            ],
          },
          {
            path: "shops",
            name: "PlatformShopsView",
            component: () => import("../views/PlatformShopsView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-shops",
            },
            children: [
              {
                path: ":shopId",
                name: "PlatformShopDetailView",
                component: () => import("../views/PlatformShopDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-platform-shops-detail",
                },
              },
            ],
          },
          {
            path: "events",
            name: "PlatformEventsView",
            component: () => import("../views/PlatformEventsView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-events",
            },
            children: [
              {
                path: ":eventId",
                name: "PlatformEventDetailView",
                component: () => import("../views/PlatformEventDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-platform-events-detail",
                },
              },
            ],
          },
          {
            path: "system",
            name: "PlatformSystemView",
            component: () => import("../views/PlatformSystemView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-system",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/test-email",
    name: "TestEmailView",
    component: () => import("../views/TestEmailView.vue"),
  },
  {
    path: "/auth",
    name: "AuthView",
    component: () => import("../views/AuthView.vue"),
    children: [
      // login (after email link redirect)
      {
        path: "verify",
        name: "AuthVerifyView",
        component: () => import("../views/AuthVerifyView.vue"),
      },
    ],
  },
  {path: "", redirect: {name: "SitesView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "SitesView"}},
]
