import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, deleteDoc, doc} from "firebase/firestore"
import {useShopStore} from "./shop"

export const useFilesShopVideosStore = defineStore("filesShopVideos", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const videos = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const filesShopVideosLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/videos`)
    return await this.attach("videos", collectionRef, serializeFiles)
  }

  async function unbind() {
    return await this.detach()
  }

  async function removeImage(args) {
    const imageId = args.id
    const shopId = shopStore.shopId
    const docRef = doc(firestore, `shops/${shopId}/videos/${imageId}`)
    await deleteDoc(docRef)
    // TODO: remove file from storage
  }

  return {
    // state
    videos,
    status,
    unsubscribe,

    // getters
    filesShopVideosLoaded,

    // actions
    bind,
    unbind,
    removeImage,
  }
})

// extend with properties
const serializeFiles = (snapshot, data) => {
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _module: {
      value: "filebrowser",
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFilesShopVideosStore, import.meta.hot))
}
