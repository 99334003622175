import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore, functions} from "@/firebase/index.js"
import {httpsCallable} from "firebase/functions"
import {collection, deleteDoc, doc, serverTimestamp, setDoc, updateDoc} from "firebase/firestore"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const usePlatformShopsFeeCategoriesStore = defineStore("platformShopsFeeCategories", () => {
  // stores
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const categories = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const platformShopsFeeCategoriesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind(args) {
    const shopId = args.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/feeCategories`)
    return await this.attach("categories", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const shopId = args.shopId
    const manageFn = httpsCallable(functions, "calls-admin-platform-manage")
    const response = await manageFn({action: "create-shop-fee-category", shopId})
    return {shopFeeCategoryId: response.data.shopFeeCategoryId}
  }

  async function update(args) {
    formSaveStateStore.markSaving(args.fieldId)

    const shopId = args.shopId
    const categoryId = args.data.id
    const docRef = doc(firestore, `shops/${shopId}/feeCategories/${categoryId}`)
    const data = {
      basedOn: args.data.basedOn || "",
      fixedFee: args.data.fixedFee || 0,
      percentageFee: args.data.percentageFee || 0,
      autoApply: args.data.autoApply || false,
      title: args.data.title,
      note: args.data.note || "",
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function remove(args) {
    const siteId = args.siteId
    const categoryId = args.categoryId
    const docRef = doc(firestore, `shops/${siteId}/feeCategories/${categoryId}`)
    return await deleteDoc(docRef)
  }

  return {
    // state
    categories,
    status,
    unsubscribe,

    // getters
    platformShopsFeeCategoriesLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlatformShopsFeeCategoriesStore, import.meta.hot))
}
