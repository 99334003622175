import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection} from "firebase/firestore"
import {useShopStore} from "./shop"

export const useShopItemLabelGroupsStore = defineStore("shopItemLabelGroups", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const groups = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemLabelGroupsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/itemLabelGroups`)
    return await this.attach("groups", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    groups,
    status,
    unsubscribe,

    // getters
    shopItemLabelGroupsLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemLabelGroupsStore, import.meta.hot))
}
