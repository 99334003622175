import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate, storeToRefs} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc} from "firebase/firestore"
import {useAppStore} from "./app.js"

export const useOrgStore = defineStore("org", () => {
  const appStore = useAppStore()

  // state
  const org = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const orgLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const {app} = storeToRefs(appStore)
    const {orgId} = app.value

    if (!orgId) {
      return
    }

    const ref = doc(firestore, `orgs/${orgId}`)
    await this.attach("org", ref)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    org,
    status,
    unsubscribe,

    // getters
    orgLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrgStore, import.meta.hot))
}
