import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, deleteDoc, doc, serverTimestamp, setDoc, updateDoc} from "firebase/firestore"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const usePlatformEventsStore = defineStore("platformEvents", () => {
  // stores
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const events = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const platformEventsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const collectionRef = collection(firestore, `eventSubscriptions`)
    return await this.attach("events", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add() {
    const docRef = doc(collection(firestore, `eventSubscriptions`))
    const data = {
      title: "",
      actions: [],
      event: "",
      shopId: "",
      subscriberId: "",
      bypassNonProductionEnvironment: false,
      note: "",
      meta: {
        created: serverTimestamp(),
        updated: null,
        deleted: null,
      },
    }
    await setDoc(docRef, data)
    return {eventId: docRef.id}
  }

  async function update(args) {
    formSaveStateStore.markSaving(args.fieldId)

    const subscriptionId = args.data.id
    const docRef = doc(firestore, `eventSubscriptions/${subscriptionId}`)
    const data = {
      title: args.data.title || "",
      actions: args.data.actions || [],
      event: args.data.event || "",
      shopId: args.data.shopId || "",
      subscriberId: args.data.subscriberId || "",
      bypassNonProductionEnvironment: args.data.bypassNonProductionEnvironment || false,
      note: args.data.note || "",
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function remove(args) {
    const eventId = args.eventId
    const docRef = doc(firestore, `eventSubscriptions/${eventId}`)
    return await deleteDoc(docRef)
  }

  return {
    // state
    events,
    status,
    unsubscribe,

    // getters
    platformEventsLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlatformEventsStore, import.meta.hot))
}
