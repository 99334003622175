import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate, storeToRefs} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, query, where, documentId} from "firebase/firestore"
import {useUserStore} from "./user.js"
import {useUserRolesStore} from "./userRoles"
import orderBy from "lodash/orderBy"

export const useSitesStore = defineStore("sites", () => {
  // stores
  const userStore = useUserStore()
  const userRolesStore = useUserRolesStore()
  const {roles: userRoles, isPlatformAdmin} = storeToRefs(userRolesStore)
  const {userPinnedSites} = storeToRefs(userStore)

  // state
  const sites = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const sitesLoaded = computed(() => status.value == "loaded")
  const sitesSorted = computed(() => orderBy(sites.value, ["name"], ["asc"]))
  const sitesPinned = computed(() =>
    sites.value.filter((site) => userPinnedSites.value.includes(site.id))
  )
  const sitesPinnedSorted = computed(() => orderBy(sitesPinned.value, ["name"], ["asc"]))
  const sitesUnpinned = computed(() =>
    sites.value.filter((site) => !userPinnedSites.value.includes(site.id))
  )
  const sitesUnpinnedSorted = computed(() => orderBy(sitesUnpinned.value, ["name"], ["asc"]))

  // actions
  async function bind() {
    let q

    if (isPlatformAdmin.value === true) {
      const collectionRef = collection(firestore, `sites`)
      q = query(collectionRef)
    } else {
      if (!userRoles.value?.sites) return
      const availableSites = Object.keys(userRoles.value.sites)
      const collectionRef = collection(firestore, `sites`)
      q = query(collectionRef, where(documentId(), "in", availableSites))
    }

    if (!q) return false

    return await this.attach("sites", q)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    sites,
    status,
    unsubscribe,

    // getters
    sitesLoaded,
    sitesSorted,
    sitesPinned,
    sitesPinnedSorted,
    sitesUnpinned,
    sitesUnpinnedSorted,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSitesStore, import.meta.hot))
}
