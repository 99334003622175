import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, deleteDoc, doc, serverTimestamp, setDoc, updateDoc} from "firebase/firestore"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const usePlatformShopsFeeRulesStore = defineStore("platformShopsFeeRules", () => {
  // stores
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const rules = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const platformShopsFeeRulesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind(args) {
    const shopId = args.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/feeRules`)
    return await this.attach("rules", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const shopId = args.shopId
    const docRef = doc(firestore, `shops/${shopId}/feeRules/default`)
    const data = {
      billing: {
        daysInMonth: [],
        end: serverTimestamp(),
        lastBilled: serverTimestamp(),
        start: serverTimestamp(),
      },
      fakturoidClientId: "",
      note: "",
      orderRules: {},
      meta: {
        created: serverTimestamp(),
        updated: null,
        deleted: null,
      },
    }
    await setDoc(docRef, data)
    return {feeRuleId: docRef.id}
  }

  async function update(args) {
    formSaveStateStore.markSaving(args.fieldId)

    const shopId = args.shopId
    const ruleId = args.data.id
    const docRef = doc(firestore, `shops/${shopId}/feeRules/${ruleId}`)
    const data = {
      billing: args.data.billing,
      fakturoidClientId: args.data.fakturoidClientId,
      orderRules: args.data.orderRules,
      note: args.data.note || "",
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function remove(args) {
    const siteId = args.siteId
    const docRef = doc(firestore, `shops/${siteId}/feeRules/default`)
    return await deleteDoc(docRef)
  }

  return {
    // state
    rules,
    status,
    unsubscribe,

    // getters
    platformShopsFeeRulesLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlatformShopsFeeRulesStore, import.meta.hot))
}
