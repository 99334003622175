// import VueGtag from "vue-gtag"

export default {
  install() {
    // app.use(VueGtag, {
    //   config: {
    //     id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
    //   },
    // })
  },
}
