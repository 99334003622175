import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore, functions} from "@/firebase/index.js"
import {collection, setDoc, updateDoc, doc, serverTimestamp} from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import {useShopStore} from "./shop"

export const useShopPromoCodesStore = defineStore("shopPromoCodes", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const promoCodes = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopPromoCodesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/promoCodes`)
    return await this.attach("promoCodes", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const shopId = shopStore.shopId
    const docRef = doc(collection(firestore, `shops/${shopId}/promoCodes`))

    // if you edit fields, consider editing functions/actions/misc/processGiftCard.fn/giftCardCode

    const data = {
      kind: args.kind,
      code: args.code,
      count: args.kind == "giftCard" ? 1 : args.count,
      unit: args.kind == "giftCard" ? "value" : args.unit,
      value: args.value,
      allowOffline: args.allowOffline,
      allowOnline: args.allowOnline,
      allowCredit: args.allowCredit,
      from: args.from,
      to: args.to,
      daysValidFromPurchase: args.daysValidFromPurchase,
      expirationKind: args.expirationKind,
      tags: args.tags,
      note: args.note,
      meta: {
        created: serverTimestamp(),
        updated: null,
        deleted: null,
      },
    }

    if (args.kind == "giftCard") {
      data.giftCard = {
        forGiftCardProductId: args.giftCard.forGiftCardProductId
          ? args.giftCard.forGiftCardProductId
          : null,
        soldWithOrder: {
          id: null,
          number: null,
        },
        kind: args.giftCard.kind ? args.giftCard.kind : null,
      }
    } else if (args.kind == "discount") {
      data.usage = {
        orders: [],
      }
    }

    await setDoc(docRef, data)

    // recalculate stats
    const rs = httpsCallable(functions, "calls-commerce-admin-recalculatePromoCodeStats")
    await rs({shopId})

    return docRef
  }

  async function update(args) {
    const shopId = shopStore.shopId
    const promoCodeId = args.id
    const docRef = doc(firestore, `shops/${shopId}/promoCodes/${promoCodeId}`)

    // if you edit fields, consider editing functions/actions/misc/processGiftCard.fn/giftCardCode
    const data = {
      kind: args.kind,
      code: args.code,
      count: args.kind == "giftCard" ? 1 : args.count,
      unit: args.kind == "giftCard" ? "value" : args.unit,
      value: args.value,
      allowOffline: args.allowOffline,
      allowOnline: args.allowOnline,
      allowCredit: args.allowCredit,
      expirationKind: args.expirationKind,
      from: args.from,
      to: args.to,
      daysValidFromPurchase: args.daysValidFromPurchase,
      tags: args.tags,
      note: args.note,
      "meta.updated": serverTimestamp(),
    }

    if (args.kind == "giftCard") {
      data.giftCard = {
        forGiftCardProductId: args.giftCard.forGiftCardProductId
          ? args.giftCard.forGiftCardProductId
          : null,
        soldWithOrder: {
          id: null,
          number: null,
        },
        kind: args.giftCard.kind ? args.giftCard.kind : null,
      }
    } else if (args.kind == "discount") {
      data.usage = {
        orders: [],
      }
    }

    return await updateDoc(docRef, data)
  }

  return {
    // state
    promoCodes,
    status,
    unsubscribe,

    // getters
    shopPromoCodesLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopPromoCodesStore, import.meta.hot))
}
