import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {auth} from "@/firebase"

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const userSignedIn = auth.currentUser
  // console.log("router.beforeEach:", to, from, userSignedIn)
  if (!userSignedIn && !["AuthView", "AuthVerifyView"].includes(to.name)) {
    // console.log("router: not allowed, redirecting to Auth")
    return {name: "AuthView"}
  }
})

export {router}
