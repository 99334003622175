<template>
  <div id="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{
            'size-l': size == 'L',
            'size-m': size == 'M',
            'size-s': size == 'S',
            'size-d': size == 'D',
          }"
        >
          <div id="modal-header">
            <slot name="header"> </slot>
            <div id="close-button-holder" v-if="closable">
              <a href="javascript:" class="button" @click="$emit('update:close')">X</a>
            </div>
          </div>

          <div id="modal-body">
            <slot name="body"> N/A </slot>
          </div>

          <div id="modal-footer">
            <slot name="footer"> default footer </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["size", "closable"],
  emits: ["update:close"],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
#modal {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .modal-mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;

    .modal-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .modal-container {
        min-width: 300px;
        min-height: 100px;
        background: #ffffff;
        border-radius: 8px;
        padding: 20px 20px 0 20px;
        text-align: center;

        #modal-footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 20px;

          a.button {
            padding: 4px 16px;
            margin: 0 6px;
            background: #90949a;
            border-radius: 20px;
            color: #efefef;
            font-weight: 700;
            letter-spacing: 1.25px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
