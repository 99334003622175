<script setup>
import {ref, watch, computed} from "vue"
import {OneToggle, OneIcon} from "@jirizavadil/framework.one/ui"
import {useAppStore} from "../stores/app.js"
import {storeToRefs} from "pinia"

const appStore = useAppStore()

const {app} = storeToRefs(appStore)

// const mode = ref({
//   light: false,
//   dark: false,
//   system: false,
// })

const mode = computed(() => {
  return {
    light: app.value.darkMode === "light",
    dark: app.value.darkMode === "dark",
    system: app.value.darkMode === "system",
  }
})

function onDarkModeUpdated(v) {
  app.value.darkMode = v
  if (v === "system") {
    localStorage.removeItem("uiDarkMode")
    return
  }
  localStorage.uiDarkMode = v
}

// watch(
//   mode,
//   (value) => {
//     if (value.light) {
//       app.value.darkMode = "light"
//       onDarkModeUpdated("light")
//       mode.value.dark = false
//       mode.value.system = false
//       // document.documentElement.classList.remove("dark")
//     } else if (value.dark) {
//       app.value.darkMode = "dark"
//       onDarkModeUpdated("dark")
//       mode.value.light = false
//       mode.value.system = false
//       // document.documentElement.classList.add("dark")
//     } else if (value.system) {
//       app.value.darkMode = "system"
//       onDarkModeUpdated("system")
//       mode.value.light = false
//       mode.value.dark = false
//       // document.documentElement.classList.remove("dark")
//     }
//   },
//   {deep: true}
// )
</script>

<template>
  <div
    class="inline-flex flex-row gap-1 rounded-[0.5rem] border border-neutral-150 bg-neutral-50 p-1 dark:border-neutral-900 dark:bg-neutral-950"
  >
    <OneToggle
      :modelValue="mode.light"
      :size="9"
      rounded="sm"
      @update:modelValue="onDarkModeUpdated('light')"
    >
      <OneIcon class="size-4" lucide="Sun" />
    </OneToggle>
    <OneToggle
      :modelValue="mode.dark"
      :size="9"
      rounded="sm"
      @update:modelValue="onDarkModeUpdated('dark')"
    >
      <OneIcon class="size-4" lucide="Moon" />
    </OneToggle>
    <OneToggle
      :modelValue="mode.system"
      :size="9"
      rounded="sm"
      @update:modelValue="onDarkModeUpdated('system')"
    >
      <OneIcon class="size-4" lucide="SunMoon" />
    </OneToggle>
  </div>
</template>
