import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate, storeToRefs} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection} from "firebase/firestore"
import {useAppStore} from "./app.js"

export const useOrgSpacesStore = defineStore("spaces", () => {
  const appStore = useAppStore()

  // state
  const spaces = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const orgSpacesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const {app} = storeToRefs(appStore)
    const {orgId} = app.value

    if (!orgId) {
      return
    }

    const ref = collection(firestore, `orgs/${orgId}/spaces`)
    await this.attach("spaces", ref)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    spaces,
    status,
    unsubscribe,

    // getters
    orgSpacesLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrgSpacesStore, import.meta.hot))
}
