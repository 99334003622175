import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  where,
  query,
} from "firebase/firestore"
import {useShopStore} from "./shop"

export const useShopDeliverySlotsStore = defineStore("shopDeliverySlots", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const deliverySlots = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)
  const range = ref({
    from: null,
    to: null,
  })
  const selectedDay = ref(null)
  const selectedSlot = ref(null)
  const bulkEdit = ref(false)

  // getters
  const shopDeliverySlotsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const deliverySlotsRef = collection(firestore, `shops/${shopId}/deliverySlots`)
    const queryParams = [
      where("dateFrom", ">=", new Date(range.value.from)),
      where("dateFrom", "<=", new Date(range.value.to)),
      where("metadata.deleted", "==", false),
    ]
    const q = query(deliverySlotsRef, ...queryParams)
    return await this.attach("deliverySlots", q, serializeDeliverySlots)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/deliverySlots`)
    if (!args) args = {}
    const from = args.from ? args.from : "06:00"
    const to = args.to ? args.to : "19:00"
    const mark = this.selectedDay.date.yyyymmdd() + getTimeHHMM(from) + getTimeHHMM(to)
    const dateFrom = this.selectedDay.date.setHHMM(from)
    const dateTo = this.selectedDay.date.setHHMM(to)
    const data = {
      dateFrom,
      dateTo,
      from,
      to,
      staff: args.staff ? args.staff : 1,
      mark,
      minutesBefore: args.minutesBefore ? args.minutesBefore : 0,
      deliveryMethods: args.deliveryMethods ? args.deliveryMethods : [],
      metadata: {
        created: serverTimestamp(),
        updated: false,
        deleted: false,
      },
    }
    return await addDoc(collectionRef, data)
  }

  async function update(args) {
    const shopId = shopStore.shopId
    const slotId = args.id
    const docRef = doc(firestore, `shops/${shopId}/deliverySlots/${slotId}`)
    if (!slotId) return
    const data = {
      ...args,
      "metadata.updated": serverTimestamp(),
    }
    return await updateDoc(docRef, data)
  }

  async function remove(args) {
    const shopId = shopStore.shopId
    const slotId = args.slotId
    const docRef = doc(firestore, `shops/${shopId}/deliverySlots/${slotId}`)
    return await deleteDoc(docRef)
  }

  return {
    // state
    deliverySlots,
    status,
    unsubscribe,
    range,
    selectedDay,
    selectedSlot,
    bulkEdit,

    // getters
    shopDeliverySlotsLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

// extend delivery slots with custom local properties
const serializeDeliverySlots = (snapshot, data) => {
  const dayInMonth = data.dateFrom.toDate().getDate()
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _dayInMonth: {
      value: dayInMonth,
      writable: false,
      enumerable: false,
    },
  })
}

function getTimeHHMM(time) {
  if (time && time.indexOf(":") > -1) {
    return time.replace(/:/g, "")
  }
  return "0000"
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopDeliverySlotsStore, import.meta.hot))
}
