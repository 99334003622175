<template>
  <picture
    :data-one-component="$options.name"
    ref="picture"
    :style="stylePicture"
    v-bind="props.root"
    v-if="imageId !== false"
  >
    <img
      ref="image"
      :src="notLoadedImageData"
      @load="imgLoad"
      @error="imgError"
      v-if="!loaded"
      :style="style"
      v-bind="props.image"
    />

    <img
      ref="image"
      :xsrc="src"
      :src="lazyLoadedSrc"
      @load="imgLoad"
      @error="imgError"
      v-if="found && loaded"
      :style="style"
      v-bind="props.image"
    />

    <img :src="notFoundImageData" class="w-full h-full" v-else />

    <!-- svg blob clip mask -->
    <svg class="svg-clip-container" width="0" height="0" xmlns="http:/www.w3.org/2000/svg">
      <defs>
        <clipPath :id="`svg-clip-${componentUid}`">
          <path stroke="none" stroke-width="0" fill="black" :d="svgMaskPath"></path>
        </clipPath>
      </defs>
    </svg>
  </picture>
</template>

<script>
import mixinClasses from "../../mixins/classes"
import * as blobs2 from "blobs/v2"
import {v4 as uuidv4} from "uuid"

export default {
  name: "OneImage",
  mixins: [mixinClasses],
  props: {
    applySvgMask: {
      type: Boolean,
      default: false,
    },
    maskShape: {
      type: String,
      default: "blob",
    },
    override: {
      type: Object,
    },
    stylesConfig: {
      type: Object,
    },
    image: {
      type: Object,
    },
    src: {
      type: [String, Boolean],
      default: false,
      validator: (val) => {
        if (typeof val === "string") {
          return val.length >= 1
        } else if (typeof val === "boolean") {
          return true
        }
      },
    },
    imageId: {
      type: [String, Boolean],
      default: null,
    },
    ratio: {
      type: String,
      default: "auto", // auto | square
    },
    fit: {
      type: String,
      default: "cover",
    },
    maxHeight: {
      type: String,
      default: "auto", // auto | full | screen | none
    },
    minHeight: {
      type: String,
      default: "auto", // auto | full | screen | none
    },
    circle: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: Boolean,
      default: false,
    },
    grayscale: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const componentUid = uuidv4()

    return {
      componentUid,
    }
  },
  data: () => ({
    svgMaskPath: "",
    defaults: {
      commonClasses: "",
      root: {
        class: "",
        propClass: {
          maxHeight: {
            auto: "max-h-full",
            full: "h-full",
            screen: "max-h-screen",
            none: "",
          },
          minHeight: {
            auto: "h-auto",
            full: "h-full",
            screen: "min-h-screen",
            none: "",
          },
          fit: {
            cover: "xh-full w-full",
            contain: "flex",
            none: "",
          },
          ratio: {
            auto: "ratio-auto",
            square: "ratio-square",
          },
          circle: {
            true: "block",
            false: "",
          },
        },
      },
      image: {
        class: "",
        propClass: {
          fit: {
            cover: "xh-full w-full",
            contain: "flex",
            none: "",
          },
          maxHeight: {
            none: "",
            auto: "h-auto max-h-full",
            // auto: "max-h-full",
            full: "h-full",
            screen: "max-h-screen",
          },
          minHeight: {
            none: "",
            auto: "h-auto",
            full: "h-full",
            screen: "min-h-screen",
          },
          circle: {
            true: "rounded-full",
            false: "",
          },
          grayscale: {
            true: "grayscale",
            false: "",
          },
        },
      },
    },
    // defaultClasses: {
    //   // base: 'w-auto object-center text-center'
    //   // base: 'block w-full h-full'
    //   base: '',
    //   // base: 'flex'
    //   props: {
    //     fit: {
    //       cover: 'h-full w-full',
    //       // contain: ''
    //     }
    //   },
    //   layout: {
    //     image: 'max-w-none max-h-screen'
    //   }
    // },
    found: false,
    loaded: false,
    // notFoundImageData: 'https://source.unsplash.com/random'
    notFoundImageData: `data:image/svg+xml,%3Csvg width='1000px' height='1000px' viewBox='0 0 1000 1000' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect id='Rectangle' fill='%23D8D8D8' x='0' y='0' width='1000' height='1000'%3E%3C/rect%3E%3C/svg%3E`,
    notLoadedImageData: `data:image/svg+xml,%3Csvg width='1000px' height='1000px' viewBox='0 0 1000 1000' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect id='Rectangle' fill='%23D8D8D8' x='0' y='0' width='1000' height='1000'%3E%3C/rect%3E%3C/svg%3E`,
    partiallyAppearedInViewport: true, // TODO: this is temporary, fix it. it stopped working when i started using ODS
    pictureElement: {
      clientWidth: null,
      clientHeight: null,
    },
    imageElement: {
      clientWidth: null,
      clientHeight: null,
    },
  }),
  computed: {
    xsrc() {
      return "lala"
    },

    viewportWidth() {
      if (this.$pinia?.state?.value?.app?.app?.viewport?.width) {
        return this.$pinia.state.value.app.app.viewport.width
      } else {
        return false
      }
    },

    // if src wasn't supplied, but we have image id and other info, let's compute the src here
    autoSrc() {
      const siteId = this.$pinia?.state?.value?.site?.id
      if (siteId && this.image && this.image.imageId && this.image.contentType) {
        const imageId = this.image.imageId
        const contentType = this.image.contentType
        let params = ""
        if (contentType == "image/svg+xml") params = ""
        if (contentType == "image/jpeg") params = "$w_1800,q_80,x_3"
        if (contentType == "image/png") params = "$w_1800,c_9,x_3"
        let src = `${import.meta.env.VITE_URL_STATIC}/sites/${siteId}/images/${imageId}${params}`
        return src
      } else {
        return false
      }
    },
    style() {
      // return {
      //   width: '239.34px',
      //   height: '239.34px'
      // }

      let s = {
        // width: "100%",
        // height: '100%',
        objectFit: this.fit,
      }

      if (this.applySvgMask) {
        // console.log('lala svgmask')
        s.clipPath = `url(#svg-clip-${this.componentUid})`
        s.width = `${this.imageElement.clientWidth}px`
        s.height = `${this.imageElement.clientWidth}px`
      }

      // width
      if (this.stylesConfig && this.stylesConfig.width) {
        let prop = this.stylesConfig.width
        let v = this.isObject(prop) ? prop[this.viewportSize] : prop
        s.width = `${v}`
      }

      if (this.ratio == "square") {
        // if (this.picture && this.picture.clientWidth) {
        if (this.imageElement) {
          s.height = `${this.imageElement.clientWidth}px`
          s.width = `${this.imageElement.clientWidth}px`
        }
      }

      return s
    },
    stylePicture() {
      return {
        width: this.style.width,
        height: this.style.height,
      }
    },
    lazyLoadedSrc() {
      if (this.partiallyAppearedInViewport) {
        // TODO: make this intelligently
        if (this.imageId == "placeholder") {
          return "https://firebasestorage.googleapis.com/v0/b/platformaone-production.appspot.com/o/common%2Fplaceholder-image.jpg?alt=media&token=8653bba9-97c1-4006-a309-37f0c34d85b5"
        } else if (this.imageId == "placeholder-portrait") {
          return "https://firebasestorage.googleapis.com/v0/b/platformaone-production.appspot.com/o/common%2Fplaceholder-image.jpg?alt=media&token=8653bba9-97c1-4006-a309-37f0c34d85b5"
        } else if (this.imageId == "placeholder-logo-white") {
          return "https://firebasestorage.googleapis.com/v0/b/platformaone-production.appspot.com/o/common%2Fplaceholder-logo-white.svg?alt=media&token=0c5af014-91a4-477c-a966-6a6de7971c77"
        } else if (this.imageId == "placeholder-logo-black") {
          return "https://firebasestorage.googleapis.com/v0/b/platformaone-production.appspot.com/o/common%2Fplaceholder-logo-black.svg?alt=media&token=65f73bb8-3804-49b6-b030-c49f0b6f991d"
        } else if (this.imageId == "placeholder-logo") {
          return "https://firebasestorage.googleapis.com/v0/b/platformaone-production.appspot.com/o/common%2Fplaceholder-logo.svg?alt=media&token=c177bb3a-5bdc-43d2-828d-84ce43902487"
        } else {
          if (this.src) return this.src
          else if (this.autoSrc) return this.autoSrc
          else return false
        }
      } else {
        return this.notLoadedImageData
      }
    },
  },
  watch: {
    found: {
      handler(val, oldVal) {
        if (oldVal == false && val == true) {
          // console.log('found changed', oldVal, val, this.imageId)
          // console.log(this)
          this.isPartiallyInViewport()
        }
      },
    },
    scrollY: {
      handler() {
        // if (this.imageId == 'xcQTYCambgYcUELcXFKZ') {
        this.isPartiallyInViewport()
        // }
      },
    },
    // viewportWidth() {
    //   this.onWindowResize()
    // },
  },
  // async mounted() {
  //   // window.addEventListener('scroll', this.lazyLoad)
  //   // this.updatePictureDimensions()
  //   const piniaAvailable = this.$pinia ? true : false
  //   console.log("Image.vue mounted:", piniaAvailable, this.$pinia.state.value.app.app.viewport)

  //   const {useAppStore} = await import("@/stores/app.js")
  //   const {storeToRefs} = await import("pinia")

  //   const appStore = useAppStore(this.$pinia)

  //   const {app} = storeToRefs(appStore)

  //   console.log("Image.vue app:", app.value.viewport.width)
  // },
  created() {
    // const piniaAvailable = this.$pinia ? true : false
    // console.log("Image.vue created:", piniaAvailable)

    // console.log('created()', this.imageId)
    if (this.src != false || this.autoSrc != false) {
      // console.log('setting found: true')
      this.found = true
    }

    if (!this.preload) this.loaded = true

    // TODO move this to somewhere global
    // window.addEventListener("resize", this.onWindowResize)
  },
  methods: {
    getSvgMaskPath() {
      if (this.maskShape == "blob") {
        // console.log('getSvgMaskPath:', this.imageElement.clientWidth)
        return blobs2.svgPath(
          {
            seed: Math.random(),
            extraPoints: 4,
            randomness: 4,
            size: this.imageElement.clientWidth,
          },
          {
            fill: "black", // 🚨 NOT SANITIZED
            // stroke: "black", // 🚨 NOT SANITIZED
            // strokeWidth: 4,
          }
        )
      } else {
        return ""
      }
    },
    lazyLoad() {
      // // console.log('lazyLoad()')
    },
    isPartiallyInViewport() {
      if (!this.$refs.image) return false
      let offset = 400 // N pixels sooner than it appears, so it has time to load
      let bounding = this.$refs.image.getBoundingClientRect()
      let partiallyVisible = bounding.top < window.innerHeight + offset && bounding.bottom >= 0
      // // console.log('bounding:', bounding, this.scrollY, partiallyVisible)
      if (partiallyVisible && !this.partiallyAppearedInViewport) {
        this.partiallyAppearedInViewport = true
        this.lazyLoad()
      }
    },
    isFullyInViewport() {
      let bounding = this.$refs.image.getBoundingClientRect()
      bounding.top >= 0 && bounding.bottom <= window.innerHeight
      // // console.log('bounding:', bounding, this.scrollY, partiallyVisible)
    },
    imgLoad() {
      // console.log("image loaded", e)
      this.loaded = true
      if (this.applySvgMask) {
        // console.log(this.$refs.image.clientWidth, this.$refs.image.clientHeight, this.$refs.image.getBoundingClientRect())
      }
      setTimeout(() => {
        this.updateImageDimensions()
      }, 10)
    },
    imgError(e) {
      console.log("image error", e)
      // this likely means resource was not found
      this.found = false
    },
    onWindowResize() {
      // setTimeout(() => {
      // this.updateImageDimensions()
      // }, 1500)
    },
    updateImageDimensions() {
      if (this.applySvgMask) {
        // console.log('updateImageDimensions()', this.$refs.image.clientWidth, this.$refs.image.clientWidth, this.$refs.image.getBoundingClientRect())
      }
      // if (this.ratio != 'square') return
      // if (!this.$refs || !this.$refs.image || this.$refs.image.clientWidth == 0) {
      // setTimeout(() => {
      //   this.updatePictureDimensions()
      // }, 50)
      // }
      // if (this.$refs && this.$refs.picture && this.$refs.picture.clientWidth && this.$refs.picture.clientHeight) {
      if (this.$refs && this.$refs.image) {
        let bcr = this.$refs.image.getBoundingClientRect()
        // if (this.applySvgMask) {
        // console.log('bcri:', bcr)
        // }
        this.imageElement.clientWidth = bcr.width
        this.imageElement.clientHeight = bcr.height
      }

      // apply svg mask
      if (this.applySvgMask) {
        this.svgMaskPath = this.getSvgMaskPath()
      }
    },
    isObject(v) {
      return typeof v === "object" && v !== null
    },
  },
}
</script>

<style scoped lang="less">
// figure {
picture {
  &.ratio-square {
    img {
      object-fit: cover;
    }
  }
}
// }
</style>
