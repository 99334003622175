import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
  writeBatch,
  onSnapshot,
} from "firebase/firestore"
import {useSiteStore} from "./site"
import {useSiteBlocksStore} from "./siteBlocks"
import {useEditorStore} from "./editor"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"
import Langs from "@/langs"

export const useSitePagesStore = defineStore("sitePages", () => {
  // stores
  const siteStore = useSiteStore()
  const editorStore = useEditorStore()
  const siteBlocksStore = useSiteBlocksStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const pages = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const sitePagesLoaded = computed(() => status.value == "loaded")
  const pagesSorted = computed(() => sortPages(pages.value))

  // actions
  async function bind() {
    const siteId = siteStore.siteId
    // console.log("sitePagesStore: bind():", siteId)
    const collectionRef = collection(firestore, `sites/${siteId}/pages`)
    return await this.attach("pages", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function updatePage(args) {
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const siteId = siteStore.siteId
    const pageId = args.pageId
    const page = this.pages.find((p) => p.id === pageId)
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    const data = {
      config: page.config,
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(pageRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function updatePageSlug(args) {
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const siteId = siteStore.siteId
    const pageId = args.pageId
    const slug = args.slug
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    const data = {
      slug,
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(pageRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function addPageToSite(args) {
    const siteId = siteStore.siteId
    const treeParentId = args.parentPageId
    let parentPage = null
    let treeOrder = 0

    for (let i = 0; i < this.pages.length; i++) {
      // Get the actual parent
      if (this.pages[i].id == treeParentId) {
        parentPage = this.pages[i]
      }

      // Get order of other children of this parent
      if (this.pages[i].treeParentId == treeParentId && this.pages[i].treeOrder >= treeOrder) {
        treeOrder = this.pages[i].treeOrder + 1
      }
    }

    let newPage = {
      config: {
        pageName: {},
      },
      slug: {},
      treeLvl: 0,
      treeParentId: "root",
      treeOrder,
      blocksDraft: [],
      blocksDraftRef: [],
      blocksPublic: [],
      blocksPublicRef: [],
      meta: {
        created: serverTimestamp(),
        deleted: null,
        updated: null,
        published: null,
      },
    }

    siteStore.siteLangsAvailable.forEach((lang) => {
      let defaultPageName = Langs.get("page-new-name", null, lang)
      newPage.config.pageName[lang] = defaultPageName
    })
    if (parentPage) {
      newPage.treeParentId = treeParentId
      newPage.treeLvl = parentPage.treeLvl + 1
    }

    const newPageRef = doc(collection(firestore, `sites/${siteId}/pages`))
    console.log("addPageToSite", newPage, newPageRef)
    await setDoc(newPageRef, newPage)
    return {pageId: newPageRef.id}
  }

  async function removePageFromSite(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    await deleteDoc(pageRef)
  }

  async function publishPage(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const page = pages.value.find((p) => p.id == pageId)
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)

    editorStore.publishingState.page = "publishing-inprogress"

    if (page.blocksDraftRef.length == 0) {
      console.log("no blocks to publish")
      editorStore.publishingState.page = "nothingToPublish"
      setTimeout(() => {
        editorStore.publishingState.page = "waitingToBePublished"
      }, 5000)
      return
    }

    const blocksDraft = page.blocksDraft

    let batches = []
    let batchIndex = 0
    let batchOperations = 0
    batches.push(writeBatch(firestore))

    blocksDraft.forEach((blockDraftId) => {
      const bd = siteBlocksStore.blocks.find((b) => b.id == blockDraftId)
      // console.log('BD:', bd)
      if (bd) {
        let blockDraftRef = doc(firestore, `sites/${siteId}/blocks/${bd.id}`)
        let blockDraftContentDraftRef = doc(
          firestore,
          `sites/${siteId}/blocks/${bd.id}/contents/${bd.contentDraftId}`
        )

        // set published blocks state to public and set contentPublicId & contentPublicRef
        batches[batchIndex].update(blockDraftRef, {
          contentPublicId: bd.contentDraftId,
          contentPublicRef: blockDraftContentDraftRef,
          state: "public",
          "meta.updated": serverTimestamp(),
        })
        batchOperations++

        // mark published block's public content state as archived & set meta.deleted
        if (bd.contentPublicId) {
          let blockDraftContentPublicRef = doc(
            firestore,
            `sites/${siteId}/blocks/${bd.id}/contents/${bd.contentPublicId}`
          )
          batches[batchIndex].update(blockDraftContentPublicRef, {
            state: "archived",
            "meta.deleted": serverTimestamp(),
          })
          batchOperations++
        }

        // mark published block's draft content state as public & set meta.updated
        if (bd.contentDraftId) {
          let blockDraftContentDraftRef = doc(
            firestore,
            `sites/${siteId}/blocks/${bd.id}/contents/${bd.contentDraftId}`
          )
          batches[batchIndex].update(blockDraftContentDraftRef, {
            state: "public",
            "meta.updated": serverTimestamp(),
          })
          batchOperations++
        }
      }

      // console.log('batchOperations:', batchOperations)
      // console.log('batchIndex:', batchIndex)

      if (batchOperations >= 9) {
        batches.push(writeBatch(firestore))
        batchIndex++
        batchOperations = 0
      }
    })

    let blocksDraftRefNew = [] // these are new references
    blocksDraft.forEach((pbd) => {
      blocksDraftRefNew.push(doc(firestore, `sites/${siteId}/blocks/${pbd}`))
    })

    // update page
    batches[batchIndex].update(pageRef, {
      blocksPublic: blocksDraft,
      blocksPublicRef: blocksDraftRefNew,
      "meta.published": serverTimestamp(),
      "meta.updated": serverTimestamp(),
    })
    batchOperations++

    await Promise.all(
      batches.map(async (batch, index) => {
        batch
          .commit()
          .then(() => {
            console.log("batch done:", batch, index)
            return true
          })
          .catch((err) => {
            console.error("publishPage commit error", err)
            return false
          })
      })
    )

    // create job
    const jobsRef = collection(firestore, "jobs")
    const jobRef = doc(jobsRef)
    const jobDoc = {
      dateCreated: serverTimestamp(),
      meta: {
        siteId,
        pageId,
      },
      state: "created",
      type: "publish",
    }

    await setDoc(jobRef, jobDoc).catch((err) => {
      console.error(err)
    })

    // create tasks (that trigger render & publish)
    try {
      const data = {
        dateCreated: serverTimestamp(),
        state: "created",
      }
      const publishIndexesDocRef = doc(firestore, `jobs/${jobRef.id}/tasks/publish-indexes`)
      const publishSiteVarDocRef = doc(firestore, `jobs/${jobRef.id}/tasks/publish-siteVar`)
      await Promise.all([setDoc(publishIndexesDocRef, data), setDoc(publishSiteVarDocRef, data)])
    } catch (err) {
      console.error(err)
    }

    // listen to job for updates
    var unsubscribe = onSnapshot(jobRef, (doc) => {
      if (doc.data().state) {
        // console.log('job updated:', doc.data().state)
        switch (doc.data().state) {
          case "failed":
            editorStore.publishingState.page = "failed"
            setTimeout(() => {
              editorStore.publishingState.page = "waitingToBePublished"
            }, 5000)
            break

          case "created":
            editorStore.publishingState.page = "publishing-inprogress"

            break

          case "finished":
            editorStore.publishingState.page = "published"
            setTimeout(() => {
              editorStore.publishingState.page = "waitingToBePublished"
            }, 5000)
            unsubscribe()
            break
        }
      }
    })
  }

  return {
    // state
    pages,
    status,
    unsubscribe,

    // getters
    sitePagesLoaded,
    pagesSorted,

    // actions
    bind,
    unbind,
    updatePage,
    updatePageSlug,
    addPageToSite,
    removePageFromSite,
    publishPage,
  }
})

function sortPages(pages) {
  // // console.log('Going to sort pages', pages)
  var hashArr = []

  var hierarchySortFunc = function (a, b) {
    return a.treeOrder - b.treeOrder
  }

  var hierarchySort = function (hashArr, key, result) {
    if (hashArr[key] == undefined) return result

    var arr = hashArr[key].sort(hierarchySortFunc)

    for (var i = 0; i < arr.length; i++) {
      result.push(arr[i])
      hierarchySort(hashArr, arr[i].id, result)
    }
    return result
  }

  for (var i = 0; i < pages.length; i++) {
    // if (pages[i].treeParentId == undefined) pages[i].treeParentId = 'root'
    if (hashArr[pages[i].treeParentId] == undefined) hashArr[pages[i].treeParentId] = []
    pages[i].reload = true
    hashArr[pages[i].treeParentId].push(pages[i])
  }

  let sorted = hierarchySort(hashArr, "root", [])
  for (let i = 0; i < sorted.length; i++) {
    let page = sorted[i]
    if (page.treeLvl == -1 || page.treeLvl == 0) {
      continue
    }
  }
  // // console.log('Sorted pages', sorted)
  return sorted
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSitePagesStore, import.meta.hot))
}
