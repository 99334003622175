import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection} from "firebase/firestore"
import {useSiteStore} from "./site"

export const useFilesSiteFilesStore = defineStore("filesSiteFiles", () => {
  // stores
  const siteStore = useSiteStore()

  // state
  const files = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const filesSiteFilesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const siteId = siteStore.siteId
    const collectionRef = collection(firestore, `sites/${siteId}/files`)
    return await this.attach("files", collectionRef, serializeFiles)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    files,
    status,
    unsubscribe,

    // getters
    filesSiteFilesLoaded,

    // actions
    bind,
    unbind,
  }
})

// extend with properties
const serializeFiles = (snapshot, data) => {
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _module: {
      value: "filebrowser",
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFilesSiteFilesStore, import.meta.hot))
}
