export default {
  install() {
    // TODO: get rid of this
    // used in delivery slots
    Date.prototype.addDays = function (days) {
      let d = new Date(this.getTime())
      d.setDate(d.getDate() + parseInt(days))
      return d
    }
    Date.prototype.addMinutes = function (minutes) {
      let d = new Date(this.getTime())
      d.setMinutes(d.getMinutes() + parseInt(minutes))
      return d
    }
    Date.prototype.yyyymmdd = function () {
      var mm = this.getMonth() + 1
      var dd = this.getDate()

      return [this.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("")
    }
    Date.prototype.setHHMM = function (hhmm) {
      if (hhmm && hhmm.length == 5) {
        let p = hhmm.split(":")
        let d = new Date(this.getTime())
        d.setHours(p[0])
        d.setMinutes(p[1])
        d.setSeconds(0)
        return d
      }
      return this
    }
  },
}
