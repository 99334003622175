import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import UserPermissions from "@/service/UserPermissions"
import {useUserRolesStore} from "./userRoles"

export const useUserPermissionsStore = defineStore("userPermissions", () => {
  // stores
  const userRolesStore = useUserRolesStore()

  // state
  const permissions = ref({})

  // actions
  function set() {
    // sites
    if (userRolesStore.roles.sites) {
      this.permissions.sites = {}
      Object.keys(userRolesStore.roles.sites).forEach((siteId) => {
        let sitePermissions = UserPermissions.getUserPermissions(userRolesStore.roles.sites[siteId])
        this.permissions.sites[siteId] = sitePermissions
      })
    }

    // shops
    if (userRolesStore.roles.shops) {
      this.permissions.shops = {}
      Object.keys(userRolesStore.roles.shops).forEach((shopId) => {
        let shopPermissions = UserPermissions.getUserPermissions(userRolesStore.roles.shops[shopId])
        this.permissions.shops[shopId] = shopPermissions
      })
    }
  }

  return {
    // state
    permissions,

    // actions
    set,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserPermissionsStore, import.meta.hot))
}
