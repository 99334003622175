<template>
  <div class="fixed z-50 w-full px-2 pt-2 text-center flex-center" v-if="updateAvailable">
    <p class="rounded-full bg-red-500 px-3 py-1 text-sm text-white">
      {{ Langs.get("version-check-new-version-available") }}
      <a class="cursor-pointer font-bold !text-white underline" @click="reload()">{{
        Langs.get("version-check-reload")
      }}</a>
    </p>
  </div>
</template>

<script>
import Langs from "@/langs"
import axios from "axios"

export default {
  name: "VersionCheck",
  data() {
    return {
      updateAvailable: false,
      // currentVersion: "__RELEASE_SHA__",
      currentVersion: __VERSION__,
      latest: {},
      environment: import.meta.env.VITE_ENVIRONMENT,
      Langs,
    }
  },
  created() {
    this.fetchLatest()
  },
  computed: {},
  methods: {
    async fetchLatest() {
      if (this.environment != "production") return // bundles are hashed only in production
      // console.log('fetchLatest()')
      let response = await axios.get(
        `https://storage.googleapis.com/platformaone-files-${this.environment}/app_admin_latest`,
        {withCredentials: false}
      )

      // console.log('response', response.data)
      // console.log('env', import.meta.env)

      this.latest = response.data
      this.checkVersion()
    },
    checkVersion() {
      if (this.currentVersion != this.latest.version) this.updateAvailable = true
      else {
        setTimeout(() => {
          this.fetchLatest()
        }, 1000 * 60 * 5) // every 5 minutes
      }
    },
    reload() {
      // console.log('reload()')
      window.location.reload(true) // true should bypass browser cache
    },
  },
}
</script>
