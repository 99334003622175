import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useFormSaveStateStore = defineStore("formSaveState", () => {
  // state
  // only way to add object key while keeping reactivity in vue2 is replacing the entire object. but you can't replace ref({}), therefore the actual content has to be under another key
  const state = ref({
    items: {},
  })

  // actions
  function markSaving(args) {
    if (!args) return
    // console.log("markSaving()", args)
    const fieldId = args.meta?.fieldId || args
    if (!fieldId) return
    if (state.value.items[fieldId] == "saving") return
    // console.log('pinia: formSaveState: markSaving()', fieldId)
    state.value.items[fieldId] = "saving"
  }

  function markSaved(args) {
    if (!args) return
    // console.log('markSaved()', args)
    const fieldId = args.meta?.fieldId || args
    if (!fieldId) return
    if (state.value.items[fieldId] == "saved") return

    // visually simulate that it takes some time to save
    setTimeout(() => {
      // console.log('pinia: formSaveState: markSaved(): saved', fieldId)
      state.value.items[fieldId] = "saved"
      setTimeout(() => {
        // console.log('pinia: formSaveState: markSaved(): init', fieldId)
        state.value.items[fieldId] = "init"
      }, 2500)
    }, 250)
  }

  function setState(args) {
    const fieldId = args.meta?.fieldId || args.id
    // console.log('pinia: formSaveState: setState()', args, fieldId, state.value.items,)
    if (!fieldId) return
    if (!args.state) return
    state.value.items = {...state.value.items, [fieldId]: args.state}
  }

  return {
    // state
    state,

    // actions
    markSaving,
    markSaved,
    setState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFormSaveStateStore, import.meta.hot))
}
