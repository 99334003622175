import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  query,
  writeBatch,
} from "firebase/firestore"
import {useSiteStore} from "./site"
import {useSitePagesStore} from "./sitePages"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"
import blockDefaults from "../blockDefaults"

export const useSiteBlocksStore = defineStore("siteBlocks", () => {
  // stores
  const siteStore = useSiteStore()
  const sitePagesStore = useSitePagesStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const blocks = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const siteBlocksLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const siteId = siteStore.siteId
    console.log("siteBlocks bind", siteId)
    const collectionRef = collection(firestore, `sites/${siteId}/blocks`)
    // const q = query(collectionRef, where('state', '==', 'draft'))
    const q = query(collectionRef)
    return await this.attach("blocks", q)
  }

  async function unbind() {
    return await this.detach()
  }

  async function addBlockToPage(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const newBlockIndex = args.newBlockIndex
    const nativeBlock = args.nativeBlock
    const bd = blockDefaults[nativeBlock]
    const newBlockDraftRef = doc(collection(firestore, `sites/${siteId}/blocks`))
    const newBlockDraftContentRef = doc(
      collection(firestore, `sites/${siteId}/blocks/${newBlockDraftRef.id}/contents`)
    )

    console.log("addBlockToPage: blockDefaults:", bd)

    // block
    const newBlockDraft = {
      config: bd.block.config,
      nativeBlock,
      contentDraftId: newBlockDraftContentRef.id,
      contentDraftRef: newBlockDraftContentRef,
      contentPublicId: null,
      contentPublicRef: null,
      state: "draft",
      meta: {
        created: serverTimestamp(),
        deleted: null,
        updated: null,
      },
    }

    // block content
    const newBlockDraftContent = {
      content: bd.content.content,
      state: "draft",
      stylesConfig: bd.content.stylesConfig,
      meta: {
        created: serverTimestamp(),
        deleted: null,
        updated: null,
      },
    }

    // page
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    let blocksDraft = sitePagesStore.pages.find((p) => p.id === pageId).blocksDraft
    blocksDraft.splice(newBlockIndex, 0, newBlockDraftRef.id)
    let blocksDraftRef = []
    blocksDraft.forEach((pbd) => {
      blocksDraftRef.push(doc(firestore, `sites/${siteId}/blocks/${pbd}`))
    })

    let pageData = {
      blocksDraft,
      blocksDraftRef,
      "meta.updated": serverTimestamp(),
    }
    if (nativeBlock == "commerce/productDetail") pageData.containsProductDetailBlock = true

    // console.log('await Promise.all: before')
    // await Promise.all([
    //   setDoc(newBlockDraftRef, newBlockDraft),
    //   setDoc(newBlockDraftContentRef, newBlockDraftContent),
    //   updateDoc(pageRef, pageData),
    // ])
    // console.log('await Promise.all: after')

    const batch = writeBatch(firestore)
    batch.set(newBlockDraftRef, newBlockDraft)
    batch.set(newBlockDraftContentRef, newBlockDraftContent)
    batch.update(pageRef, pageData)

    console.log("await batch: before")
    await batch.commit()
    console.log("await batch: after")

    return {blockId: newBlockDraftRef.id}
  }

  async function addCustomBlockToPage(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const newBlockIndex = args.newBlockIndex
    const nativeBlock = args.nativeBlock
    const blockTemplate = args.blockTemplate

    // construct default content from block template
    let bd = {
      block: {
        config: {},
      },
      content: {
        content: {},
        stylesConfig: {},
      },
    }

    const btdcc = blockTemplate.defaults.content.content
    Object.keys(btdcc).forEach((key) => {
      bd.content.content[key] = blockDefaults.t[btdcc[key]]
    })

    // console.log('bd', bd)
    // let bd = blockDefaults[nativeBlock]
    const newBlockDraftRef = doc(collection(firestore, `sites/${siteId}/blocks`))
    const newBlockDraftContentRef = doc(
      collection(firestore, `sites/${siteId}/blocks/${newBlockDraftRef.id}/contents`)
    )

    // console.log('addBlockToPage: blockDefaults:', bd)

    // block
    const newBlockDraft = {
      config: bd.block.config,
      nativeBlock,
      blockTemplateId: blockTemplate.id,
      contentDraftId: newBlockDraftContentRef.id,
      contentDraftRef: newBlockDraftContentRef,
      contentPublicId: null,
      contentPublicRef: null,
      state: "draft",
      meta: {
        created: serverTimestamp(),
        deleted: null,
        updated: null,
      },
    }

    // block content
    const newBlockDraftContent = {
      content: bd.content.content,
      state: "draft",
      stylesConfig: bd.content.stylesConfig,
      meta: {
        created: serverTimestamp(),
        deleted: null,
        updated: null,
      },
    }

    // page
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    let blocksDraft = sitePagesStore.pages.find((p) => p.id === pageId).blocksDraft
    blocksDraft.splice(newBlockIndex, 0, newBlockDraftRef.id)
    let blocksDraftRef = []
    blocksDraft.forEach((pbd) => {
      blocksDraftRef.push(doc(firestore, `sites/${siteId}/blocks/${pbd}`))
    })

    let pageData = {
      blocksDraft,
      blocksDraftRef,
      "meta.updated": serverTimestamp(),
    }
    if (nativeBlock == "commerce/productDetail") pageData.containsProductDetailBlock = true

    await Promise.all([
      setDoc(newBlockDraftRef, newBlockDraft),
      setDoc(newBlockDraftContentRef, newBlockDraftContent),
      updateDoc(pageRef, pageData),
    ])

    return {blockId: newBlockDraftRef.id}
  }

  async function addSharedBlockToPage(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const newBlockIndex = args.newBlockIndex
    const originalBlockId = args.originalBlockId // adding shared block
    const blockRef = doc(firestore, `sites/${siteId}/blocks/${originalBlockId}`)

    console.log("addSharedBlockToPage", blockRef.id)

    // page
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    let blocksDraft = sitePagesStore.pages.find((p) => p.id === pageId).blocksDraft
    blocksDraft.splice(newBlockIndex, 0, blockRef.id)
    let blocksDraftRef = []
    blocksDraft.forEach((pbd) => {
      blocksDraftRef.push(doc(firestore, `sites/${siteId}/blocks/${pbd}`))
    })

    const data = {
      blocksDraft,
      blocksDraftRef,
      "meta.updated": serverTimestamp(),
    }

    await updateDoc(pageRef, data)

    return {blockId: originalBlockId}
  }

  async function removeBlockFromPage(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const blockId = args.blockId
    const blockIndex = args.blockIndex
    const block = blocks.value.find((b) => b.id == blockId)
    let blocksDraft = sitePagesStore.pages.find((p) => p.id === pageId).blocksDraft
    // console.log('blocksDraft:before', blocksDraft)
    blocksDraft = blocksDraft.filter((bd, index) => bd !== blockId && index != blockIndex)
    let blocksDraftRef = []
    blocksDraft.forEach((pbd) => {
      blocksDraftRef.push(doc(firestore, `sites/${siteId}/blocks/${pbd}`))
    })

    // console.log('blocksDraft:after', blocksDraft)
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    const data = {
      blocksDraft,
      blocksDraftRef,
      "meta.updated": serverTimestamp(),
    }
    console.log("removeBlockFromPage: block:", block)
    if (block.nativeBlock == "commerce/productDetail") data.containsProductDetailBlock = false
    await updateDoc(pageRef, data)
  }

  async function swapBlocks(args) {
    const siteId = siteStore.siteId
    const pageId = args.pageId
    const blockIndexA = args.blockIndexA
    const blockIndexB = args.blockIndexB
    let blocksDraft = sitePagesStore.pages.find((p) => p.id === pageId).blocksDraft
    console.log("swapBlocks:before", blocksDraft)
    let buffer = blocksDraft[blockIndexB]
    blocksDraft[blockIndexB] = blocksDraft[blockIndexA]
    blocksDraft[blockIndexA] = buffer
    console.log("swapBlocks:after", blocksDraft)
    const pageRef = doc(firestore, `sites/${siteId}/pages/${pageId}`)
    let blocksDraftRef = []
    blocksDraft.forEach((pbd) => {
      blocksDraftRef.push(doc(firestore, `sites/${siteId}/blocks/${pbd}`))
    })
    const data = {
      blocksDraft,
      blocksDraftRef,
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(pageRef, data)
  }

  async function updateBlock(args) {
    console.log("pinia: siteBlocks: updateBlock()", args)
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const siteId = siteStore.siteId
    const blockId = args.blockId
    const block = args.data
    // console.log('updateBlock:', siteId, blockId, block)
    const docRef = doc(firestore, `sites/${siteId}/blocks/${blockId}`)
    const data = {
      config: block.config,
      // ? content draft/public id refs
      // ? state
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    if (args.meta?.fieldId) formSaveStateStore.markSaved(args)
    return
  }

  async function updateBlockContent(args) {
    console.log("pinia: siteBlocks: updateBlockContent()", args)
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const siteId = siteStore.siteId
    const blockId = args.blockId
    const contentDraft = args.data
    if (!contentDraft) return false
    // console.log('updateBlockContent:', siteId, blockId, block)

    const docRef = doc(firestore, `sites/${siteId}/blocks/${blockId}/contents/${contentDraft.id}`)
    const data = {
      content: contentDraft.content,
      stylesConfig: contentDraft.stylesConfig,
      // ? state
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    if (args.meta?.fieldId) formSaveStateStore.markSaved(args)
    return
  }

  return {
    // state
    blocks,
    status,
    unsubscribe,

    // getters
    siteBlocksLoaded,

    // actions
    bind,
    unbind,
    addBlockToPage,
    addCustomBlockToPage,
    addSharedBlockToPage,
    removeBlockFromPage,
    swapBlocks,
    updateBlock,
    updateBlockContent,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSiteBlocksStore, import.meta.hot))
}
