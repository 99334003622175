import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collectionGroup, query, doc, orderBy, documentId, startAt, endAt} from "firebase/firestore"
import {useSiteStore} from "./site"

export const useSiteBlocksContentsStore = defineStore("siteBlocksContents", () => {
  // stores
  const siteStore = useSiteStore()

  // state
  const contents = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const siteBlocksContentsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const siteId = siteStore.siteId
    const siteRef = doc(firestore, `sites/${siteId}`)
    const collectionRef = collectionGroup(firestore, `contents`)
    const q = query(
      collectionRef,
      orderBy(documentId()),
      startAt(siteRef.path),
      endAt(siteRef.path + `\uf8ff`)
    )
    return await this.attach("contents", q)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    contents,
    status,
    unsubscribe,

    // getters
    siteBlocksContentsLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSiteBlocksContentsStore, import.meta.hot))
}
