import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc, serverTimestamp, updateDoc} from "firebase/firestore"
import {useShopStore} from "./shop"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const useShopModelStore = defineStore("shopModel", () => {
  // stores
  const shopStore = useShopStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const model = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopModelLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind(args) {
    const shopId = shopStore.shopId
    const modelId = args.modelId
    const docRef = doc(firestore, `shops/${shopId}/models/${modelId}`)
    return await this.attach("model", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function update(args) {
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const shopId = shopStore.shopId
    const modelId = args.modelId
    const docRef = doc(firestore, `shops/${shopId}/models/${modelId}`)
    const data = {
      ...args.data,
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    if (args.meta?.fieldId) formSaveStateStore.markSaved(args)
  }

  return {
    // state
    model,
    status,
    unsubscribe,

    // getters
    shopModelLoaded,

    // actions
    bind,
    unbind,
    update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopModelStore, import.meta.hot))
}
