export default {
  text: {
    "version-check-new-version-available": "new version available",
    "version-check-reload": "reload",

    main_title: "Administration",

    "menu-cms": "cms",
    "menu-pages": "Pages",
    "menu-posts": "Posts",
    "menu-commerce": "Commerce",
    "menu-analytics": "Analytics",
    "menu-settings": "Settings",
    "menu-help": "Help",
    "menu-logout": "Logout",
    "menu-publisher": "Publisher",
    "menu-designer": "Designer",

    "sites-main-headline": "My Sites",

    "option-yes": "Yes",
    "option-no": "No",

    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

    "login-header": "Login",
    "login-header-label": "Proceed to login here...",
    "login-user-name": "User name",
    "login-user-password": "Password",
    "login-user-email": "E-mail",
    "login-submit-button": "Sign in",
    "login-help": "Need help?",
    "login-use-pwd": "use password",
    "login-use-pwdless": "use e-mail link",
    "login-use-passkey": "use Passkey",
    "login-msg-welcome": "platforma.one",
    "login-error-wrong-password": "Wrong password",
    "login-error-too-many-requests": "Too many attempts, try later.",
    "login-validator-passwordlessemail-errormessage-notvalid":
      "Not close enough. Misspelled smoething?",
    "login-validator-passwordlessemail-errormessage-notfound": "We didn't find you. Are you real?",
    "login-validator-passwordlessemail-errormessage-empty": "This is the place to put your e-mail.",
    "login-validator-passwordemail-errormessage-bad": "This",
    "login-validator-passwordemail-errormessage-empty": "Come on, be more specific.",
    "login-validator-passwordemail-errormessage-notvalid": "That's not an email!",
    "login-validator-password-errormessage-bad": "or that is not correct.",
    "login-validator-password-errormessage-empty": "Come on, passwords are important!",
    "login-email-instructions-headline": "Check your email",
    "login-email-sent-instructions-pt1": "Login email is on its way to",
    "login-email-sent-instructions-pt2": "",
    "login-email-sent-instructions-pt3": "You may close this window.",
    "login-email-instructions-tryagain": "Try again",

    "guest-signup-email-instructions-headline": "Wohoo, only one step away from your site!",
    "guest-signup-email-sent-instructions-pt1":
      "Confirmation link to publish your site was sent to",
    "guest-signup-email-sent-instructions-pt2":
      "You may close this window, new one will be opened from the link you will receive.",
    "guest-signup-published-headline": "It's on!",
    "guest-signup-published-text": "Your site is running live at",
    "guest-signup-published-open-btn": "Check it out",

    "main-site-menu": "Main menu",

    "settings-header": "Settings",
    "site-config-title": "Site title",

    "pages-header": "Pages",
    "pages-add-subpage": "Add subpage",
    "pages-stop-adding-subpage": "Finished adding subpage",
    "page-qr-code-generator-page-lang": "Language",
    "page-list": "Page list",
    "main-view-site": "View site",
    "page-selected-layout": "Layout",
    "page-selected-content": "Content",
    "page-selected-settings": "Settings",
    "page-selected-styles": "Styles",
    "page-setup-title": "Title",
    "page-setup-role": "Required role",
    "page-setup-description": "Desription",
    "page-setup-slugs": "URL",
    "page-setup-visible": "Visible",
    "page-button-delete": "Delete page",
    "page-add-hint": "Click a point to insert",
    "page-duplicate": "Duplicate",
    "page-duplicate-parent": "Parent page",
    "page-duplicate-new-title": "New page title",
    "page-duplicate-execute": "Make it so",
    "page-clone-fill_name": "Fill page name for language: ",
    "page-clone-parent_no_slug": "Parent page has no slug!",
    "page-clone-slag_for_lang": "Parent page has no slug for language: ",
    "page-clone-done": "Duplication completed.",
    "page-clone-error": "Duplication failed!",
    "page-block-styling": "Styling",
    "label-add-language-version": "Add language version",

    "generic-download": "Download",
    "commerce-generate-qr-code": "Generate QR code",
    "commerce-remove-variant": "Remove variant",
    "commerce-qr-code-generator-auto-add-to-cart": "Automatically add to cart",
    "commerce-qr-code-generator-delivery-method": "Delivery method",
    "commerce-qr-code-generator-hide-other-delivery-methods": "Hide other delivery methods",
    "commerce-order-name-anonymous": "no name",
    "commerce-order-item-giftcard-resendToCustomer": "resend to customer",
    "commerce-order-item-giftcard-resendToRecipient": "resend to recipient",
    "commerce-order-item-giftcard-download": "download",
    "commerce-header": "E-shop",
    "commerce-shop-name": "Shop name",
    "commerce-no-site": "Select the website first",
    "commerce-new-eshop": "Create new e-shop",
    "commerce-carts": "Carts",
    "commerce-orders": "Orders",
    "commerce-inventory": "Inventory",
    "commerce-shop-items": "Shop items",
    "commerce-customers": "Customers",
    "commerce-subscriptions": "Subscriptions",
    "commerce-orders-setup": "Settings",
    "commerce-export": "Export",
    "commerce-orders-flow-setup": "Order flow setup",
    "commerce-shop-create-model": "Create new product form specification",
    "commerce-shop-update-model": "Update product form specification",
    "commerce-products-header": "Products",
    "commerce-product-attr-name": "Field name",
    "commerce-product-model-name": "Product type name",
    "commerce-shop-create-product": "Create new product",
    "commerce-shop-update-product": "Update product details",
    "commerce-product-header": "Product",
    "commerce-fee-reports": "Fee reports",
    "commerce-fee-billing": "Billing",
    "product-created-info": "Product has been created.",
    "product-not-created-error": "A problem prevented product creation!",
    "product-saved-info": "Product has been updated.",
    "product-not-saved-error": "A problem prevented product alteration!",
    "product-question-delete": "Do you really want to remove the product (including variants)?",
    "product-image-main": "Main product",
    "product-image-variant": "Main variant",
    "product-fees": "Fees",
    "product-sku-already-exists": "SKU must be unique",
    "upload-file-in-process": "Upload in progress",
    "commerce-setup": "Commerce setup",
    "commerce-supported-curr": "Supported currencies",
    "commerce-default-curr": "Default currency",
    "commerce-supported-langs": "Supported languages",
    "commerce-default-lang": "Default language",
    "commerce-order-num-format": "Order number format",
    "commerce-order-num-format-hint": "...",
    "commerce-sell-units": "Possible sale units",
    "commerce-sell-units-hint": "...",
    "commerce-bank-connection": "Bank connection",
    "commerce-bank-name": "Bank name",
    "commerce-bank-code": "Bank code",
    "commerce-account-number": "Account number",
    "commerce-iban": "IBAN",
    "commerce-swift": "SWIFT",
    "commerce-company-address": "Company address",
    "commerce-setup-payments": "Payment setup",
    "commerce-payment-methods": "Payment methods",
    "commerce-payment-method-add": "Add another payment method",
    "commerce-delivery-planning-bulk-edit-enable": "Bulk edit",
    "commerce-delivery-planning-bulk-edit-disable": "Cancel bulk edit",
    "commerce-delivery-planning-delete-all": "Remove all",
    "commerce-tax-values": "Available tax values",
    "commerce-delivery-channels": "Supported delivery methods",
    "commerce-setup-deliveries": "Deliveries setup",
    "commerce-delivery-method-add": "Add another delivery method",
    "commerce-setup-delivery-planning-settings": "Delivery planning settings",
    "commerce-setup-delivery-planning": "Delivery planning",
    "commerce-delivery-next-day-order": "Last next-day order at:",
    "commerce-delivery-next-days": "Show next days:",
    "commerce-delivery-use-defaults": "Use defaults",
    "commerce-delivery-add-slot": "+ Add Slot",
    "commerce-delivery-additional-fields": "Additional cart form fields",
    "commerce-delivery-add-field": "+ Add field",
    "commerce-delivery-planning": "Delivery planning",
    "commerce-delivery-default-slots": "Slots template",
    "commerce-setup-delivery-planning-slots": "Active slots",
    "commerce-delivery-settings": "Settings",
    "commerce-delivery-point-title": "Delivery point",
    "commerce-flow-delete-error": "Not possible to delete state. It is used in: [%0]",
    "commerce-flow-cmd-set": "Set a value",
    "commerce-flow-cmd-exe": "Execute operation",
    "commerce-flow-operation-request-liftago": "Request Liftago",
    "commerce-flow-operation-zasilkovna-upload-to-zasilkovna": "Upload to Zasilkovna",
    "commerce-flow-operation-get-shipping-labels": "Print labels",
    "commerce-flow-operation-send-order-state-change-notification":
      "E-mail about order state change",
    "commerce-flow-check-equal": "Equal",
    "commerce-flow-check-not-equal": "Not Equal",
    "commerce-flow-check-contains": "Contains",
    "commerce-flow-check-not-contains": "Not contans",

    "commerce-order-tags-paid": "paid",
    "commerce-order-tags-unpaid": "unpaid",
    "commerce-order-tags-contactless": "contactless",

    "commerce-price-brutto": "incl. VAT",
    "commerce-price-netto": "excl. VAT",
    "commerce-price-units": "units",
    "commerce-price-enter-without-vat": "enter price without VAT",
    "commerce-price-enter-with-vat": "enter price with VAT",
    "commerce-price-currency": "currency",
    "commerce-price-vat": "VAT",
    "commerce-price-initial": "Initial",

    "commerce-promo-codes": "Discount codes",
    "commerce-gift-cards": "Gift cards",
    "commerce-promocode-label": "Code",
    "commerce-promocode-codeslist": "Code list",
    "commerce-promocode-count": "Count of individual usage",
    "commerce-promocode-unit": "Unit",
    "commerce-promocode-value": "Value",
    "commerce-promocode-from": "Valid from",
    "commerce-promocode-to": "Valid to",
    "commerce-promocode-tags": "Tags",
    "commerce-promocode-note": "Note",
    "commerce-promocode-kind": "Kind",

    // commerce gift cards
    "commerce-giftcards-table-code": "Code",
    "commerce-giftcards-table-status": "Status",
    "commerce-giftcards-table-value": "Value",
    "commerce-giftcards-table-product": "Product",
    "commerce-giftcards-table-validFrom": "Valid from",
    "commerce-giftcards-table-validTo": "Valid to",
    "commerce-giftcards-table-purchasedIn": "Purchased in",
    "commerce-giftcards-table-usedAt": "Used at",
    "commerce-giftcards-table-usage": "Usage",

    "commerce-giftcards-status-available": "Available",
    "commerce-giftcards-status-used": "Used",
    "commerce-giftcards-status-usedPartially": "Partially used",
    "commerce-giftcards-status-expired": "Expired",

    "commerce-giftcards-details-allowedUsage-atStore": "at store",
    "commerce-giftcards-details-allowedUsage-eShop": "e-shop",
    "commerce-giftcards-details-allowedUsage-subtractable": "subtractable",

    "commerce-giftcards-details-valid": "valid",
    "commerce-giftcards-details-valueLeft": "value left",
    "commerce-giftcards-details-details": "details",
    "commerce-giftcards-details-allowedUsage": "allowed usage",
    "commerce-giftcards-details-validity": "validity",
    "commerce-giftcards-details-history": "history",
    "commerce-giftcards-details-order": "order",
    "commerce-giftcards-details-validate": "validate",

    "commerce-giftcards-details-validate-amount": "amount",
    "commerce-giftcards-details-validate-privateNote": "private note",
    "commerce-giftcards-details-validate-markUsed": "mark used",
    "commerce-giftcards-details-validate-markUsedConfirm": "confirm",

    // commerce setup
    // invoicing
    "commerce-setup-company-title-invoicing": "Invoicing",
    "commerce-setup-company-label-orderNumberFormat": "Order number format",

    // invoice info
    "commerce-setup-company-title-company": "Invoice info",
    "commerce-setup-company-label-name": "Company",
    "commerce-setup-company-label-ico": "IČO",
    "commerce-setup-company-label-dic": "DIČ",
    "commerce-setup-company-label-legalNote": "Note",

    // address
    "commerce-setup-company-label-street": "Street",
    "commerce-setup-company-label-streetNumber": "Street no.",
    "commerce-setup-company-label-city": "City",
    "commerce-setup-company-label-postal": "Postal",
    "commerce-setup-company-label-country": "Country",

    // banking
    "commerce-setup-company-label-bankName": "Bank name",
    "commerce-setup-company-label-bankCode": "Bank code",
    "commerce-setup-company-label-accountNumber": "Account number",
    "commerce-setup-company-label-iban": "IBAN",
    "commerce-setup-company-label-swift": "SWIFT",

    // contact
    "commerce-setup-company-label-phone": "Phone",
    "commerce-setup-company-label-email": "E-mail",

    "commerce-settings-general-shop-name": "Shop name",
    "commerce-settings-payment-taxes": "VAT [%]",

    "commerce-orders-hide-column": "Hide column",
    "commerce-orders-select-multiple": "Select orders",
    "commerce-orders-cancel-selection": "Cancel selection",
    "commerce-orders-of-selected": "selected",
    "commerce-orders-of-all": "all",

    "commerce-courier-liftago-courierPosition": "Courier location",
    "commerce-courier-liftago-rideStatus-PROCESSING": "Looking for driver",
    "commerce-courier-liftago-rideStatus-ACCEPTED": "Driver selected",
    "commerce-courier-liftago-rideStatus-WAITING": "Driver awaiting pickup",
    "commerce-courier-liftago-rideStatus-IN_PROGRESS": "Items on board",
    "commerce-courier-liftago-rideStatus-COMPLETED": "Delivery finished",
    "commerce-courier-liftago-rideStatus-CANCELLED": "Delivery cancelled",
    "commerce-courier-liftago-rideStatus-REJECTED": "Couldn't find driver",
    "commerce-courier-liftago-stopState-IN_QUEUE": "In delivery",
    "commerce-courier-liftago-stopState-DELIVERED": "Delivered",
    "commerce-courier-liftago-stopState-UNDELIVERED": "Not delivered",
    "commerce-courier-liftago-handoverStatus-NEW": "Processed",
    "commerce-courier-liftago-handoverStatus-SCHEDULED": "Scheduled",
    "commerce-courier-liftago-handoverStatus-IN_RIDE": "Awaiting pickup",
    "commerce-courier-liftago-handoverStatus-ON_BOARD": "On board",
    "commerce-courier-liftago-handoverStatus-DELIVERING": "Delivering",
    "commerce-courier-liftago-handoverStatus-DELIVERED": "Delivered",
    "commerce-courier-liftago-handoverStatus-UNDELIVERED": "Undelivered",
    "commerce-courier-liftago-handoverStatus-RETURNING": "Returning",
    "commerce-courier-liftago-handoverStatus-RETURNED": "Returned",
    "commerce-courier-liftago-handoverStatus-CANCELED": "Canceled",
    "commerce-courier-liftago-handoverStatus-CANCELED_IN_RIDE": "Canceled during pickup",
    "commerce-courier-liftago-handoverStatus-CANCELED_WITH_POB": "Canceled after boarding",
    "commerce-courier-liftago-handoverStatus-CANCELED_BY_DRIVER": "Canceled by driver",
    "commerce-courier-liftago-handoverStatus-WONT_DELIVER": "Undeliverable",

    "address-street": "Street",
    "address-number": "Building Number",
    "address-area": "Area",
    "address-city": "City",
    "address-zip": "ZIP code",
    "address-region": "Region",
    "address-country": "Country",
    "commerce-invoice-disclaimer": "Invoice disclaimer",

    // if you change this, change the client translation file
    "cart-main-title": "Cart",
    "cart-header-backToShop": "Continue shoppping",
    "cart-itemList-empty": "Nothing here. 😔 Pick something 🙃",
    "cart-promoCode-title": "Promo code",
    "cart-promoCode-insert": "Use promo code",
    "cart-promoCode-field-promoCode-label": "Do you have a promo code?",
    "cart-promoCode-field-promoCode-placeholder":
      "Magic key, that makes you save while spending. 😜",
    "cart-promoCode-field-promoCode-helpText": "ABRACADABRA",
    "cart-delivery-title": "Delivery",
    "cart-delivery-branch-title": "Branch",
    "cart-delivery-undeliverableItemsCombination":
      "This product combination can't be delivered. Please order separately.",
    "cart-delivery-deliveryOptions": "Delivery options",
    "cart-customerInfo-title": "My info",
    "cart-customerInfo-actionButtons-addBillingInfo": "Enter billing info",
    "cart-customerInfo-fields-customerEmail-label": "E-mail",
    "cart-customerInfo-fields-customerEmail-placeholder": "john@smith.com",
    "cart-customerInfo-fields-customerEmail-helpText":
      "We need your e-mail to inform you about your order state.",
    "cart-customerInfo-fields-customerName-label": "Name and surname",
    "cart-customerInfo-fields-customerName-placeholder": "John Smith",
    "cart-customerInfo-fields-customerName-helpText": "",
    "cart-customerInfo-fields-customerPhone-label": "Phone",
    "cart-customerInfo-fields-customerPhone-placeholder": "123 456 789",
    "cart-customerInfo-fields-customerPhone-helpText":
      "We need your phone in order to contact you promply in case of an unexpected event.",
    "cart-billingInfo-title": "Invoicing",
    "cart-billingInfo-actionButtons-fillManually": "Fill manually",
    "cart-billingInfo-actionButtons-companyAddress": "Invoice company",
    "cart-billingInfo-fields-billingAddress-label": "Address",
    "cart-billingInfo-fields-billingAddress-placeholder": "Main st. 1, Prague",
    "cart-billingInfo-fields-billingAddress-helpText": "",
    "cart-billingInfo-fields-billingAddressStreet-label": "Street",
    "cart-billingInfo-fields-billingAddressStreet-placeholder": "Main st. 1, Prague",
    "cart-billingInfo-fields-billingAddressStreet-helpText": "",
    "cart-billingInfo-fields-billingAddressCity-label": "City",
    "cart-billingInfo-fields-billingAddressCity-placeholder": "Prague",
    "cart-billingInfo-fields-billingAddressCity-helpText": "",
    "cart-billingInfo-fields-billingAddressPostal-label": "Postal",
    "cart-billingInfo-fields-billingAddressPostal-placeholder": "110 00",
    "cart-billingInfo-fields-billingAddressPostal-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyICO-label": "Reg. no.",
    "cart-billingInfo-fields-billingAddressCompanyICO-placeholder": "123 45 678",
    "cart-billingInfo-fields-billingAddressCompanyICO-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyName-label": "Company",
    "cart-billingInfo-fields-billingAddressCompanyName-placeholder": "Company s.r.o.",
    "cart-billingInfo-fields-billingAddressCompanyName-helpText": "",
    "cart-billingInfo-fields-invoiceNote-label": "Invoice note",
    "cart-billingInfo-fields-invoiceNote-placeholder": "Gift for the boss",
    "cart-billingInfo-fields-invoiceNote-helpText": "",
    "cart-deliveryInfo-title": "Delivery",
    "cart-deliveryInfo-actionButtons-fillManually": "Fill manually",
    "cart-deliveryInfo-actionButtons-companyAddress": "Deliver to a business",
    "cart-deliveryInfo-actionButtons-otherBillingInfo": "Other billing info",
    "cart-deliveryInfo-actionButtons-addBillingInfo": "Add billing info",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-true": "for me",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-false": "as a gift",
    "cart-deliveryInfo-fields-recipientName-label": "Name and surname",
    "cart-deliveryInfo-fields-recipientName-placeholder": "John Smith",
    "cart-deliveryInfo-fields-recipientName-helpText": "",
    "cart-deliveryInfo-fields-recipientPhone-label": "Phone",
    "cart-deliveryInfo-fields-recipientPhone-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-recipientPhone-helpText": "We need a phone for the delivery courier.",
    "cart-deliveryInfo-fields-deliveryAddress-label": "Adresa",
    "cart-deliveryInfo-fields-deliveryAddress-placeholder": "Main st. 1, Prague",
    "cart-deliveryInfo-fields-deliveryAddress-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressStreet-label": "Street",
    "cart-deliveryInfo-fields-deliveryAddressStreet-placeholder": "Main st. 1, Prague",
    "cart-deliveryInfo-fields-deliveryAddressStreet-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCity-label": "City",
    "cart-deliveryInfo-fields-deliveryAddressCity-placeholder": "Prague",
    "cart-deliveryInfo-fields-deliveryAddressCity-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressPostal-label": "Postal",
    "cart-deliveryInfo-fields-deliveryAddressPostal-placeholder": "110 00",
    "cart-deliveryInfo-fields-deliveryAddressPostal-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-label": "Reg. no.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-label": "Company",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-placeholder": "Company s.r.o.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-helpText": "",
    "cart-deliveryInfo-fields-deliveryNote-label": "Delivery note",
    "cart-deliveryInfo-fields-deliveryNote-placeholder": "",
    "cart-deliveryInfo-fields-deliveryNote-helpText": "",
    "cart-deliveryInfo-fields-recipientNote-label": "Recipient note",
    "cart-deliveryInfo-fields-recipientNote-placeholder": "",
    "cart-deliveryInfo-fields-recipientNote-helpText": "",
    "cart-deliveryInfo-fields-recipientInQuarantine-labelTrue": "Contactless delivery",
    "cart-deliverySlots-noMoreDaysAvailable": "no other days<br>planned yet 😔",
    "cart-deliverySlots-noMoreSlotsAvailable": "No more free<br>slots available 😔",
    "cart-note-title": "Note",
    "cart-note-insert": "Add note",
    "cart-note-fields-note-label": "Anything else on your mind?",
    "cart-note-fields-note-placeholder": "Load!",
    "cart-note-fields-note-helpText":
      "We will try to give you the moon! We're still people, though 😜",
    "cart-payment-title": "Payment",
    "cart-stripeWallet-otherMethod": "Other method",
    "cart-orderButton-terms-pre": "By payment I agree to",
    "cart-orderButton-terms-link": "terms",
    "cart-confirmation-orderNumber-heading": "order",
    "cart-confirmation-email-heading": "confirmation",
    "cart-confirmation-merchantNote": "Looking forward to next time!",
    "cart-confirmation-continueShoppingBtn": "something else? 😜",

    // confirmation texts - these are configured in delivery methods
    "cart-confirmation-method-recipientSameAsCustomer-title": "Thank you",
    "cart-confirmation-method-recipientSameAsCustomer-paragraph": "Your order is being processed.",
    "cart-confirmation-method-recipientNotSameAsCustomer-title": "Thank you",
    "cart-confirmation-method-recipientNotSameAsCustomer-paragraph":
      "Your order is being processed and soon will be dispatched to the recipient.",

    // labels for override form
    "override-section--postfix-label": "label",
    "override-section--postfix-placeholder": "placeholder",
    "override-section--postfix-helpText": "help",
    "override-section-cart-main": "main",
    "override-section-cart-main-key-cart-main-title": "cart title",
    "override-section-cart-header": "header",
    "override-section-cart-header-key-cart-header-backToShop": "close cart button",
    "override-section-cart-itemList": "items",
    "override-section-cart-itemList-key-cart-itemList-empty": "empty cart",
    "override-section-cart-promoCode": "promo code",
    "override-section-cart-promoCode-key-cart-promoCode-title": "promo code title",
    "override-section-cart-promoCode-key-cart-promoCode-field-promoCode": "promo code",
    "override-section-cart-delivery": "delivery",
    "override-section-cart-delivery-key-cart-delivery-title": "delivery title",
    "override-section-cart-delivery-key-cart-delivery-branch-title": "branch title",
    "override-section-cart-delivery-key-cart-delivery-undeliverableItemsCombination":
      "undeliveable items combination text",
    "override-section-cart-delivery-key-cart-delivery-deliveryOptions": "delivery options title",
    "override-section-cart-customerInfo": "customer",
    "override-section-cart-customerInfo-key-cart-customerInfo-title": "customer title",
    "override-section-cart-customerInfo-key-cart-customerInfo-actionButtons-addBillingInfo":
      "add billing info button",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerEmail": "e-mail",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerName": "name",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerPhone": "phone",
    "override-section-cart-billingInfo": "billing info",
    "override-section-cart-billingInfo-key-cart-billingInfo-title": "billing info title",
    "override-section-cart-billingInfo-key-cart-billingInfo-actionButtons-fillManually":
      "fill manually button",
    "override-section-cart-billingInfo-key-cart-billingInfo-actionButtons-companyAddress":
      "company address button",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddress": "address",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressStreet": "street",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCity": "city",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressPostal": "postal",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCompanyICO":
      "Reg. no.",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCompanyName":
      "Company",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-invoiceNote": "invoice note",
    "override-section-cart-deliveryInfo": "delivery info",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-title": "delivery info title",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-fillManually":
      "fill manually button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-companyAddress":
      "company address button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-otherBillingInfo":
      "other billing info button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-addBillingInfo":
      "add billing info button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-recipientSameAsCustomerOptions-true":
      "recipient is same as buyer",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-recipientSameAsCustomerOptions-false":
      "recipient is different from buyer",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientName": "name",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientPhone": "phone",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddress": "address",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressStreet":
      "street",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCity": "city",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressPostal":
      "postal",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCompanyICO":
      "reg. no.",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCompanyName":
      "company",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryNote": "delivery note",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientNote":
      "recipient note",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientInQuarantine-labelTrue":
      "recipient in quarantine",
    "override-section-cart-deliverySlots": "delivery slots",
    "override-section-cart-deliverySlots-key-cart-deliverySlots-noMoreDaysAvailable":
      "no more days available",
    "override-section-cart-deliverySlots-key-cart-deliverySlots-noMoreSlotsAvailable":
      "no more slots available",
    "override-section-cart-note": "note",
    "override-section-cart-note-key-cart-note-title": "note title",
    "override-section-cart-note-key-cart-note-fields-note": "note",
    "override-section-cart-payment": "payment",
    "override-section-cart-payment-key-cart-payment-title": "payment title",
    "override-section-cart-confirmation": "confirmation",
    "override-section-cart-confirmation-key-cart-confirmation-orderNumber-heading":
      "order number heading",
    "override-section-cart-confirmation-key-cart-confirmation-email-heading": "e-mail heading",
    "override-section-cart-confirmation-key-cart-confirmation-merchantNote": "merchant note",
    "override-section-cart-confirmation-key-cart-confirmation-continueShoppingBtn":
      "continue shopping button",
    "override-section-cart-orderButton": "order",
    "override-section-cart-orderButton-key-cart-orderButton-terms-pre": "terms - perex",
    "override-section-cart-orderButton-key-cart-orderButton-terms-link": "terms - link",

    "product-field-title": "Title",
    "product-field-description": "Description",
    "product-field-google-product-category": "Product Category ID",
    "product-tags": "Tags",
    "product-sku": "SKU",
    "product-options": "Options",
    "product-variants": "Variants",
    "product-dynamic-sections": "Dynamic sections",
    "product-customer-form": "Customer form",
    "product-kind": "Kind",
    "product-sub-kind": "Subkind",
    "product-images": "Images",
    "product-gift-card-images": "Images for gift card",
    "product-gift-card-settings": "Gift Card settings",
    "product-booking-settings": "Booking settings",
    "product-quantity": "Stock & visibility",
    "product-quantity-stock": "Stock",
    "product-quantity-available": "available",
    "product-quantity-blocked": "blocked",
    "product-quantity-reserved": "reserved",
    "product-quantity-when-sold-out": "when sold out",
    "product-shipping": "Shipping",
    "product-shipping-dimensions": "Dimensions",
    "product-shipping-available-deliveries": "Available delivery",
    "product-channels": "Channels",
    "item-labels": "attributes",
    "item-labels-adding-name": "name",
    "item-labels-group": "attributes group",
    "item-labels-add": "add attribute",
    "item-vod-videos": "videos on demand",
    "item-vod-free-preview": "free preview",
    "item-vod-seconds": "seconds",
    "item-vod-or": "or",
    "item-vod-separate-video": "add separate video",
    "item-vod-remove": "remove",
    "item-vod-confirm": "confirm",
    "item-vod-processing": "processing",
    "item-vod-notified-via-email": "When processing is done, you will be notified via e-mail.",
    "system-settings": "System settings",
    "other-settings": "Other",
    "channel-online": "Online",
    "product-files": "Files",
    "add-a-new": "Add another:",
    "button-add-variant": "Add variant",
    "button-add-product": "Product",
    "button-add-option": "+ Add option",
    "button-add-attribute": "+ Add atrribute",
    "product-field-type": "Type",
    "product-field-name": "Name",
    "question-product-opt-delete": "Do you really want to delete this option?",
    "question-product-product-delete": "Do you really want to delete this product?",
    "question-product-product-variants-delete":
      "Do you really want to delete this product and its variants?",
    "product-enable-stock-track": "track stock",
    "product-min-order": "Minimal order amount:",
    "product-when-sold-out": "When sold out:",
    "product-on-sold-hide": "hide",
    "product-on-sold-disable": "restrict",
    "product-on-sold-preorder": "preorder",
    "product-show-as-preorder": "preorder",
    "product-show-as": "Visibility",
    "product-show-as-hide": "hidden",
    "product-show-as-unavailable": "unavailable",
    "product-show-as-available": "available",
    "product-show-as-auto": "by stock",
    "product-show-stock-counts": "Show stock counts",
    "product-pricing": "Pricing",
    "product-enable-reservation": "Allow reservation",
    "product-reservation-timeout": "Time to keep product in cart for order",
    "commerce-pricing-base": "Base",
    "commerce-pricing-tax": "Tax",
    "commerce-pricing-final": "Final",
    "commerce-pricing-purchased": "Purchased",
    "commerce-pricing-commission": "Commission",
    "commerce-pricing-profit": "Profit",
    "commerce-package-dimensions-length": "l",
    "commerce-package-dimensions-width": "w",
    "commerce-package-dimensions-height": "h",
    "shipping-weight": "Weight [kg]",
    "shipping-dimensions": "Dimensions [cm]",
    "question-product-file-attr-delete": "Do you really want to delete this attribute?",
    "question-product-file-delete": "Do you really want to delete this file?",

    "signup-email": "What is your email?",
    "signup-site-domain": "What will be your site domain?",
    "signup-done": "... check your email inbox.",

    "products-prepared": "Ready -> Sent",

    "button-cancel": "Cancel",
    "button-confirm": "Confirm",
    "button-remove": "Remove",
    "button-save-draft": "Save draft",
    "button-publish": "Publish",
    "button-publish-all": "Publish all",
    "button-save": "Save",
    "button-add": "Add",
    "button-add-file": "Add file",
    "button-change-file": "Change file",
    "button-add-font": "Add font",
    "button-delete": "Delete",
    "button-all": "All",
    "button-signup": "Signup",

    "order-incomming-new": "New order!",
    // "order-incomming-new" : "New order! [%0]",
    "orders-customer": "Customer",
    "orders-products": "Products",
    "orders-shipping": "Shipping",
    "order-payment-not-paid": "Not paid yet",
    "order-payment-paid": "Paid at:",
    "order-customer-note": "Customer note",
    "order-customer-notify": "Notify customer",
    "order-note-add": "Add note",
    "order-pay-products": "Products",
    "order-pay-delivery": "Shipping",
    "order-pay-payment": "Payment",
    "order-pay-discount": "Discount",
    "order-pay-total": "Customer pays",
    "order-pay-method-card-online": "Card online",
    "order-pay-method-card-site": "Card in a shop",
    "order-pay-method-card-deliv": "Card at the delivery",
    "order-pay-method-cash-site": "Cash in a shop",
    "order-pay-method-cash-deliv": "Cash at the delivery",
    "order-pay-method-bank-wire": "bank wire",
    "order-pay-method-paypal": "Paypal",
    "order-shipping-method-branch": "Branch pickup",
    "order-shipping-method-branch-pob1-onspot": "Branch pickup",
    "order-shipping-method-messanger": "Kurýr",
    "order-shipping-method-ppl": "PPL",
    "order-shipping-method-ups": "UPS",
    "order-shipping-method-zasilkovna": "Zásilkovna",
    "order-shipping-method-cpost": "Czech post",
    "order-shipping-method-cpost-ob": "Czech post - business package",
    "order-shipping-method-custom": "Custom",
    "order-shipping-method-popup": "Pop-up",
    "order-shipping-method-popup-praktika1": "Pop-up Praktika Letná",
    "order-shipping-method-no-delivery": "No delivery",

    oa_state_unpaid: "Unpaid",
    oa_state_paid: "Paid",
    oa_state_preparing: "Preparing products",
    oa_state_printing: "Printing labels",
    oa_state_packing: "Packing",
    oa_state_dispatching: "Dispatching",
    oa_state_sent: "Sent",
    oa_state_pickup: "Forpickup",
    oa_state_refunding: "Refunding",
    oa_state_cancelled: "Cancelled",
    oa_state_archived: "Archived",
    oa_go_paid: "Was paid",
    oa_go_prepare: "Prepare products",
    oa_go_print: "Print Label",
    oa_go_pack: "Pack goods",
    oa_go_cancel: "Cancel",
    oa_go_dispatch: "Dispatch",
    oa_go_send: "Send",
    oa_go_pickup: "Ready to pickup",
    oa_go_refund: "Refund",
    oa_go_archive: "Archive",
    oa_go_open: "Open",

    "user-action-user-looked": "Order opened",
    "user-action-moving-state": "State changed",
    "user-action-info-note-checked": "Customer note checked",
    "user-action-info-note-unchecked": "Customer note unchecked",
    "user-action-courier-note-checked": "Courier note checked",
    "user-action-courier-note-unchecked": "Courier note unchecked",
    "user-action-recipient-note-checked": "Recipient note checked",
    "user-action-recipient-note-unchecked": "Recipient note unchecked",
    "user-action-contactless-checked": "Contactless delivery checked",
    "user-action-contactless-unchecked": "Contactless delivery unchecked",
    "user-action-item-checked": "Item checked",
    "user-action-item-unchecked": "Item unchecked",
    "user-action-updated-private-note": "Private note updated",
    "user-action-deleted-private-note": "Private note deleted",
    "user-action-invoice-downloaded": "Invoice downloaded",
    "user-action-invoice-sent": "Invoice manually sent to customer",
    "user-action-refundSlip-downloaded": "Refund slip downloaded",
    "cart-created": "Cart created",
    "cart-item-added": "Item added",
    "cart-item-updated": "Item updated",
    "cart-item-removed": "Item removed",
    "order-created": "Order created",
    "stripe-payment-created": "Payment started",
    "stripe-payment-requires-action": "Payment requires action",
    "stripe-payment-succeeded": "Payment succeeded",
    "stripe-payment-failed": "Payment failed",
    "sendgrid-processed": "E-mail processed",
    "sendgrid-deferred": "E-mail deferred",
    "sendgrid-delivered": "E-mail delivered",
    "sendgrid-open": "E-mail opened",
    "sendgrid-click": "Link in e-mail opened",
    "sendgrid-bounce": "E-mail bounced",
    "sendgrid-dropped": "E-mail dropped",
    "sendgrid-blocked": "E-mail blocked",
    "sendgrid-spamreport": "E-mail marked as spam",
    "sendNotification-sent": "E-mail sent",
    "sendNotification-error": "E-mail not sent",
    "digital-product-download": "digital product download",

    "stripe-error-card-expired": "Card expired. Please use another card.",
    "stripe-decline-card-expired": "Card expired. Please use another card.",
    "stripe-decline-auth-required": "Payment needs authorization. Please try again.",
    "stripe-decline-try-again": "Please try again or contact your bank.",
    "stripe-decline-contact-bank": "Please contact your bank.",
    "stripe-decline-duplicate": "Duplicate payment.",
    "stripe-decline-incorrect-number": "Incorrect card number",
    "stripe-decline-incorrect-cvc": "Incorrect CVC",
    "stripe-decline-incorrect-expiry": "Incorrect expiry",
    "stripe-decline-insufficient-funds": "Please check your account balance.",
    "stripe-decline-test-card": "Do not use test card.",
    "stripe-decline-test-mode-live-card": "Live card used in test mode",

    "stripe-requires-action-3ds2": "3D secure verification",

    "billing-address": "Billing address",
    "delivery-address": "Delivery details",

    "block-config-name": "Block name",
    "block-config-tag": "Tag",
    "block-config-shared": "Shared",
    "block-config-region": "Region",
    "block-config-viewports": "Viewports",

    "forms-field-id": "ID",
    "forms-field-type": "Field type",
    "target-window-same": "Same window",
    "target-window-new": "New window",

    "form-font-name": "Font name",
    "form-font-weight": "Font weight",
    "form-font-url": "Font URL",
    "form-font-source-file": "Font file",

    "page-create-new": "New page",
    "page-create-new-abort": "Abort adding new page",
    "page-create-new-info": "Now select parental page of the new one",
    "page-new-name": "New page",
    "page-new-slug": "newpage",
    "page-new-created": "New page created",
    "page-new-not-created": "The page was not created.",
    "shared-blocks-label": "Shared",
    "private-blocks-label": "Private",

    "settings-css-header": "Styles",
    "settings-scripts-header": "Scripts",
    "settings-config-header": "Styles config",
    "settings-fonts-header": "Fonts",

    "template-editor": "Templates",
    "theme-label-fonts": "Fonts",
    "theme-label-scripts": "Script files",

    "field-listing-source": "Source",
    "field-listing-root-page": "Root page",

    "question-publish-all": "Do you relly want to publish all?",
    "message-info-site-published": "Site has been published.",
    "error-login-nonce-request":
      "Login failed. Please, repeat the process one more time, and in case it didn't work either, please do not hesitate to contact us.",
    "error-email-invlaid": "E-mail address is not valid!",
    "error-email-not-found": "E-mail address was not found!",
    "error-sitemName-already-used": "This site name is already used!",
    "error-email-already-used": "This E-mail address is already used!",
    "error-both-already-used": "Both values are already used!",
    "error-invlaid-values": "The form is not filled properly!",

    // Profile
    "profile-change-password": "Change password",
    "profile-passkeys": "Passkeys",
    "profile-notifications": "Notifikace",
    "profile-admin-lang": "Admin language",

    // View titles
    "view-title-sites": "sites",
    "view-title-site-settings": "site settings",
    "view-title-site-settings-sections": "sections",
    "view-title-site-settings-domains": "domains",
    "view-title-pages": "pages",
    "view-title-page-settings": "page settings",
    "view-title-booking": "booking",
    "view-title-commerce": "commerce",
    "view-title-commerce-carts": "carts",
    "view-title-commerce-orders": "orders",
    "view-title-commerce-inventory": "inventory",
    "view-title-commerce-inventory-product": "product",
    "view-title-commerce-inventory-products": "products",
    "view-title-commerce-settings": "commerce settings",
    "view-title-commerce-order-flow": "order flow",
    "view-title-commerce-promocodes": "promo codes",
    "view-title-commerce-giftcards": "gift cards",
    "view-title-commerce-giftcard-detail": "gift card",
    "view-title-commerce-giftcard-validation": "gift card validation",
    "view-title-commerce-delivery-planning": "delivery planning",
    "view-title-commerce-fee-reports": "fee reports",
    "view-title-commerce-fee-billing": "billing",
    "view-title-profile": "my profile",
    "view-title-profile-changepassword": "password change",
    "view-title-profile-passkeys": "Passkeys",
    "view-title-profile-notifications": "Notifikace",
    "view-title-commerce-promocode-detail": "code detail",
    "view-title-commerce-promocode-import": "code import",
    "view-title-commerce-settings-general": "general",
    "view-title-commerce-settings-invoicing": "invoicing",
    "view-title-commerce-settings-payment": "payment",
    "view-title-commerce-settings-delivery": "delivery",
    "view-title-commerce-settings-delivery-method": "method",
    "view-title-commerce-settings-cart": "cart",
    "view-title-commerce-export": "export",
    "view-title-platform": "platform",
    "view-title-platform-wizard": "wizard",
    "view-title-platform-clients": "clients",
    "view-title-platform-clients-detail": "client",
    "view-title-platform-sites": "sites",
    "view-title-platform-sites-detail": "site",
    "view-title-platform-users": "users",
    "view-title-platform-users-detail": "user",
    "view-title-platform-shops": "shops",
    "view-title-platform-shops-detail": "shop",
    "view-title-platform-events": "events",
    "view-title-platform-events-detail": "event",
    "view-title-platform-system": "system",

    "delivery-method-constraints-label": "Delivery constraints",
    "delivery-method-constraints-min-dispatch-time": "Dispatched after",
    "delivery-method-constraints-min-dispatch-time-since-order": "since order",
    "delivery-method-constraints-shipping-from": "Dispatched from",
    "delivery-method-constraints-shipping-to": "Dispatched till",
  },
}
