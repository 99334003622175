export default {
  text: {
    "version-check-new-version-available": "nová verze je dostupná",
    "version-check-reload": "aktualizovat",

    main_title: "Administrace",

    "menu-cms": "cms",
    "menu-pages": "Stránky",
    "menu-posts": "Příspěvky",
    "menu-commerce": "Obchod",
    "menu-analytics": "Analytics",
    "menu-settings": "Nastavení",
    "menu-help": "Nápověda",
    "menu-logout": "Odhlásit",
    "menu-publisher": "Vydavatel",
    "menu-designer": "Designer",

    "sites-main-headline": "Moje stránky",

    "option-yes": "Ano",
    "option-no": "Ne",

    monthNames: [
      "Leden",
      "Únor",
      "Březen",
      "Duben",
      "Květen",
      "Červen",
      "Červenec",
      "Srpen",
      "Září",
      "Říjen",
      "Listopad",
      "Prosinec",
    ],
    dayNames: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
    dayNamesShort: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],

    "login-header": "Přihlášení",
    "login-header-label": "Přihlásit se zde...",
    "login-user-name": "Přihlašovací jméno",
    "login-user-password": "Heslo",
    "login-user-email": "Přihlašovací email",
    "login-submit-button": "Přihlásit se",
    "login-help": "Pomoc",
    "login-use-pwd": "použít heslo",
    "login-use-pwdless": "použít odkaz do e-mailu",
    "login-use-passkey": "použít Klíč",
    "login-msg-welcome": "platforma.one",
    "login-error-wrong-password": "Špatné heslo",
    "login-error-too-many-requests": "Příliš mnoho pokusů, zkuste později.",
    "login-validator-passwordlessemail-errormessage-notvalid": "Skoro. Není tam nějaký přelkep?",
    "login-validator-passwordlessemail-errormessage-notfound": "Nenašli jsme tě. Existuješ?",
    "login-validator-passwordlessemail-errormessage-empty": "Sem by to chtělo napsat e-mail",
    "login-validator-passwordemail-errormessage-bad": "Tohle",
    "login-validator-passwordemail-errormessage-empty": "Notak, buď trochu přesnější.",
    "login-validator-passwordemail-errormessage-notvalid": "Takhle nevypadá e-mail!",
    "login-validator-password-errormessage-bad": "nebo toto není správně.",
    "login-validator-password-errormessage-empty": "No tak, hesla jsou důležitá!",
    "login-email-instructions-headline": "Mrkni do e-mailu",
    "login-email-sent-instructions-pt1": "Na e-mail",
    "login-email-sent-instructions-pt2": "letí přihlašovací tlačítko.",
    "login-email-sent-instructions-pt3": "Tohle okno můžeš zavřít.",
    "login-email-instructions-tryagain": "Zkusit znovu",

    "guest-signup-email-instructions-headline": "Hurá! Už jen jeden krok k tvému webu",
    "guest-signup-email-sent-instructions-pt1":
      "Potvrzovací odkaz ke zveřejnění tvé stránky jsme poslali na",
    "guest-signup-email-sent-instructions-pt2":
      "Toto okno můžeš zavřít. V mailu klikni na odkaz, který otevře nové.",
    "guest-signup-published-headline": "A je to!",
    "guest-signup-published-text": "Tvá stránka běží na",
    "guest-signup-published-open-btn": "Podívej se na ni",

    "main-site-menu": "Hlavní nabídka",

    "settings-header": "Nastavení",
    "site-config-title": "Název webu",

    "pages-header": "Stránky",
    "pages-add-subpage": "Přidat podstránku",
    "pages-stop-adding-subpage": "Ukončit přidávání podstránky",
    "page-qr-code-generator-page-lang": "Jazyk",
    "page-list": "Seznam stránek",
    "main-view-site": "Ukázat náhled",
    "page-selected-layout": "Rozložení",
    "page-selected-content": "Obsah",
    "page-selected-settings": "Nastavení",
    "page-selected-styles": "Vzhled",
    "page-setup-title": "Název",
    "page-setup-role": "Pořebná role",
    "page-setup-description": "Popis",
    "page-setup-slugs": "URL",
    "page-setup-visible": "Viditelné",
    "page-button-delete": "Smazat stránku",
    "page-add-hint": "Vyberte bod vložení",
    "page-duplicate": "Duplikovat",
    "page-duplicate-parent": "Nadřazená stránka",
    "page-duplicate-new-title": "Název nové stránky",
    "page-duplicate-execute": "Staniž se",
    "page-clone-fill_name": "Doplňte název stránky pro jazyk: ",
    "page-clone-parent_no_slug": "Nadřazená stránkanemá vyplněný slug",
    "page-clone-slag_for_lang": "Nadřazená stránkanemá vyplněný slug pro jazyk: ",
    "page-clone-done": "Duplikace se zdařila.",
    "page-clone-error": "Duplikace selhala!",
    "page-block-styling": "Stylování",
    "label-add-language-version": "Přidat verzi pro jazyk",

    "generic-download": "Stáhnout",
    "commerce-generate-qr-code": "Generovat QR kód",
    "commerce-remove-variant": "Odstranit variantu",
    "commerce-qr-code-generator-auto-add-to-cart": "Automaticky přidat do košíku",
    "commerce-qr-code-generator-delivery-method": "Doručovací metoda",
    "commerce-qr-code-generator-hide-other-delivery-methods": "Schovat ostatní doručovací metody",
    "commerce-order-name-anonymous": "bez jména",
    "commerce-order-item-giftcard-resendToCustomer": "poslat zákazníkovi",
    "commerce-order-item-giftcard-resendToRecipient": "poslat obdarovanému",
    "commerce-order-item-giftcard-download": "stáhnout",
    "commerce-header": "E-shop",
    "commerce-shop-name": "Název obchodu",
    "commerce-no-site": "Nejprve vyberte web",
    "commerce-new-eshop": "Založit nový e-shop",
    "commerce-carts": "Košíky",
    "commerce-orders": "Objednávky",
    "commerce-inventory": "Produkty",
    "commerce-shop-items": "Položky e-shopu",
    "commerce-customers": "Zákazníci",
    "commerce-subscriptions": "Subscriptions",
    "commerce-orders-setup": "Nastavení",
    "commerce-export": "Export",
    "commerce-orders-flow-setup": "Nastavení stavů objednávek",
    "commerce-shop-create-model": "Vytvořit novou specifikaci formuláře produktu",
    "commerce-shop-update-model": "Upravit specifikaci formuláře produktu",
    "commerce-products-header": "Produkty",
    "commerce-product-attr-name": "Název pole",
    "commerce-product-model-name": "Název typu produktu",
    "commerce-shop-create-product": "Vytvoření nového produktu",
    "commerce-shop-update-product": "Změna detailů produktu",
    "commerce-product-header": "Produkt",
    "commerce-fee-reports": "Poplatky",
    "commerce-fee-billing": "Vyúčtování",
    "product-created-info": "Produkt by vytvořen.",
    "product-not-created-error": "Problém zabránil vytvoření produktu!",
    "product-saved-info": "Produkt byl upraven.",
    "product-not-saved-error": "Problém zabránil upravení produktu!",
    "product-question-delete": "Opravdu chcete odebrat produkt (včetně variant)?",
    "product-image-main": "Hlavní produktu",
    "product-image-variant": "Hlavní varianty",
    "product-fees": "Poplatky",
    "product-sku-already-exists": "SKU musí být unikátní",
    "upload-file-in-process": "Probíhá nahrávání",
    "commerce-setup": "Nastavení vlastností",
    "commerce-supported-curr": "Podporované měny",
    "commerce-default-curr": "Výchozí měna",
    "commerce-supported-langs": "Podporované jazyky",
    "commerce-default-lang": "Výchozí jazyk",
    "commerce-order-num-format": "Formát čísla objednávky",
    "commerce-order-num-format-hint": "...",
    "commerce-sell-units": "Prodejní jednotky",
    "commerce-sell-units-hint": "...",
    "commerce-bank-connection": "Bankovní spojení",
    "commerce-bank-name": "Název banky",
    "commerce-bank-code": "Číslo banky",
    "commerce-account-number": "Číslo účtu",
    "commerce-iban": "IBAN",
    "commerce-swift": "SWIFT",
    "commerce-company-address": "Adresa společnosti",
    "commerce-setup-payments": "Nasvatení plateb",
    "commerce-payment-methods": "Platební metody",
    "commerce-payment-method-add": "Přidat další metodu platby",
    "commerce-tax-values": "Povolené sazby daně",
    "commerce-delivery-planning": "Doručovací sloty",
    "commerce-delivery-planning-bulk-edit-enable": "Hromadné úpravy",
    "commerce-delivery-planning-bulk-edit-disable": "Ukončit hromadné úpravy",
    "commerce-delivery-planning-delete-all": "Smazat vše",
    "commerce-delivery-channels": "Podporované doručovací metody",
    "commerce-setup-deliveries": "Nastavení doručování",
    "commerce-delivery-method-add": "Přidat další metodu doručení",
    "commerce-setup-delivery-planning-settings": "Nastavení doručovacího plánu",
    "commerce-setup-delivery-planning": "Doručovací plán",
    "commerce-delivery-next-day-order": "Last next-day order at:",
    "commerce-delivery-next-days": "Zobrazit nasledující dny:",
    "commerce-delivery-use-defaults": "Použít šablonu",
    "commerce-delivery-add-slot": "+ Přidat slot",
    "commerce-delivery-additional-fields": "Dodatečná pole na formuláři košíku",
    "commerce-delivery-add-field": "+ Přidat pole",
    "commerce-delivery-setup": "Plán doručování",
    "commerce-delivery-default-slots": "Šablona slotů",
    "commerce-setup-delivery-planning-slots": "Aktivní sloty",
    "commerce-delivery-settings": "Nastavení",
    "commerce-delivery-point-title": "Doručovací místo",
    "commerce-flow-cmd-set": "Nastavit hodnotu",
    "commerce-flow-cmd-exe": "Provést operaci",
    "commerce-flow-operation-request-liftago": "Objednat Liftago",
    "commerce-flow-operation-zasilkovna-upload-to-zasilkovna": "Nahrát do Zásilkovny",
    "commerce-flow-operation-get-shipping-labels": "Vytisknout štítky",
    "commerce-flow-operation-send-order-state-change-notification": "E-mail o změně stavu obj.",
    "commerce-flow-check-equal": "Rovno",
    "commerce-flow-check-not-equal": "Nerovno",
    "commerce-flow-check-contains": "Obsahuje",
    "commerce-flow-check-not-contains": "Neobsahuje",

    "commerce-order-tags-paid": "zaplacená",
    "commerce-order-tags-unpaid": "nezaplacená",
    "commerce-order-tags-contactless": "bezkontaktní",

    "commerce-price-brutto": "s DPH",
    "commerce-price-netto": "bez DPH",
    "commerce-price-units": "jednotek",
    "commerce-price-enter-without-vat": "zadat ceny bez DPH",
    "commerce-price-enter-with-vat": "zadat ceny s DPH",
    "commerce-price-currency": "měna",
    "commerce-price-vat": "DPH",
    "commerce-price-initial": "výchozí",

    "commerce-promo-codes": "slevové kódy",
    "commerce-gift-cards": "dárkové karty",
    "commerce-promocode-label": "Kód",
    "commerce-promocode-codeslist": "Seznam kódů",
    "commerce-promocode-count": "Počet použití",
    "commerce-promocode-unit": "Jednotka",
    "commerce-promocode-value": "Hodnota",
    "commerce-promocode-from": "Platnost od",
    "commerce-promocode-to": "Platnost do",
    "commerce-promocode-tags": "Štítky",
    "commerce-promocode-note": "Poznámka",
    "commerce-promocode-kind": "Typ",

    // commerce gift cards
    "commerce-giftcards-table-code": "kód",
    "commerce-giftcards-table-status": "stav",
    "commerce-giftcards-table-value": "hodnota",
    "commerce-giftcards-table-product": "produkt",
    "commerce-giftcards-table-validFrom": "platnost od",
    "commerce-giftcards-table-validTo": "platnost do",
    "commerce-giftcards-table-purchasedIn": "zakoupena",
    "commerce-giftcards-table-usedAt": "použita",
    "commerce-giftcards-table-usage": "použití",

    "commerce-giftcards-status-available": "aktivní",
    "commerce-giftcards-status-used": "vyčerpaná",
    "commerce-giftcards-status-usedPartially": "částečně vyčerpaná",
    "commerce-giftcards-status-expired": "propadlá",

    "commerce-giftcards-details-allowedUsage-atStore": "v obchodě",
    "commerce-giftcards-details-allowedUsage-eShop": "na e-shopu",
    "commerce-giftcards-details-allowedUsage-subtractable": "odečitatelná",

    "commerce-giftcards-details-valid": "platná",
    "commerce-giftcards-details-valueLeft": "zůstatek",
    "commerce-giftcards-details-details": "detail",
    "commerce-giftcards-details-allowedUsage": "povolené použití",
    "commerce-giftcards-details-validity": "platnost",
    "commerce-giftcards-details-history": "historie",
    "commerce-giftcards-details-order": "objednávka",
    "commerce-giftcards-details-validate": "validovat",

    "commerce-giftcards-details-validate-amount": "částka",
    "commerce-giftcards-details-validate-privateNote": "privátní poznámka",
    "commerce-giftcards-details-validate-markUsed": "uplatnit",
    "commerce-giftcards-details-validate-markUsedConfirm": "potvrdit",

    // commerce setup
    // invoicing
    "commerce-setup-company-title-invoicing": "Fakturace",
    "commerce-setup-company-label-orderNumberFormat": "Formát čísla faktury",

    // invoice info
    "commerce-setup-company-title-company": "Údaje na fakturu",
    "commerce-setup-company-label-name": "Firma",
    "commerce-setup-company-label-ico": "IČO",
    "commerce-setup-company-label-dic": "DIČ",
    "commerce-setup-company-label-legalNote": "Poznámka",

    // address
    "commerce-setup-company-label-street": "Ulice",
    "commerce-setup-company-label-streetNumber": "č.p.",
    "commerce-setup-company-label-city": "Město",
    "commerce-setup-company-label-postal": "PSČ",
    "commerce-setup-company-label-country": "Země",

    // banking
    "commerce-setup-company-label-bankName": "Název banky",
    "commerce-setup-company-label-bankCode": "Kód banky",
    "commerce-setup-company-label-accountNumber": "Číslo účtu",
    "commerce-setup-company-label-iban": "IBAN",
    "commerce-setup-company-label-swift": "SWIFT",

    // contact
    "commerce-setup-company-label-phone": "Telefon",
    "commerce-setup-company-label-email": "E-mail",

    "commerce-settings-general-shop-name": "Název obchodu",
    "commerce-settings-payment-taxes": "DPH [%]",

    "commerce-orders-hide-column": "Schovat sloupec",
    "commerce-orders-select-multiple": "Vybrat objednávky",
    "commerce-orders-cancel-selection": "Zrušit výběr",
    "commerce-orders-of-selected": "vybrané",
    "commerce-orders-of-all": "všechny",
    // "commerce-orders" : "",
    // "commerce-orders" : "",

    "commerce-courier-liftago-courierPosition": "Lokace kurýra",
    "commerce-courier-liftago-rideStatus-PROCESSING": "Hledání řidiče",
    "commerce-courier-liftago-rideStatus-ACCEPTED": "Přijato řidičem",
    "commerce-courier-liftago-rideStatus-WAITING": "Řidič čeká na převzetí",
    "commerce-courier-liftago-rideStatus-IN_PROGRESS": "Naloženo na rozvoz",
    "commerce-courier-liftago-rideStatus-COMPLETED": "Doručeno",
    "commerce-courier-liftago-rideStatus-CANCELLED": "Zrušeno",
    "commerce-courier-liftago-rideStatus-REJECTED": "Žádný řidič k dispozici",
    "commerce-courier-liftago-stopState-IN_QUEUE": "Doručuje se",
    "commerce-courier-liftago-stopState-DELIVERED": "Doručeno",
    "commerce-courier-liftago-stopState-UNDELIVERED": "Nedoručeno",
    "commerce-courier-liftago-handoverStatus-NEW": "Zpracováno",
    "commerce-courier-liftago-handoverStatus-SCHEDULED": "Naplánováno",
    "commerce-courier-liftago-handoverStatus-IN_RIDE": "Čeká na vyzvednutí",
    "commerce-courier-liftago-handoverStatus-ON_BOARD": "Na cestě",
    "commerce-courier-liftago-handoverStatus-DELIVERING": "Doručuje se",
    "commerce-courier-liftago-handoverStatus-DELIVERED": "Doručeno",
    "commerce-courier-liftago-handoverStatus-UNDELIVERED": "Nedoručeno",
    "commerce-courier-liftago-handoverStatus-RETURNING": "Vrací se",
    "commerce-courier-liftago-handoverStatus-RETURNED": "Vráceno",
    "commerce-courier-liftago-handoverStatus-CANCELED": "Zrušeno",
    "commerce-courier-liftago-handoverStatus-CANCELED_IN_RIDE": "Zrušeno při vyzvednutí",
    "commerce-courier-liftago-handoverStatus-CANCELED_WITH_POB": "Zrušeno po naložení",
    "commerce-courier-liftago-handoverStatus-CANCELED_BY_DRIVER": "Zrušeno řidičem",
    "commerce-courier-liftago-handoverStatus-WONT_DELIVER": "Nedoručitelné",

    "address-street": "Ulice",
    "address-number": "Číslo popisné",
    "address-area": "Oblast",
    "address-city": "Město",
    "address-zip": "PSČ",
    "address-region": "Region",
    "address-country": "Země",
    "commerce-invoice-disclaimer": "Informativní text faktury",

    // cart labels for cart overrides form - if you change this, change the client translation file
    "cart-main-title": "Košík",
    "cart-header-backToShop": "Zpět do obchodu",
    "cart-itemList-empty": "Nic tu není. 😔 Něco si vyber 🙃",
    "cart-promoCode-title": "Slevový kód",
    "cart-promoCode-insert": "Použít slevový kód",
    "cart-promoCode-field-promoCode-label": "Máte kód slevového kódu?",
    "cart-promoCode-field-promoCode-placeholder":
      "Kouzelné heslo, které způsobí, že i při utrácení ušetříte. 😜",
    "cart-promoCode-field-promoCode-helpText": "ABRAKADABRA",
    "cart-delivery-title": "Doručení",
    "cart-delivery-branch-title": "Pobočka",
    "cart-delivery-undeliverableItemsCombination":
      "Kombinaci produktů nelze doručit. Objednej, prosím, zvlášť.",
    "cart-delivery-deliveryOptions": "Možnosti doručení",
    "cart-customerInfo-title": "Moje údaje",
    "cart-customerInfo-actionButtons-addBillingInfo": "Zadat fakturační údaje",
    "cart-customerInfo-fields-customerEmail-label": "E-mail",
    "cart-customerInfo-fields-customerEmail-placeholder": "petr@novak.cz",
    "cart-customerInfo-fields-customerEmail-helpText":
      "Tvůj e-mail potřebujeme, abychom Tě mohli informovat o stavu objednávky",
    "cart-customerInfo-fields-customerName-label": "Jméno a příjmení",
    "cart-customerInfo-fields-customerName-placeholder": "Petr Novák",
    "cart-customerInfo-fields-customerName-helpText": "",
    "cart-customerInfo-fields-customerPhone-label": "Telefon",
    "cart-customerInfo-fields-customerPhone-placeholder": "123 456 789",
    "cart-customerInfo-fields-customerPhone-helpText":
      "Tvůj telefon potřebujeme, abychom Tě mohli rychle kontaktovat, pokud se objeví nečkaná situace.",
    "cart-billingInfo-title": "Fakturace",
    "cart-billingInfo-actionButtons-fillManually": "Vyplnit ručně",
    "cart-billingInfo-actionButtons-companyAddress": "Na firmu",
    "cart-billingInfo-fields-billingAddress-label": "Adresa",
    "cart-billingInfo-fields-billingAddress-placeholder": "Hlavní 1, Praha",
    "cart-billingInfo-fields-billingAddress-helpText": "",
    "cart-billingInfo-fields-billingAddressStreet-label": "Ulice",
    "cart-billingInfo-fields-billingAddressStreet-placeholder": "Hlavní 1, Praha",
    "cart-billingInfo-fields-billingAddressStreet-helpText": "",
    "cart-billingInfo-fields-billingAddressCity-label": "Město",
    "cart-billingInfo-fields-billingAddressCity-placeholder": "Praha",
    "cart-billingInfo-fields-billingAddressCity-helpText": "",
    "cart-billingInfo-fields-billingAddressPostal-label": "PSČ",
    "cart-billingInfo-fields-billingAddressPostal-placeholder": "110 00",
    "cart-billingInfo-fields-billingAddressPostal-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyICO-label": "IČO",
    "cart-billingInfo-fields-billingAddressCompanyICO-placeholder": "123 45 678",
    "cart-billingInfo-fields-billingAddressCompanyICO-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyName-label": "Firma",
    "cart-billingInfo-fields-billingAddressCompanyName-placeholder": "Firma s.r.o.",
    "cart-billingInfo-fields-billingAddressCompanyName-helpText": "",
    "cart-billingInfo-fields-invoiceNote-label": "Poznámka na fakturu",
    "cart-billingInfo-fields-invoiceNote-placeholder": "Dárek pro šéfovou",
    "cart-billingInfo-fields-invoiceNote-helpText": "",
    "cart-deliveryInfo-title": "Doručení",
    "cart-deliveryInfo-actionButtons-fillManually": "Vyplnit ručně",
    "cart-deliveryInfo-actionButtons-companyAddress": "Doručit na firmu",
    "cart-deliveryInfo-actionButtons-otherBillingInfo": "Jiné fakturační údaje",
    "cart-deliveryInfo-actionButtons-addBillingInfo": "Přidat fakturační údaje",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-true": "pro mě",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-false": "jako dárek",
    "cart-deliveryInfo-fields-recipientName-label": "Jméno a příjmení",
    "cart-deliveryInfo-fields-recipientName-placeholder": "Petr Novák",
    "cart-deliveryInfo-fields-recipientName-helpText": "",
    "cart-deliveryInfo-fields-recipientPhone-label": "Telefon",
    "cart-deliveryInfo-fields-recipientPhone-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-recipientPhone-helpText":
      "Telefon příjemce potřebujeme, aby jej kurýr mohl kontaktovat.",
    "cart-deliveryInfo-fields-deliveryAddress-label": "Adresa",
    "cart-deliveryInfo-fields-deliveryAddress-placeholder": "Hlavní 1, Praha",
    "cart-deliveryInfo-fields-deliveryAddress-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressStreet-label": "Ulice",
    "cart-deliveryInfo-fields-deliveryAddressStreet-placeholder": "Hlavní 1, Praha",
    "cart-deliveryInfo-fields-deliveryAddressStreet-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCity-label": "Město",
    "cart-deliveryInfo-fields-deliveryAddressCity-placeholder": "Praha",
    "cart-deliveryInfo-fields-deliveryAddressCity-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressPostal-label": "PSČ",
    "cart-deliveryInfo-fields-deliveryAddressPostal-placeholder": "110 00",
    "cart-deliveryInfo-fields-deliveryAddressPostal-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-label": "IČO",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-placeholder": "123 45 678",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-label": "Firma",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-placeholder": "Firma s.r.o.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-helpText": "",
    "cart-deliveryInfo-fields-deliveryNote-label": "Poznámka pro kurýra",
    "cart-deliveryInfo-fields-deliveryNote-placeholder": "První patro",
    "cart-deliveryInfo-fields-deliveryNote-helpText": "",
    "cart-deliveryInfo-fields-recipientNote-label": "Vzkaz pro příjemce",
    "cart-deliveryInfo-fields-recipientNote-placeholder": "Užij si dárek!",
    "cart-deliveryInfo-fields-recipientNote-helpText": "",
    "cart-deliveryInfo-fields-recipientInQuarantine-labelTrue": "Bezkontaktní doručení",
    "cart-deliverySlots-noMoreDaysAvailable": "další dny zatím<br>nemáme 😔",
    "cart-deliverySlots-noMoreSlotsAvailable": "Bohužel nemáme<br>žádné sloty 😔",
    "cart-note-title": "Poznámka",
    "cart-note-insert": "Přidat poznámku",
    "cart-note-fields-note-label": "Ještě něco na srdci?",
    "cart-note-fields-note-placeholder": "Kámen!",
    "cart-note-fields-note-helpText":
      "Budeme se snažit splnit modré z nebe! Ale taky jsme jen lidi, žejo 😜",
    "cart-payment-title": "Platba",
    "cart-stripeWallet-otherMethod": "Jiný způsob",
    "cart-orderButton-terms-pre": "Platbou souhlasím s",
    "cart-orderButton-terms-link": "obchodními podmínkami",
    "cart-confirmation-orderNumber-heading": "objednávka",
    "cart-confirmation-email-heading": "potvrzení",
    "cart-confirmation-merchantNote": "Těšíme se na příště!",
    "cart-confirmation-continueShoppingBtn": "ještě něco? 😜",

    // confirmation texts - these are configured in delivery methods
    "cart-confirmation-method-recipientSameAsCustomer-title": "Děkujeme",
    "cart-confirmation-method-recipientSameAsCustomer-paragraph": "Tvá objednávka se už vyřizuje.",
    "cart-confirmation-method-recipientNotSameAsCustomer-title": "Děkujeme",
    "cart-confirmation-method-recipientNotSameAsCustomer-paragraph":
      "Tvá objednávka se už vyřizuje a brzy ji odešleme příjemci.",

    // labels for override form
    "override-section--postfix-label": "popisek",
    "override-section--postfix-placeholder": "zástupný text",
    "override-section--postfix-helpText": "nápověda",
    "override-section-cart-main": "hlavní",
    "override-section-cart-main-key-cart-main-title": "nadpis košíku",
    "override-section-cart-header": "hlavička",
    "override-section-cart-header-key-cart-header-backToShop": "tlačítko na zavření košíku",
    "override-section-cart-itemList": "položky",
    "override-section-cart-itemList-key-cart-itemList-empty": "prázdný košík",
    "override-section-cart-promoCode": "promo kód",
    "override-section-cart-promoCode-key-cart-promoCode-title": "nadpis promo kódu",
    "override-section-cart-promoCode-key-cart-promoCode-field-promoCode": "promo kód",
    "override-section-cart-delivery": "doručení",
    "override-section-cart-delivery-key-cart-delivery-title": "nadpis doručení",
    "override-section-cart-delivery-key-cart-delivery-branch-title": "nadpis pobočky",
    "override-section-cart-delivery-key-cart-delivery-undeliverableItemsCombination":
      "text nedoručitelné kombinace",
    "override-section-cart-delivery-key-cart-delivery-deliveryOptions": "nadpis možností doručení",
    "override-section-cart-customerInfo": "zákazník",
    "override-section-cart-customerInfo-key-cart-customerInfo-title": "nadpis zákazníka",
    "override-section-cart-customerInfo-key-cart-customerInfo-actionButtons-addBillingInfo":
      "tlačítko přidat fakturační údaje",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerEmail": "e-mail",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerName": "jméno",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerPhone": "telefon",
    "override-section-cart-billingInfo": "fakturační údaje",
    "override-section-cart-billingInfo-key-cart-billingInfo-title": "nadpis fakturačních údajů",
    "override-section-cart-billingInfo-key-cart-billingInfo-actionButtons-fillManually":
      "tlačítko vyplnit ručně",
    "override-section-cart-billingInfo-key-cart-billingInfo-actionButtons-companyAddress":
      "tlačítko firemní adresa",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddress": "adresa",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressStreet": "ulice",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCity": "město",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressPostal": "PSČ",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCompanyICO": "IČO",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCompanyName":
      "Firma",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-invoiceNote":
      "Poznámka na fakturu",
    "override-section-cart-deliveryInfo": "doručovací údaje",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-title": "nadpis doručovacích údajů",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-fillManually":
      "tlačítko vyplnit ručně",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-companyAddress":
      "tlačítko firemní adresa",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-otherBillingInfo":
      "tlačítko ostatní údaje",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-addBillingInfo":
      "tlačítko přidat fakturační údaje",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-recipientSameAsCustomerOptions-true":
      "příjemce stejný jako objenávající",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-recipientSameAsCustomerOptions-false":
      "příjemce jiný než objednávající",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientName": "jméno",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientPhone": "telefon",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddress": "adresa",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressStreet":
      "ulice",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCity": "město",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressPostal": "PSČ",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCompanyICO":
      "IČO",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCompanyName":
      "Firma",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryNote":
      "Poznámka k doručení",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientNote":
      "Poznámka pro příjemce",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientInQuarantine-labelTrue":
      "Příjemce v karanténě",
    "override-section-cart-deliverySlots": "doručovací sloty",
    "override-section-cart-deliverySlots-key-cart-deliverySlots-noMoreDaysAvailable":
      "nenaplánované dny",
    "override-section-cart-deliverySlots-key-cart-deliverySlots-noMoreSlotsAvailable":
      "nejsou volné sloty",
    "override-section-cart-note": "poznámka",
    "override-section-cart-note-key-cart-note-title": "nadpis poznámky",
    "override-section-cart-note-key-cart-note-fields-note": "poznámka",
    "override-section-cart-payment": "platba",
    "override-section-cart-payment-key-cart-payment-title": "nadpis platby",
    "override-section-cart-orderButton": "objednání",
    "override-section-cart-orderButton-key-cart-orderButton-terms-pre": "podmínky - začátek věty",
    "override-section-cart-orderButton-key-cart-orderButton-terms-link": "podmínky - odkaz",
    "override-section-cart-confirmation": "potvrzení",
    "override-section-cart-confirmation-key-cart-confirmation-orderNumber-heading":
      "nadpis číslo objednávky",
    "override-section-cart-confirmation-key-cart-confirmation-email-heading": "nadpis e-mail",
    "override-section-cart-confirmation-key-cart-confirmation-merchantNote": "vzkaz obchodníka",
    "override-section-cart-confirmation-key-cart-confirmation-continueShoppingBtn":
      "tlačítko pokračovat v nákupu",

    "product-field-title": "Název",
    "product-field-description": "Popis",
    "product-field-google-product-category": "Product Category ID",
    "product-tags": "Štítky",
    "product-sku": "SKU",
    "product-options": "Možnosti",
    "product-variants": "Varianta",
    "product-dynamic-sections": "Dynamické sekce",
    "product-customer-form": "Zákaznická pole",
    "product-kind": "Druh",
    "product-sub-kind": "kategorie",
    "product-images": "Fotografie",
    "product-gift-card-images": "Fotografie na dárkové karty",
    "product-gift-card-settings": "Nastavení dárkových karet",
    "product-booking-settings": "Nastavení rezervací",
    "product-quantity": "Sklad & viditelnost",
    "product-quantity-stock": "Sklad",
    "product-quantity-available": "dostupné",
    "product-quantity-blocked": "blokované",
    "product-quantity-reserved": "rezervované",
    "product-quantity-when-sold-out": "po vyprodání",
    "product-shipping": "Logistika",
    "product-shipping-dimensions": "Rozměry",
    "product-shipping-available-deliveries": "Dostupná doprava",
    "product-channels": "Prodejní cesty",
    "item-labels": "atributy",
    "item-labels-adding-name": "název",
    "item-labels-group": "skupina atributů",
    "item-labels-add": "přidat atribut",
    "item-vod-videos": "videa na vyžádání",
    "item-vod-free-preview": "náhled zdarma",
    "item-vod-seconds": "sekund",
    "item-vod-or": "nebo",
    "item-vod-separate-video": "přidat separátní video",
    "item-vod-remove": "odstranit",
    "item-vod-confirm": "potvrdit",
    "item-vod-processing": "probíhá konverze",
    "item-vod-notified-via-email":
      "Až bude video připraveno k prodeji, dostaneš notifikaci e-mailem.",
    "system-settings": "Systémová nastavení",
    "other-settings": "Ostatní",
    "channel-online": "Online",
    "product-files": "Soubory",
    "add-a-new": "Přidat nový:",
    "button-add-variant": "Přidat variantu",
    "button-add-product": "Produkt",
    "button-add-option": "+ Přidat možnost",
    "button-add-attribute": "+ Přidat atribut",
    "product-field-type": "Typ",
    "product-field-name": "Název",
    "question-product-opt-delete": "Opravdu si přejete smazat tuto možnost?",
    "question-product-product-delete": "Opravdu si přejete smazat tento produkt?",
    "question-product-product-variants-delete":
      "Opravdu si přejete smazat tento produkt a jeho varianty?",
    "product-enable-stock-track": "Sledování skladu",
    "product-min-order": "Minimální množství objednávky:",
    "product-when-sold-out": "Po vyprodání",
    "product-on-sold-hide": "skrýt",
    "product-on-sold-disable": "zakázat",
    "product-on-sold-preorder": "předprodej",
    "product-show-as-preorder": "předprodej",
    "product-show-as": "Viditelnost",
    "product-show-as-hide": "skrytý",
    "product-show-as-unavailable": "nedostupný",
    "product-show-as-available": "dostupný",
    "product-show-as-auto": "podle skladu",
    "product-show-stock-counts": "Zobrazovat počet na skladu",
    "product-pricing": "Cena",
    "product-enable-reservation": "Povolit reservování",
    "product-reservation-timeout": "Doba rezervování zboží k objednání",
    "commerce-pricing-base": "Základ",
    "commerce-pricing-tax": "Daň",
    "commerce-pricing-final": "Celkem",
    "commerce-pricing-purchased": "Pořizovací cena",
    "commerce-pricing-commission": "Provize",
    "commerce-pricing-profit": "Profit",
    "commerce-package-dimensions-length": "d",
    "commerce-package-dimensions-width": "š",
    "commerce-package-dimensions-height": "v",
    "shipping-weight": "Váha [kg]",
    "shipping-dimensions": "Rozměry [cm]",
    "question-product-file-attr-delete": "Opravdu si přejete smazat tento atribut?",
    "question-product-file-delete": "Opravdu si přejete smazat tento soubor?",
    "commerce-flow-delete-error": "Nelze smazat stav, protože je použitý v: [%0]",

    "signup-email": "Vaše emailová adresa",
    "signup-site-domain": "Jak nazveme vaši doménu?",
    "signup-done": "... zkontrolujte svoji e-mailovou schránku.",

    "products-prepared": "Připraveno -> Odeslat",

    "button-cancel": "Zrušit",
    "button-confirm": "Potvrdit",
    "button-remove": "Odebrat",
    "button-save-draft": "Uložit náčrt",
    "button-publish": "Publikovat",
    "button-publish-all": "Publikovat vše",
    "button-save": "Uložit",
    "button-add": "Přidat",
    "button-add-file": "Přidat soubor",
    "button-change-file": "Nahradit soubor",
    "button-add-font": "Přidat font",
    "button-delete": "Smazat",
    "button-all": "Vše",
    "button-signup": "Registrovat",

    // "order-incomming-new" : "Nová objednávka! [%0]",
    "order-incomming-new": "Nová objednávka!",
    "orders-customer": "Zákazník",
    "orders-products": "Obsah",
    "orders-shipping": "Doprava",
    "order-payment-not-paid": "Zatím neuhrazeno",
    "order-payment-paid": "Zaplaceno:",
    "order-customer-note": "Poznámka zákazníka",
    "order-customer-notify": "Informovat zákazníka",
    "order-note-add": "Přidat poznámku",
    "order-pay-products": "Produkty",
    "order-pay-delivery": "Doručení",
    "order-pay-payment": "Platba",
    "order-pay-discount": "Sleva",
    "order-pay-total": "Zákazník zaplatí",
    "order-pay-method-card-online": "Kartou online",
    "order-pay-method-card-site": "Kartou v obchodě",
    "order-pay-method-card-deliv": "Kartou při převzetí",
    "order-pay-method-cash-site": "V hotovosti v obchodě",
    "order-pay-method-cash-deliv": "V hotovosti při převzetí (dobírka)",
    "order-pay-method-bank-wire": "Bankovním převodem",
    "order-pay-method-paypal": "Paypal",
    "order-shipping-method-branch": "Vyzvednutí na pobočce",
    "order-shipping-method-branch-pob1-onspot": "Pobočka",
    "order-shipping-method-messanger": "Kurýr",
    "order-shipping-method-ppl": "PPL",
    "order-shipping-method-ups": "UPS",
    "order-shipping-method-zasilkovna": "Zásilkovna",
    "order-shipping-method-cpost": "Česká Pošta",
    "order-shipping-method-cpost-ob": "Česká Pošta - Obchodní balík",
    "order-shipping-method-custom": "Vlastní",
    "order-shipping-method-popup": "Pop-up",
    "order-shipping-method-popup-praktika1": "Pop-up Praktika Letná",
    "order-shipping-method-no-delivery": "Bez doručení",

    oa_state_unpaid: "Nezaplaceno",
    oa_state_paid: "Zaplaceno",
    oa_state_preparing: "Příprava produktu",
    oa_state_printing: "Tisk štítků",
    oa_state_packing: "Balení",
    oa_state_dispatching: "K Odeslání",
    oa_state_sent: "Odesláno",
    oa_state_pickup: "K vyzvednutí",
    oa_state_refunding: "Náhrady",
    oa_state_cancelled: "Zrušeno",
    oa_state_archived: "Archivováno",
    oa_go_paid: "Bylo zaplaceno",
    oa_go_prepare: "Připravit produkty",
    oa_go_print: "Vytisknout štítky",
    oa_go_pack: "Zabalit zboží",
    oa_go_cancel: "Zrušit",
    oa_go_dispatch: "Předat",
    oa_go_send: "Odeslat",
    oa_go_pickup: "Možné vyzvednout",
    oa_go_refund: "Vrátit platbu",
    oa_go_archive: "Archivovat",
    oa_go_open: "Otevřít",

    "user-action-user-looked": "Objednávka otevřena",
    "user-action-moving-state": "Změněn stav",
    "user-action-info-note-checked": "Označena poznámka zákazníka",
    "user-action-info-note-unchecked": "Odznačena poznámka zákazníka",
    "user-action-courier-note-checked": "Označena poznámka pro kurýra",
    "user-action-courier-note-unchecked": "Odznačena poznámka pro kurýra",
    "user-action-recipient-note-checked": "Označen vzkaz pro příjemce",
    "user-action-recipient-note-unchecked": "Odznačen vzkaz pro příjemce",
    "user-action-contactless-checked": "Označeno bezkontaktní doručení",
    "user-action-contactless-unchecked": "Odznačeno bezkontaktní doručení",
    "user-action-item-checked": "Označena položka",
    "user-action-item-unchecked": "Odznačena položka",
    "user-action-updated-private-note": "Upravena privátní poznámka",
    "user-action-deleted-private-note": "Smazána privátní poznámka",
    "user-action-invoice-downloaded": "Stažena faktura",
    "user-action-invoice-sent": "Faktura ručně odeslána zákazníkovi",
    "user-action-refundSlip-downloaded": "Stažen opravný doklad",
    "cart-created": "Vytvořen košík",
    "cart-item-added": "Přidán produkt",
    "cart-item-updated": "Upraven produkt",
    "cart-item-removed": "Odebrán produkt",
    "order-created": "Vytvořena objednávka",
    "stripe-payment-created": "Zahájená platba",
    "stripe-payment-requires-action": "Platba vyžaduje akci",
    "stripe-payment-succeeded": "Úspěšná platba",
    "stripe-payment-failed": "Neúspěšná platba",
    "sendgrid-processed": "E-mail zpracován",
    "sendgrid-deferred": "E-mail odložen",
    "sendgrid-delivered": "E-mail doručen",
    "sendgrid-open": "E-mail otevřen",
    "sendgrid-click": "Otevřen odkaz v e-mailu",
    "sendgrid-bounce": "E-mail vrácen",
    "sendgrid-dropped": "E-mail vrácen",
    "sendgrid-blocked": "E-mail blokován",
    "sendgrid-spamreport": "E-mail označen jako spam",
    "liftago-STOP_ON_BOARD": "Naloženo",
    "liftago-STOP_DELIVERED": "Doručeno",
    "liftago-STOP_UNDELIVERED": "Nedoručeno",
    "sendNotification-sent": "E-mail odeslán",
    "sendNotification-error": "E-mail neodeslán",
    "sendNotification-nonproduction":
      "Sendgrid webhooks are only processed in production environment",
    "dispatch-liftago-dispatched": "Jízda objednána",
    "dispatch-liftago-dispatch-error": "Chyba objednávky jízdy",
    "digital-product-download": "stažení digitálního produktu",

    "stripe-error-card-expired": "Karta expirovala. Použijte jinou kartu.",
    "stripe-decline-card-expired": "Karta expirovala. Použijte jinou kartu.",
    "stripe-decline-auth-required": "Platba vyžaduje autorizaci. Zkuste znovu.",
    "stripe-decline-try-again": "Zkuste znovu nebo kontaktujte Vaši banku.",
    "stripe-decline-contact-bank": "Kontaktujte Vaši banku.",
    "stripe-decline-duplicate": "Duplicitní platba",
    "stripe-decline-incorrect-number": "Chybné číslo karty",
    "stripe-decline-incorrect-cvc": "Chybné CVC",
    "stripe-decline-incorrect-expiry": "Chybná expirace",
    "stripe-decline-insufficient-funds": "Zkontrolujte stav svého účtu.",
    "stripe-decline-test-card": "Nepoužívejte testovací kartu.",
    "stripe-decline-test-mode-live-card": "Ostrá karta použita v testovacím módu",

    "stripe-requires-action-3ds2": "Ověření 3D secure",

    "billing-address": "Fakturační adresa",
    "delivery-address": "Doručovací údaje",
    "order-courier-deliver-details": "Doručení",
    "order-courier-recipient-details": "Příjemce",
    "order-courier-dispatch": "Odeslání",

    "order-courier-ordered": "Kurýr objednán",
    "order-courier-dispatched": "Odesláno",

    "block-config-name": "Název bloku",
    "block-config-shared": "Sdílený",
    "block-config-region": "Region",
    "block-config-viewports": "Viewports",
    "block-config-tag": "Tag",

    "forms-field-id": "ID",
    "forms-field-type": "Typ pole",
    "target-window-same": "Stejné okno",
    "target-window-new": "Nové okno",

    "form-font-name": "Název fontu",
    "form-font-weight": "Font weight (CSS)",
    "form-font-url": "URL fontu",
    "form-font-source-file": "Soubor fontu",

    "page-create-new": "Nová stránka",
    "page-create-new-abort": "Přerušit přidávání",
    "page-create-new-info": "Nyní vyberte strnku nadřazenou nové",
    "page-new-name": "Nová stránka",
    "page-new-slug": "novastranka",
    "page-new-created": "Nová stránka byla vytvořena",
    "page-new-not-created": "Novou stránku se nepodařilo vytvořit! Více informací v konzili.",

    "shared-blocks-label": "Sdílené",
    "private-blocks-label": "Privátní",

    "settings-css-header": "Styly",
    "settings-scripts-header": "Scripty",
    "settings-config-header": "Nasvení stylů",
    "settings-fonts-header": "Fonty",

    "template-editor": "Šablony",
    "theme-label-fonts": "Fonty",
    "theme-label-scripts": "Soubory skriptů",

    "field-listing-source": "Zdroj dat",
    "field-listing-root-page": "Kořenová stránka",

    "question-publish-all": "Do you relly want to publish all?",
    "message-info-site-published": "Stránka byla publikována",
    "error-login-nonce-request":
      "Přihlášení se nezdařilo. Opakujte proces ještě jednou, a pokud to nepomůže, tak nás neváhejte kontaktovat.",
    "error-email-invlaid": "E-mail adresa není platná!",
    "error-email-not-found": "E-mail adresa nebyla nalezena!",
    "error-sitemName-already-used": "Tento název stránky je již použitý!",
    "error-email-already-used": "Tato e-mailová adresa je již použita!",
    "error-both-already-used": "Obě hodnoty jsou již použité!",
    "error-invlaid-values": "Formulář není řádně vyplněn!",

    // Profile
    "profile-change-password": "Změnit heslo",
    "profile-passkeys": "Klíče",
    "profile-notifications": "Notifikace",
    "profile-admin-lang": "Jazyk administrace",

    // View titles
    "view-title-sites": "weby",
    "view-title-site-settings": "nastavení webu",
    "view-title-site-settings-sections": "sekce",
    "view-title-site-settings-domains": "domény",
    "view-title-pages": "stránky",
    "view-title-page-settings": "nastavení stránky",
    "view-title-booking": "rezervace",
    "view-title-commerce": "obchod",
    "view-title-commerce-carts": "košíky",
    "view-title-commerce-orders": "objednávky",
    "view-title-commerce-inventory": "produkty",
    "view-title-commerce-inventory-product": "produkt",
    "view-title-commerce-inventory-products": "produkty",
    "view-title-commerce-settings": "nastavení obchodu",
    "view-title-commerce-order-flow": "stavy objednávek",
    "view-title-commerce-promocodes": "promo kódy",
    "view-title-commerce-giftcards": "dárkové karty",
    "view-title-commerce-giftcard-detail": "dárková karta",
    "view-title-commerce-giftcard-validation": "validace dárkové karty",
    "view-title-commerce-delivery-planning": "doručovací sloty",
    "view-title-commerce-fee-reports": "poplatky",
    "view-title-commerce-fee-billing": "fakturace",
    "view-title-profile": "můj profil",
    "view-title-profile-changepassword": "změna hesla",
    "view-title-profile-passkeys": "Klíče",
    "view-title-profile-notifications": "Notifikace",
    "view-title-commerce-promocode-detail": "detail kódu",
    "view-title-commerce-promocode-import": "import kódů",
    "view-title-commerce-settings-general": "základní",
    "view-title-commerce-settings-invoicing": "faktury",
    "view-title-commerce-settings-payment": "platby",
    "view-title-commerce-settings-delivery": "doručení",
    "view-title-commerce-settings-delivery-method": "metoda",
    "view-title-commerce-settings-cart": "košík",
    "view-title-commerce-export": "export",
    "view-title-platform": "platforma",
    "view-title-platform-wizard": "průvodce",
    "view-title-platform-clients": "klienti",
    "view-title-platform-clients-detail": "klient",
    "view-title-platform-sites": "weby",
    "view-title-platform-sites-detail": "web",
    "view-title-platform-users": "uživatelé",
    "view-title-platform-users-detail": "uživatel",
    "view-title-platform-shops": "obchody",
    "view-title-platform-shops-detail": "obchod",
    "view-title-platform-events": "akce",
    "view-title-platform-events-detail": "akce",
    "view-title-platform-system": "systém",

    "delivery-method-constraints-label": "Možnosti doručení",
    "delivery-method-constraints-min-dispatch-time": "Odeslání nejdříve za",
    "delivery-method-constraints-min-dispatch-time-since-order": "od objednání",
    "delivery-method-constraints-shipping-from": "Odeslání od",
    "delivery-method-constraints-shipping-to": "Odeslání do",
  },
}
