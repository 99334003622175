import {router} from "@/router"
import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  doc,
  serverTimestamp,
  updateDoc,
  collection,
  setDoc,
  writeBatch,
  onSnapshot,
} from "firebase/firestore"
import {useEditorStore} from "./editor"
import {useUserStore} from "./user"
import {useSitesStore} from "./sites"
import {useSitePagesStore} from "./sitePages"
import {useSiteBlocksStore} from "./siteBlocks"
import {useSiteBlocksContentsStore} from "./siteBlocksContents"
import {useSiteBlocksCustomTemplatesStore} from "./siteBlocksCustomTemplates"
import {useFilesSiteFilesStore} from "./filesSiteFiles"
import {useFilesSiteImagesStore} from "./filesSiteImages"
import {useFilesSiteVideosStore} from "./filesSiteVideos"
import {useShopStore} from "./shop"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"
import {getPageRoute} from "@platformaone/common/app/stores/shared.js"
import {useRoute} from "vue-router"

export const useSiteStore = defineStore("site", () => {
  const route = useRoute()

  // stores
  const editorStore = useEditorStore()
  const sitePagesStore = useSitePagesStore()
  const siteBlocksStore = useSiteBlocksStore()
  const siteBlocksContentsStore = useSiteBlocksContentsStore()
  const siteBlocksCustomTemplatesStore = useSiteBlocksCustomTemplatesStore()
  const filesSiteFilesStore = useFilesSiteFilesStore()
  const filesSiteImagesStore = useFilesSiteImagesStore()
  const filesSiteVideosStore = useFilesSiteVideosStore()
  const userStore = useUserStore()
  const sitesStore = useSitesStore()
  const shopStore = useShopStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const site = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const siteLoaded = computed(() => status.value == "loaded")
  const siteId = computed(() => site.value.id)
  const siteShopId = computed(() => site.value.shopId)
  const sitePlatform = computed(() => site.value.platform)
  const siteHasShop = computed(() => {
    return typeof siteShopId.value !== "undefined" &&
      siteShopId.value &&
      siteShopId.value.length > 5
      ? true
      : false
  })
  const siteName = computed(() => site.value.name)
  const siteLangsAvailable = computed(() => site.value.config?.lang.available)
  const siteLangDefault = computed(() => site.value.config?.lang.default)
  const siteDomains = computed(() => site.value.domains)
  const siteRoutes = computed(() => {
    const sitePages = sitePagesStore.pages
    let map = {}
    // console.log('siteRoutes:', site.value, siteLangsAvailable)
    siteLangsAvailable.value?.forEach((lang) => {
      sitePages.forEach((p) => {
        map[getPageRoute(p.id, sitePages, lang)] = p.id
      })
    })

    return map
  })
  const siteRoutesReversed = computed(() => {
    const sitePages = sitePagesStore.pages
    let map = {}
    siteLangsAvailable.value?.forEach((lang) => {
      sitePages.forEach((p) => {
        if (!map[p.id]) map[p.id] = {}
        map[p.id][lang] = getPageRoute(p.id, sitePages, lang)
      })
    })

    return map
  })

  // actions
  async function bind(args) {
    const forceSiteId = args?.siteId

    // lookup if site in current route
    const routeSiteName = route.params?.siteName
    const usersAvailableSites = sitesStore.sites
    const routeSiteId = routeSiteName
      ? usersAvailableSites.find((s) => s.name === routeSiteName)?.id
      : null

    // console.log({routeSiteName, routeSiteId})

    // route site id has precedence over user's last site id stored in database
    const siteId = forceSiteId || routeSiteId || userStore.userSelectedSiteId

    // console.log("bind()", {
    //   args,
    //   forceSiteId,
    //   routeSiteName,
    //   routeSiteId,
    //   userSelectedSiteId: userStore.userSelectedSiteId,
    //   siteId,
    // })
    // console.log("site bind", {
    //   siteId,
    //   routeSiteId,
    //   userSelectedSiteId: userStore.userSelectedSiteId,
    // })
    const docRef = doc(firestore, `sites/${siteId}`)
    return await this.attach("site", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function update(args) {
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const siteId = this.site.id
    const siteRef = doc(firestore, `sites/${siteId}`)
    const data = {
      ...args.data,
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(siteRef, data)

    if (args.meta?.fieldId) formSaveStateStore.markSaved(args)
    return
  }

  async function publish() {
    await Promise.all(
      sitePagesStore.pages.map(async (page) => {
        console.log("publishing page:", page.id)
        await sitePagesStore.publishPage({pageId: page.id})
        console.log("published page:", page.id)
        return
      })
    )
    console.log("published site")
    return
  }

  // this is for sites without page editor (qasima) - they don't have pages but still need to publish index & siteVar
  async function publishSite(id) {
    editorStore.publishingState.page = "publishing-inprogress"

    // create job
    const jobsRef = collection(firestore, "jobs")
    const jobRef = doc(jobsRef)
    const jobDoc = {
      dateCreated: serverTimestamp(),
      meta: {
        siteId: id || siteId.value,
        pageId: null,
      },
      state: "created",
      type: "publish",
    }

    await setDoc(jobRef, jobDoc).catch((err) => {
      console.error(err)
    })

    // create tasks (that trigger render & publish)
    try {
      const data = {
        dateCreated: serverTimestamp(),
        state: "created",
      }
      const publishIndexesDocRef = doc(firestore, `jobs/${jobRef.id}/tasks/publish-indexes`)
      const publishSiteVarDocRef = doc(firestore, `jobs/${jobRef.id}/tasks/publish-siteVar`)
      await Promise.all([setDoc(publishIndexesDocRef, data), setDoc(publishSiteVarDocRef, data)])
    } catch (err) {
      console.error(err)
    }

    // listen to job for updates
    var unsubscribe = onSnapshot(jobRef, (doc) => {
      if (doc.data().state) {
        // console.log('job updated:', doc.data().state)
        switch (doc.data().state) {
          case "failed":
            editorStore.publishingState.page = "failed"
            setTimeout(() => {
              editorStore.publishingState.page = "waitingToBePublished"
            }, 5000)
            break

          case "created":
            editorStore.publishingState.page = "publishing-inprogress"

            break

          case "finished":
            editorStore.publishingState.page = "published"
            setTimeout(() => {
              editorStore.publishingState.page = "waitingToBePublished"
            }, 5000)
            unsubscribe()
            break
        }
      }
    })
  }

  async function onClick({site, e}) {
    console.log("onClick", site, e?.metaKey)
    let pinnedSites = userStore.userPinnedSites
    const siteId = site?.id
    const siteName = site?.site?.name

    // cmd/ctrl
    if (e?.metaKey) {
      console.log("onClick", site)

      let pinned = pinnedSites

      if (pinned.includes(site.id)) {
        pinned = pinned.filter((s) => s != site.id)
      } else {
        pinned.push(site.id)
      }

      let data = {
        data: {"config.pinned.sites": pinned},
      }
      await userStore.update(data)
    } else {
      await this.select({siteId})
      // console.log("current route", router.currentRoute.value)
      let currentRoute = router.currentRoute.value.name
      let newRoute

      // qasima site -> go to commerce
      if (["qasima.ae", "qasima.cz"].includes(site.platform)) {
        newRoute = currentRoute == "SitesView" ? "ShopMenuView" : currentRoute
      }
      // platforma.one site -> go to site main view (or stay)
      else {
        newRoute = currentRoute == "SitesView" ? "SiteMainView" : currentRoute
      }
      router.push({
        name: newRoute,
        params: {
          siteName,
        },
      })
    }
  }

  async function select(args) {
    const siteId = args.siteId

    console.log("select()", {siteId, userSelectedSiteId: userStore.userSelectedSiteId})
    if (siteId == userStore.userSelectedSiteId) return

    await userStore.updateSelectedSite({siteId})

    // unbind site and related stuff
    await this.unbind()
    await sitePagesStore.unbind()
    await siteBlocksStore.unbind()
    await siteBlocksContentsStore.unbind()
    await siteBlocksCustomTemplatesStore.unbind()
    await filesSiteFilesStore.unbind()
    await filesSiteImagesStore.unbind()
    await filesSiteVideosStore.unbind()

    // unbind old shop and bind new one
    if (siteHasShop.value) {
      console.log("site changed -> unbinding shop")
      await shopStore.unbind()

      console.log("shop unbound, current site:", this.site.id, "shop:", this.site.shopId)
      // shopStore.bindUserSelectedShop()
    }

    await this.bind({siteId})
    await sitePagesStore.bind()

    // unbind old shop and bind new one
    if (siteHasShop.value) {
      shopStore.bindUserSelectedShop()
    }

    console.log("all should now be rebound")
  }

  return {
    // state
    site,
    status,
    unsubscribe,

    // getters
    siteLoaded,
    siteId,
    siteShopId,
    sitePlatform,
    siteHasShop,
    siteName,
    siteLangsAvailable,
    siteLangDefault,
    siteDomains,
    siteRoutes,
    siteRoutesReversed,

    // actions
    bind,
    unbind,
    update,
    publish,
    publishSite,
    onClick,
    select,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSiteStore, import.meta.hot))
}
