import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, doc, serverTimestamp, setDoc, updateDoc, query, where} from "firebase/firestore"
import {useShopStore} from "./shop"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const useShopItemTagsStore = defineStore("shopItemTags", () => {
  // stores
  const shopStore = useShopStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const tags = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemTagsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/itemTags`)
    const q = query(collectionRef, where("meta.deleted", "==", false))
    return await this.attach("tags", q)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const shopId = shopStore.shopId
    const docRef = doc(collection(firestore, `shops/${shopId}/itemTags`))
    const data = {
      title: args.title,
      meta: {
        created: serverTimestamp(),
        updated: false,
        deleted: false,
      },
    }
    console.log('add()', data)
    await setDoc(docRef, data)
    return {id: docRef.id}
  }

  async function update(args) {
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const shopId = shopStore.shopId
    const productId = args.productId
    const product = args.data
    const docRef = doc(firestore, `shops/${shopId}/itemTags/${productId}`)
    const data = {
      config: product.config,
      desc: product.desc,
      sku: product.root.sku || "",
      price: product.root.price || [],
      tags: product.root.tags || [],
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    if (args.meta?.fieldId) formSaveStateStore.markSaved(args)
  }

  return {
    // state
    tags,
    status,
    unsubscribe,

    // getters
    shopItemTagsLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemTagsStore, import.meta.hot))
}
