import {storeToRefs} from "pinia"
import {functions, auth} from "@/firebase/index.js"
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signInWithEmailLink,
  setPersistence,
  updatePassword,
  fetchSignInMethodsForEmail,
  browserLocalPersistence,
  signOut,
} from "firebase/auth"
import {configureScope} from "@sentry/browser"
import {httpsCallable} from "firebase/functions"
import {router} from "../router"
import Langs from "../langs/index.js"
import {useFilesShopFilesStore} from "@/stores/filesShopFiles"
import {useFilesShopImagesStore} from "@/stores/filesShopImages"
import {useFilesShopVideosStore} from "@/stores/filesShopVideos"
import {useFilesSiteFilesStore} from "@/stores/filesSiteFiles"
import {useFilesSiteImagesStore} from "@/stores/filesSiteImages"
import {useFilesSiteVideosStore} from "@/stores/filesSiteVideos"
import {usePlatformClientsStore} from "@/stores/platformClients"
import {usePlatformEventsStore} from "@/stores/platformEvents"
import {usePlatformShopsStore} from "@/stores/platformShops"
import {usePlatformShopsFeeCategoriesStore} from "@/stores/platformShopsFeeCategories"
import {usePlatformShopsFeeRulesStore} from "@/stores/platformShopsFeeRules"
import {usePlatformSitesStore} from "@/stores/platformSites"
import {usePlatformUsersStore} from "@/stores/platformUsers"
import {useShopStore} from "@/stores/shop"
import {useShopDeliverySlotsStore} from "@/stores/shopDeliverySlots"
import {useShopItemLabelGroupsStore} from "@/stores/shopItemLabelGroups"
import {useShopItemsStore} from "@/stores/shopItems"
import {useShopItemsHoldsStore} from "@/stores/shopItemsHolds"
import {useShopItemsStockStore} from "@/stores/shopItemsStock"
import {useShopItemTagsStore} from "@/stores/shopItemTags"
import {useShopModelStore} from "@/stores/shopModel"
import {useShopOrderFlowStatesStore} from "@/stores/shopOrderFlowStates"
import {useShopPromoCodesStore} from "@/stores/shopPromoCodes"
import {useShopPromoCodesStatsStore} from "@/stores/shopPromoCodesStats"
import {useShopRidesStore} from "@/stores/shopRides"
import {useSiteStore} from "@/stores/site"
import {useSiteBlocksStore} from "@/stores/siteBlocks"
import {useSiteBlocksContentsStore} from "@/stores/siteBlocksContents"
import {useSiteBlocksCustomTemplatesStore} from "@/stores/siteBlocksCustomTemplates"
import {useSitePagesStore} from "@/stores/sitePages"
import {useSitesStore} from "@/stores/sites"
import {useUserStore} from "@/stores/user"
import {useUserPermissionsStore} from "@/stores/userPermissions"
import {useUserRolesStore} from "@/stores/userRoles"
import {initPlatformaone} from "@platformaone/common"

const offline = import.meta.env.VITE_OFFLINE === "true" || false

export function useAuth() {
  // stores
  const filesShopFilesStore = useFilesShopFilesStore()
  const filesShopImagesStore = useFilesShopImagesStore()
  const filesShopVideosStore = useFilesShopVideosStore()
  const filesSiteFilesStore = useFilesSiteFilesStore()
  const filesSiteImagesStore = useFilesSiteImagesStore()
  const filesSiteVideosStore = useFilesSiteVideosStore()
  const platformClientsStore = usePlatformClientsStore()
  const platformEventsStore = usePlatformEventsStore()
  const platformShopsStore = usePlatformShopsStore()
  const platformShopsFeeCategoriesStore = usePlatformShopsFeeCategoriesStore()
  const platformShopsFeeRulesStore = usePlatformShopsFeeRulesStore()
  const platformSitesStore = usePlatformSitesStore()
  const platformUsersStore = usePlatformUsersStore()
  const shopStore = useShopStore()
  const shopDeliverySlotsStore = useShopDeliverySlotsStore()
  const shopItemLabelGroupsStore = useShopItemLabelGroupsStore()
  const shopItemsStore = useShopItemsStore()
  const shopItemsHoldsStore = useShopItemsHoldsStore()
  const shopItemsStockStore = useShopItemsStockStore()
  const shopItemTagsStore = useShopItemTagsStore()
  const shopModelStore = useShopModelStore()
  const shopOrderFlowStatesStore = useShopOrderFlowStatesStore()
  const shopPromoCodesStore = useShopPromoCodesStore()
  const shopPromoCodesStatsStore = useShopPromoCodesStatsStore()
  const shopRidesStore = useShopRidesStore()
  const siteStore = useSiteStore()
  const siteBlocksStore = useSiteBlocksStore()
  const siteBlocksContentsStore = useSiteBlocksContentsStore()
  const siteBlocksCustomTemplatesStore = useSiteBlocksCustomTemplatesStore()
  const sitePagesStore = useSitePagesStore()
  const sitesStore = useSitesStore()
  const userStore = useUserStore()
  const userPermissionsStore = useUserPermissionsStore()
  const userRolesStore = useUserRolesStore()

  // states
  const {user} = storeToRefs(userStore)
  const {sites} = storeToRefs(sitesStore)

  // getters
  const {userLoaded, userSelectedSiteId} = storeToRefs(userStore)
  const {isPlatformAdmin} = storeToRefs(userRolesStore)
  const {permissions: userPermissions} = storeToRefs(userPermissionsStore)
  const {siteId, siteShopId} = storeToRefs(siteStore)

  function allowed(what) {
    // console.log(
    //   "allowed",
    //   userSelectedSiteId.value,
    //   isPlatformAdmin.value,
    //   siteId.value,
    //   siteShopId.value,
    //   what
    // )
    if (!userSelectedSiteId?.value) return false
    if (!userPermissions?.value) return false
    if (isPlatformAdmin?.value) return true

    const selectedSiteId = siteId.value
    const selectedShopId = siteShopId.value
    let allowed = false

    if (
      selectedShopId &&
      userLoaded &&
      userPermissions.value.shops[selectedShopId] &&
      userPermissions.value.shops[selectedShopId].indexOf(what) > -1
    ) {
      // console.log("allowing", what)
      allowed = true
    }

    if (
      selectedSiteId &&
      userLoaded &&
      userPermissions.value.sites[selectedSiteId] &&
      userPermissions.value.sites[selectedSiteId].indexOf(what) > -1
    ) {
      // console.log("allowing", what)
      allowed = true
    }

    return allowed
  }

  function setSentry(firebaseUser) {
    if (import.meta.env.VITE_ENVIRONMENT == "local_dev") return

    configureScope((scope) => {
      scope.setUser({
        id: firebaseUser.uid,
        email: firebaseUser.email,
      })
    })
  }

  async function listenToFirebaseAuthStateChange() {
    if (offline) return

    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        userStore.userSignedIn = true
        userStore.userAuth = firebaseUser

        initPlatformaone({firebaseUser})

        setSentry(firebaseUser)

        try {
          await loadUserDetails()
          await loadUsersAvailableSites()

          Langs.setLang(userStore.userLang)

          // console.log('auth user:', user.value.config.selectedSiteId)
          // console.log("redirectedFrom:", router.currentRoute.value.redirectedFrom)

          const currentRoute = router.currentRoute.value
          const redirectedFrom = currentRoute?.redirectedFrom
          const continueUrl = currentRoute?.query?.continueUrl

          console.log({redirectedFrom, continueUrl})

          // gift card scanned QR code
          if (redirectedFrom && redirectedFrom.fullPath?.startsWith("/gc/")) {
            console.log("scanned QR code")
            // lookup siteName & code id
            const selectedSiteId = user.value.config.selectedSiteId
            const siteName = sites.value.find((s) => s.id == selectedSiteId)?.name
            if (!siteName) {
              router.push({name: "SitesView"})
            }
            const code = redirectedFrom.fullPath.split("/")[2]
            router.push({
              name: "ShopGiftCardsView",
              params: {siteName},
              query: {code, action: "validate"},
            })
          } else if (redirectedFrom && redirectedFrom.fullPath !== "/") {
            // console.log('forwarding to original request')
            router.push(redirectedFrom)
          }
          // redirect after magic link login
          else if (continueUrl?.replace(import.meta.env.VITE_APP_ADMIN_URL, "")) {
            const continuePath = continueUrl.replace(import.meta.env.VITE_APP_ADMIN_URL, "")
            router.push(continuePath)
          } else {
            if (user.value.config?.selectedSiteId) {
              // console.log("forwarding to user selected site")
              const selectedSiteId = user.value.config.selectedSiteId
              const siteName = sites.value.find((s) => s.id == selectedSiteId)?.name
              if (!siteName) {
                router.push({name: "SitesView"})
              }
              router.push({name: "DashboardView", params: {siteName}})
            } else {
              // console.log('forwarding to site selection')
              router.push({name: "SitesView"})
            }
          }
        } catch (e) {
          console.error("onAuthStateChanged error:", e)
        }
      } else {
        userStore.userAuth = {}
        userStore.userSignedIn = false
      }
    })
  }

  async function loginWithEmailAndPassword(credentials) {
    return signInWithEmailAndPassword(auth, credentials.email, credentials.password)
  }

  async function loginWithMagicLink(params) {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // use email from URL -> can confirm login from another device
      const userEmail = params.em || window.localStorage.getItem("emailForSignIn")

      await setPersistence(auth, browserLocalPersistence)
      await signInWithEmailLink(auth, userEmail).catch((error) => {
        console.error("Error while login the user via the sign in link", error)
      })
      window.localStorage.removeItem("emailForSignIn")
    } else {
      console.error("Login link malformed")
      return false
    }
  }

  async function requestMagicLink(email, redirectUrl) {
    // check if the user exists and is allowed to sign in
    let userSigninMethods = await fetchSignInMethodsForEmail(auth, email)

    if (!userSigninMethods?.length > 0) return false

    const adminLoginFn = httpsCallable(functions, "calls-admin-login")
    const req = {
      email: email,
      lang: "cs",
      authVerifyUrl: import.meta.env.VITE_ADMIN_LOGIN_VERIFY_URL,
      redirectUrl,
    }

    await adminLoginFn(req)

    window.localStorage.setItem("emailForSignIn", email)
    return true
  }

  async function loadUserDetails() {
    const user = auth.currentUser
    const userId = user.uid

    try {
      return await Promise.all([userStore.bind({userId}), userRolesStore.bind({userId})])
    } catch (e) {
      console.error("loadUserDetails error:", e)
    }
  }

  async function loadUsersAvailableSites() {
    try {
      return await sitesStore.bind() // this is probably overkill, loading it once would be enough instead of subscribing all
    } catch (e) {
      console.error("loadUsersAvailableSites error:", e)
    }
  }

  async function changePassword(args) {
    const user = auth.currentUser
    const newPassword = args.newPassword

    await updatePassword(user, newPassword).catch((error) => {
      console.error("changePassword error:", error)
    })

    return {status: "password-updated"}
  }

  async function logout() {
    await Promise.all([
      filesShopFilesStore.unbind(),
      filesShopImagesStore.unbind(),
      filesShopVideosStore.unbind(),
      filesSiteFilesStore.unbind(),
      filesSiteImagesStore.unbind(),
      filesSiteVideosStore.unbind(),
      platformClientsStore.unbind(),
      platformEventsStore.unbind(),
      platformShopsStore.unbind(),
      platformShopsFeeCategoriesStore.unbind(),
      platformShopsFeeRulesStore.unbind(),
      platformSitesStore.unbind(),
      platformUsersStore.unbind(),
      shopStore.unbind(),
      shopDeliverySlotsStore.unbind(),
      shopItemLabelGroupsStore.unbind(),
      shopItemsStore.unbind(),
      shopItemsHoldsStore.unbind(),
      shopItemsStockStore.unbind(),
      shopItemTagsStore.unbind(),
      shopModelStore.unbind(),
      shopOrderFlowStatesStore.unbind(),
      shopPromoCodesStore.unbind(),
      shopPromoCodesStatsStore.unbind(),
      shopRidesStore.unbind(),
      siteStore.unbind(),
      siteBlocksStore.unbind(),
      siteBlocksContentsStore.unbind(),
      siteBlocksCustomTemplatesStore.unbind(),
      sitePagesStore.unbind(),
      sitesStore.unbind(),
      userStore.unbind(),
      userRolesStore.unbind(),
    ])

    signOut(auth)

    router.push({
      name: "AuthView",
    })
  }

  return {
    allowed,
    listenToFirebaseAuthStateChange,
    loginWithEmailAndPassword,
    loginWithMagicLink,
    requestMagicLink,
    changePassword,
    logout,
    // user,
    // userLoaded,
    // userPermissions,
    // isPlatformAdmin,
    // siteId,
    // siteShopId,
  }
}
