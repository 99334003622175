import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useNotificationsStore = defineStore("notifications", () => {
  // state
  const notifications = ref([])

  return {
    // state
    notifications,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
