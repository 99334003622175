import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {auth} from "@/firebase"
import {useAppStore} from "@/stores/app"

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const appStore = useAppStore()
  const userSignedIn = auth.currentUser
  // console.log("router.beforeEach:", to, from, userSignedIn)
  if (!userSignedIn && !["AuthView", "AuthVerifyView"].includes(to.name)) {
    // console.log("router: not allowed, redirecting to Auth")
    return {name: "AuthView"}
  }

  // clear floating menu
  appStore.app.floatingMenu = {}
})

export {router}
