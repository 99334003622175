import {ref, computed} from "vue"
import {acceptHMRUpdate} from "pinia"
import {randomKey} from "../../../lib/composables/utils.js"

export function useAppNotificationsStore({defineStore}) {
  return defineStore("appNotifications", () => {
    // state
    const notifications = ref([])

    function appNotification({
      active = true,
      display = "toast", // toast | snackbar
      type = "info", // info | success | warning | error
      title,
      message,
      delay = 5000,
    }) {
      const id = randomKey()
      const notification = {
        id,
        active,
        display,
        type,
        title,
        message,
        delay,
      }

      notifications.value.push(notification)

      setTimeout(() => {
        const notificationIndex = notifications.value.findIndex(
          (notification) => notification.id === id
        )
        notifications.value.splice(notificationIndex, 1)
      }, delay)
    }

    return {
      // state
      notifications,

      // actions
      appNotification,
    }
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppNotificationsStore, import.meta.hot))
}
