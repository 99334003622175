const config = {
  appId: null,
  apiKey: null,
  firebaseUser: null,
  scope: {
    orgId: null,
    spaceId: null,
    environmentId: null,
  },
  appNotification: null, // convenience function to send app notifications automatically (from ./stores/app.js)
}

export const initPlatformaone = ({appId, apiKey, firebaseUser, scope, appNotification}) => {
  console.log("initPlatformaone()", {appId, apiKey, firebaseUser, scope, appNotification})
  
  if (firebaseUser) {
    // the firebase auth token refreshes often, so i have to get it each time
    config.firebaseUser = firebaseUser //.getIdToken()
  } else {
    config.apiKey = apiKey
  }
  if (scope) {
    config.scope = scope
  }
  if (appId) {
    config.appId = appId
  }
  if (appNotification) {
    config.appNotification = appNotification
  }
}

export const getConfig = () => config
