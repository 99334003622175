import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore, functions} from "@/firebase/index.js"
import {httpsCallable} from "firebase/functions"
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const usePlatformSitesStore = defineStore("platformSites", () => {
  // stores
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const sites = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const platformSitesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const collectionRef = collection(firestore, `sites`)
    return await this.attach("sites", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add() {
    const manageFn = httpsCallable(functions, "calls-admin-platform-manage")
    const response = await manageFn({action: "create-site"})
    return {siteId: response.data.siteId}
  }

  async function update(args) {
    formSaveStateStore.markSaving(args.fieldId)

    const siteId = args.data.id
    const docRef = doc(firestore, `sites/${siteId}`)
    const data = {
      ...(args.data.config && {config: args.data.config}),
      ...(args.data.domains && {domains: args.data.domains}),
      ...(args.data.name && {name: args.data.name}),
      ...(args.data.note && {note: args.data.note}),
      ...(args.data.clientId && {clientId: args.data.clientId}),
      ...(args.data.shopId && {shopId: args.data.shopId}),
      ...(args.data.logo && {logo: args.data.logo}),
      ...(args.data.content && {content: args.data.content}),
      ...(args.data.platform && {platform: args.data.platform}),
      ...(args.data.scope && {scope: args.data.scope}),
      ...(args.data.features && {features: args.data.features}),
      ...(args.data.domainSystemDefault && {domainSystemDefault: args.data.domainSystemDefault}),
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function remove(args) {
    const siteId = args.siteId
    const docRef = doc(firestore, `sites/${siteId}`)
    return await deleteDoc(docRef)
  }

  return {
    // state
    sites,
    status,
    unsubscribe,

    // getters
    platformSitesLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlatformSitesStore, import.meta.hot))
}
