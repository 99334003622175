import {getConfig} from "./init.js"
import axios from "axios" // TODO: replace with fetch when node v21+ is released for firebase functions

// do not try to use environment variables here as long as this function is required to run in firebase functions. they just do not load env variables in local emulator.
const urlLocal = "http://localhost:4011"
const urlProduction = "https://api-p532w4ft7q-ey.a.run.app"

const backend = typeof process !== "undefined" && process.env.GCLOUD_PROJECT
const environment =
  typeof process !== "undefined" && process.env
    ? process.env.ENVIRONMENT ||
      process.env.GCLOUD_PROJECT?.replace("platformaone-", "") ||
      "local_dev"
    : import.meta.env.VITE_ENVIRONMENT || "local_dev"
const production = environment === "production"

const URL_API = backend ? (production ? urlProduction : urlLocal) : "/api"

// console.log({backend, environment, production, URL_API})

export async function request({endpoint, method = "POST", data = null, opts = {}}) {
  const config = getConfig()

  console.log("🔷 request", {endpoint, config, method, data, opts})

  let apiKey
  if (config.firebaseUser) {
    // the firebase auth token refreshes often, so i have to get it each time
    apiKey = await config.firebaseUser.getIdToken()
  } else {
    apiKey = config.apiKey
  }

  let url = `${URL_API}/${endpoint}`
  // const options = {
  //   method,
  //   headers: {
  //     "Content-Type": "application/json",
  //     ...(apiKey ? {Authorization: `Bearer ${apiKey}`} : {}),
  //   },
  // }

  // if (data) {
  //   // GET
  //   if (["GET", "DELETE"].includes(method)) {
  //     const params = new URLSearchParams(data).toString()
  //     url = `${url}?${params}`
  //   }
  //   // POST, PATCH, PUT
  //   else if (["POST", "PATCH", "PUT"].includes(method)) {
  //     options.body = JSON.stringify(data)
  //   }
  // }

  // scope may be overriden by opts for individual requests
  const orgId = opts.scope?.orgId || config.scope.orgId
  const spaceId = opts.scope?.spaceId || config.scope.spaceId
  const environmentId = opts.scope?.environmentId || config.scope.environmentId

  // temporary for axios until replaced by fetch
  const optionsAxios = {
    method,
    url,
    headers: {
      "X-Channel": config.appId,
      "X-Scope-Org": orgId,
      "X-Scope-Space": spaceId,
      "X-Scope-Environment": environmentId,
      "Content-Type": "application/json",
      ...(apiKey ? {Authorization: `Bearer ${apiKey}`} : {}),
    },
    withCredentials: true,
    validateStatus: function (status) {
      // Never reject the promise, treat all responses as success - this is same behavior as fetch
      return true // Always resolves the promise
    },
  }

  // temoporary for axios until replaced by fetch
  if (data) {
    if (["GET", "DELETE"].includes(method)) {
      // Use params for GET and DELETE requests
      optionsAxios.params = data
    } else if (["POST", "PATCH", "PUT"].includes(method)) {
      // Use data for POST, PATCH, PUT requests
      optionsAxios.data = data
    }
  }

  try {
    // const response = await fetch(url, options)
    const response = await axios(url, optionsAxios).catch((error) => {
      console.error(error)
      // return {error}
    })

    // code
    const status = response.status

    // const contentType = response.headers.get("content-type")
    // const data = contentType?.includes("application/json")
    //   ? await response.json()
    //   : await response.text()
    const data = response.data

    const result = {
      success: status >= 200 && status < 300,
      error: status >= 400,
      // response: {
      //   status,
      // },
      statusCode: status,
      response: data,
    }

    // convenience function to send app notifications automatically (from ./stores/app.js)

    let notifyAutomatically = true
    if (opts.notify === false) {
      notifyAutomatically = false
    }
    if (opts.notifySuccess === false && data.status === "success") {
      notifyAutomatically = false
    }

    // console.log({endpoint, notifyAutomatically})

    if (config.appNotification && notifyAutomatically) {
      config.appNotification({
        title: data.statusMessage || data.statusCode || "unknown error",
        type: data.status || "error",
      })
    }

    console.log("🔶", result)

    return result
  } catch (error) {
    // console.error(error)
    // return {error}
  }
}
