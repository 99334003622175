import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc} from "firebase/firestore"
import {useShopStore} from "./shop"

export const useShopPromoCodesStatsStore = defineStore("shopPromoCodesStats", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const stats = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopPromoCodesStatsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const docRef = doc(firestore, `shops/${shopId}/promoCodesStats/default`)
    return await this.attach("stats", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    stats,
    status,
    unsubscribe,

    // getters
    shopPromoCodesStatsLoaded,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopPromoCodesStatsStore, import.meta.hot))
}
