export default {
  text: {
    // auth
    "auth-otp-form-email-placeholder": "email@example.com",
    "auth-otp-form-button-continue-label": "continue",
    "auth-otp-form-email-sent-to": "We sent you a code to",
    "auth-otp-form-email-wrong-email": "wrong email?",
    "auth-otp-form-button-verify-label": "sign in",

    "version-check-new-version-available": "new version available",
    "version-check-reload": "reload",

    main_title: "Administration",

    "menu-cms": "cms",
    "menu-pages": "Pages",
    "menu-posts": "Posts",
    "menu-commerce": "Commerce",
    "menu-analytics": "Analytics",
    "menu-settings": "Settings",
    "menu-help": "Help",
    "menu-logout": "Logout",
    "menu-publisher": "Publisher",
    "menu-designer": "Designer",

    "sites-main-headline": "My Sites",

    "option-yes": "Yes",
    "option-no": "No",

    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

    "login-header": "Login",
    "login-header-label": "Proceed to login here...",
    "login-user-name": "User name",
    "login-user-password": "Password",
    "login-user-email": "E-mail",
    "login-submit-button": "Sign in",
    "login-help": "Need help?",
    "login-use-pwd": "use password",
    "login-use-pwdless": "use e-mail link",
    "login-use-passkey": "use Passkey",
    "login-msg-welcome": "platforma.one",
    "login-error-wrong-password": "Wrong password",
    "login-error-too-many-requests": "Too many attempts, try later.",
    "login-validator-passwordlessemail-errormessage-notvalid":
      "Not close enough. Misspelled smoething?",
    "login-validator-passwordlessemail-errormessage-notfound": "We didn't find you. Are you real?",
    "login-validator-passwordlessemail-errormessage-empty": "This is the place to put your e-mail.",
    "login-validator-passwordemail-errormessage-bad": "This",
    "login-validator-passwordemail-errormessage-empty": "Come on, be more specific.",
    "login-validator-passwordemail-errormessage-notvalid": "That's not an email!",
    "login-validator-password-errormessage-bad": "or that is not correct.",
    "login-validator-password-errormessage-empty": "Come on, passwords are important!",
    "login-email-instructions-headline": "Check your email",
    "login-email-sent-instructions-pt1": "Login email is on its way to",
    "login-email-sent-instructions-pt2": "",
    "login-email-sent-instructions-pt3": "You may close this window.",
    "login-email-instructions-tryagain": "Try again",

    "guest-signup-email-instructions-headline": "Wohoo, only one step away from your site!",
    "guest-signup-email-sent-instructions-pt1":
      "Confirmation link to publish your site was sent to",
    "guest-signup-email-sent-instructions-pt2":
      "You may close this window, new one will be opened from the link you will receive.",
    "guest-signup-published-headline": "It's on!",
    "guest-signup-published-text": "Your site is running live at",
    "guest-signup-published-open-btn": "Check it out",

    "main-site-menu": "Main menu",

    "settings-header": "Settings",
    "site-config-title": "Site title",

    "pages-header": "Pages",
    "pages-add-subpage": "Add subpage",
    "pages-stop-adding-subpage": "Finished adding subpage",
    "page-qr-code-generator-page-lang": "Language",
    "page-list": "Page list",
    "main-view-site": "View site",
    "page-selected-layout": "Layout",
    "page-selected-content": "Content",
    "page-selected-settings": "Settings",
    "page-selected-styles": "Styles",
    "page-setup-title": "Title",
    "page-setup-role": "Required role",
    "page-setup-description": "Desription",
    "page-setup-slugs": "URL",
    "page-setup-visible": "Visible",
    "page-button-delete": "Delete page",
    "page-add-hint": "Click a point to insert",
    "page-duplicate": "Duplicate",
    "page-duplicate-parent": "Parent page",
    "page-duplicate-new-title": "New page title",
    "page-duplicate-execute": "Make it so",
    "page-clone-fill_name": "Fill page name for language: ",
    "page-clone-parent_no_slug": "Parent page has no slug!",
    "page-clone-slag_for_lang": "Parent page has no slug for language: ",
    "page-clone-done": "Duplication completed.",
    "page-clone-error": "Duplication failed!",
    "page-block-styling": "Styling",
    "label-add-language-version": "Add language version",

    // editor
    "editor-blockForm-main-textWithHeader-form-header-label": "header",
    "editor-blockForm-main-textWithHeader-form-paragraph-label": "paragraph",
    "editor-blockForm-main-header-form-header-label": "header",
    "editor-blockForm-commerce-productListing-form-productDetailPage-label": "product page",
    "editor-blockForm-commerce-productListing-form-productDetailPage-caption":
      "product page that will be shown when product is clicked in the list",
    "editor-blockForm-commerce-productListing-form-filterByTags-label": "product filter",
    "editor-blockForm-commerce-productListing-form-filterByTags-caption":
      "tags of products to be be shown in this section",
    "editor-blockForm-commerce-productListing-form-sortBy-label": "sorting",
    "editor-blockForm-commerce-productListing-form-sortBy-option-title-label": "title",
    "editor-blockForm-commerce-productListing-form-sortBy-option-price-label": "price",
    "editor-blockForm-commerce-productListing-form-sortBy-option-dateUpdated-label": "date updated",
    "editor-blockForm-commerce-productListing-form-sortBy-option-dateCreated-label": "date created",
    "editor-blockForm-commerce-productListing-form-sortBy-option-availability-label":
      "availability",
    "editor-blockForm-commerce-productListing-form-sortBy-addField": "add another field",
    "editor-blockForm-commerce-productListing-form-limit-label": "limit the number of items",
    "editor-blockForm-commerce-productListing-form-showAllButton-label": "show 'more' button",
    "editor-blockForm-commerce-productListing-form-showItemsAvailable-label": "show available",
    "editor-blockForm-commerce-productListing-form-showItemsUnavailable-label": "show unavailable",
    "editor-blockForm-commerce-productListing-form-showItemsForPreorder-label": "show preorder",
    "editor-blockForm-commerce-productListing-form-showFilters-label": "show filters",

    "generic-download": "Download",
    "commerce-generate-qr-code": "Generate QR code",
    "commerce-remove-variant": "Remove variant",
    "commerce-qr-code-generator-auto-add-to-cart": "Automatically add to cart",
    "commerce-qr-code-generator-delivery-method": "Delivery method",
    "commerce-qr-code-generator-hide-other-delivery-methods": "Hide other delivery methods",
    "commerce-order-name-anonymous": "no name",
    "commerce-order-item-giftcard-resendToCustomer": "resend to customer",
    "commerce-order-item-giftcard-resendToRecipient": "resend to recipient",
    "commerce-order-item-giftcard-download": "download",
    "commerce-header": "E-shop",
    "commerce-shop-name": "Shop name",
    "commerce-no-site": "Select the website first",
    "commerce-new-eshop": "Create new e-shop",
    "commerce-carts": "Carts",
    "commerce-orders": "Orders",
    "commerce-inventory": "Inventory",
    "commerce-shop-items": "Shop items",
    "commerce-customers": "Customers",
    "commerce-subscriptions": "Subscriptions",
    "commerce-orders-setup": "Settings",
    "commerce-export": "Export",
    "commerce-orders-flow-setup": "Order flow setup",
    "commerce-shop-create-model": "Create new product form specification",
    "commerce-shop-update-model": "Update product form specification",
    "commerce-products-header": "Products",
    "commerce-product-attr-name": "Field name",
    "commerce-product-model-name": "Product type name",
    "commerce-shop-create-product": "Create new product",
    "commerce-shop-update-product": "Update product details",
    "commerce-product-header": "Product",
    "commerce-fee-reports": "Fee reports",
    "commerce-fee-billing": "Billing",
    "product-created-info": "Product has been created.",
    "product-not-created-error": "A problem prevented product creation!",
    "product-saved-info": "Product has been updated.",
    "product-not-saved-error": "A problem prevented product alteration!",
    "product-question-delete": "Do you really want to remove the product (including variants)?",
    "product-image-main": "Main product",
    "product-image-variant": "Main variant",
    "product-fees": "Fees",
    "product-sku-already-exists": "SKU must be unique",
    "upload-file-in-process": "Upload in progress",
    "commerce-setup": "Commerce setup",
    "commerce-supported-curr": "Supported currencies",
    "commerce-default-curr": "Default currency",
    "commerce-supported-langs": "Supported languages",
    "commerce-default-lang": "Default language",
    "commerce-order-num-format": "Order number format",
    "commerce-order-num-format-hint": "...",
    "commerce-sell-units": "Possible sale units",
    "commerce-sell-units-hint": "...",
    "commerce-bank-connection": "Bank connection",
    "commerce-bank-name": "Bank name",
    "commerce-bank-code": "Bank code",
    "commerce-account-number": "Account number",
    "commerce-iban": "IBAN",
    "commerce-swift": "SWIFT",
    "commerce-company-address": "Company address",
    "commerce-setup-payments": "Payment setup",
    "commerce-payment-methods": "Payment methods",
    "commerce-payment-method-add": "Add another payment method",
    "commerce-delivery-planning-bulk-edit-enable": "Bulk edit",
    "commerce-delivery-planning-bulk-edit-disable": "Cancel bulk edit",
    "commerce-delivery-planning-delete-all": "Remove all",
    "commerce-tax-values": "Available tax values",
    "commerce-delivery-channels": "Supported delivery methods",
    "commerce-setup-deliveries": "Deliveries setup",
    "commerce-delivery-method-add": "Add another delivery method",
    "commerce-setup-delivery-planning-settings": "Delivery planning settings",
    "commerce-setup-delivery-planning": "Delivery planning",
    "commerce-delivery-next-day-order": "Last next-day order at:",
    "commerce-delivery-next-days": "Show next days:",
    "commerce-delivery-use-defaults": "Use defaults",
    "commerce-delivery-add-slot": "+ Add Slot",
    "commerce-delivery-additional-fields": "Additional cart form fields",
    "commerce-delivery-add-field": "+ Add field",
    "commerce-delivery-planning": "Delivery planning",
    "commerce-delivery-default-slots": "Slots template",
    "commerce-setup-delivery-planning-slots": "Active slots",
    "commerce-delivery-settings": "Settings",
    "commerce-delivery-point-title": "Delivery point",
    "commerce-flow-delete-error": "Not possible to delete state. It is used in: [%0]",
    "commerce-flow-cmd-set": "Set a value",
    "commerce-flow-cmd-exe": "Execute operation",
    "commerce-flow-operation-request-liftago": "Request Liftago",
    "commerce-flow-operation-zasilkovna-upload-to-zasilkovna": "Upload to Zasilkovna",
    "commerce-flow-operation-get-shipping-labels": "Print labels",
    "commerce-flow-operation-send-order-state-change-notification":
      "E-mail about order state change",
    "commerce-flow-check-equal": "Equal",
    "commerce-flow-check-not-equal": "Not Equal",
    "commerce-flow-check-contains": "Contains",
    "commerce-flow-check-not-contains": "Not contans",

    "commerce-order-tags-paid": "paid",
    "commerce-order-tags-unpaid": "unpaid",
    "commerce-order-tags-contactless": "contactless",

    "commerce-orders-items-filter-order-state": "state",
    "commerce-orders-items-filter-delivery-method": "method",
    "commerce-orders-items-filter-delivery-branch": "branch",

    "commerce-orders-items-table-col-title": "title",
    "commerce-orders-items-table-col-item-count": "count",
    "commerce-orders-items-table-col-order-number": "order",
    "commerce-orders-items-table-col-order-price": "or. price",
    "commerce-orders-items-table-col-order-state": "state",
    "commerce-orders-items-table-col-delivery-method": "method",
    "commerce-orders-items-table-col-delivery-branch": "branch",
    "commerce-orders-items-table-col-delivery-slot": "slot",
    "commerce-orders-items-table-col-customer": "customer",
    "commerce-orders-items-table-col-payment": "payment",
    "commerce-orders-items-table-col-ordered-at": "ordered",

    "commerce-price-brutto": "incl. VAT",
    "commerce-price-netto": "excl. VAT",
    "commerce-price-units": "units",
    "commerce-price-enter-without-vat": "enter price without VAT",
    "commerce-price-enter-with-vat": "enter price with VAT",
    "commerce-price-currency": "currency",
    "commerce-price-vat": "VAT",
    "commerce-price-initial": "Initial",

    "commerce-promo-codes": "Discount codes",
    "commerce-gift-cards": "Gift cards",
    "commerce-promocode-label": "Code",
    "commerce-promocode-codeslist": "Code list",
    "commerce-promocode-count": "Count of individual usage",
    "commerce-promocode-unit": "Unit",
    "commerce-promocode-value": "Value",
    "commerce-promocode-from": "Valid from",
    "commerce-promocode-to": "Valid to",
    "commerce-promocode-tags": "Tags",
    "commerce-promocode-note": "Note",
    "commerce-promocode-kind": "Kind",

    // commerce gift cards
    "commerce-giftcards-table-code": "Code",
    "commerce-giftcards-table-status": "Status",
    "commerce-giftcards-table-value": "Value",
    "commerce-giftcards-table-valueRemaining": "Balance",
    "commerce-giftcards-table-customerName": "Name",
    "commerce-giftcards-table-product": "Product",
    "commerce-giftcards-table-validFrom": "Valid from",
    "commerce-giftcards-table-validTo": "Valid to",
    "commerce-giftcards-table-purchasedIn": "Order",
    "commerce-giftcards-table-usedAt": "Used at",
    "commerce-giftcards-table-usage": "Usage",
    "commerce-giftcards-table-createdAt": "Created",

    "commerce-giftcards-status-available": "Available",
    "commerce-giftcards-status-used": "Used",
    "commerce-giftcards-status-usedPartially": "Partially used",
    "commerce-giftcards-status-expired": "Expired",
    "commerce-giftcards-status-purchased": "Purchased",
    "commerce-giftcards-status-notPurchased": "Not purchased",

    "commerce-giftcards-details-allowedUsage-atStore": "at store",
    "commerce-giftcards-details-allowedUsage-eShop": "e-shop",
    "commerce-giftcards-details-allowedUsage-subtractable": "subtractable",
    "commerce-giftcards-details-history-col-label-amount": "amount",
    "commerce-giftcards-details-history-col-label-note": "note",
    "commerce-giftcards-details-history-col-label-date": "date",
    "commerce-giftcards-details-history-col-label-time": "time",
    "commerce-giftcards-details-valid": "valid",
    "commerce-giftcards-details-valueLeft": "value left",
    "commerce-giftcards-details-details": "details",
    "commerce-giftcards-details-allowedUsage": "allowed usage",
    "commerce-giftcards-details-validity-from": "valid from",
    "commerce-giftcards-details-validity-to": "valid to",
    "commerce-giftcards-details-history": "history",
    "commerce-giftcards-details-order": "order",
    "commerce-giftcards-details-transact": "transact",
    "commerce-giftcards-details-validate-amount": "amount",
    "commerce-giftcards-details-validate-privateNote": "private note",

    "commerce-giftcards-add-title": "New gift card",
    "commerce-giftcards-add-form-field-multiple-label": "count",
    "commerce-giftcards-add-form-field-multiple-option-single": "single",
    "commerce-giftcards-add-form-field-multiple-option-multiple": "multiple",
    "commerce-giftcards-add-form-field-code-label": "code",
    "commerce-giftcards-add-form-field-code-caption": "Any code, e.g. GC-2345456 or 42392004",
    "commerce-giftcards-add-form-field-codes-label": "code list",
    "commerce-giftcards-add-form-field-codes-caption": "Any codes separated by a new line.",
    "commerce-giftcards-add-form-field-value-label": "value",
    "commerce-giftcards-add-form-field-value-caption": "Any amount, e.g. 100 or 10000.00",
    "commerce-giftcards-add-form-field-usage-label": "allowed usage",
    "commerce-giftcards-add-form-field-allowOffline-label": "at store",
    "commerce-giftcards-add-form-field-allowOnline-label": "on e-shop",
    "commerce-giftcards-add-form-field-allowCredit-label": "subtractable (multiple usage)",
    "commerce-giftcards-add-form-field-validity-label": "validity",
    "commerce-giftcards-add-form-field-from-label": "from",
    "commerce-giftcards-add-form-field-from-caption": "shows timezone",
    "commerce-giftcards-add-form-field-to-label": "to",
    "commerce-giftcards-add-form-field-to-caption": "shows timezone",
    "commerce-giftcards-add-form-field-validity-presets-label": "preset",
    "commerce-giftcards-add-form-field-validity-presets-1m-label": "1 month",
    "commerce-giftcards-add-form-field-validity-presets-3m-label": "3 months",
    "commerce-giftcards-add-form-field-validity-presets-6m-label": "6 months",
    "commerce-giftcards-add-form-field-validity-presets-1y-label": "1 year",
    "commerce-giftcards-add-form-field-validity-presets-2y-label": "2 years",
    "commerce-giftcards-add-form-field-validity-presets-3y-label": "3 years",
    "commerce-giftcards-add-form-field-validity-presets-5y-label": "5 years",
    "commerce-giftcards-add-form-field-validity-presets-10y-label": "10 years",
    "commerce-giftcards-add-form-field-validity-presets-2099-label": "2099",
    "commerce-giftcards-add-form-field-customerName-label": "name",
    "commerce-giftcards-add-form-field-customerEmail-label": "email",
    "commerce-giftcards-add-form-field-customerPhone-label": "phone",
    "commerce-giftcards-add-form-field-note-label": "note",
    "commerce-giftcards-add-form-field-note-caption": "internal note, not visible to customers",
    "commerce-giftcards-add-form-action-save-label": "save",
    "commerce-giftcards-add-form-action-cancel-label": "cancel",

    "commerce-giftcards-transact-form-title": "Transaction",
    "commerce-giftcards-transact-form-direction-subtract": "subtract",
    "commerce-giftcards-transact-form-direction-add": "add",
    "commerce-giftcards-transact-form-amount-label": "amount",
    "commerce-giftcards-transact-form-amount-placeholder": "1000",
    "commerce-giftcards-transact-form-note-label": "note",
    "commerce-giftcards-transact-form-note-placeholder": "for order lala-123",
    "commerce-giftcards-transact-form-note-caption": "internal, not visible to customers",
    "commerce-giftcards-transact-form-actions-cancel-label": "cancel",
    "commerce-giftcards-transact-form-actions-transact-subtract-label": "subtract",
    "commerce-giftcards-transact-form-actions-transact-add-label": "add",
    "commerce-giftcards-transact-form-actions-transactConfirm-label": "confirm",

    // commerce setup
    // invoicing
    "commerce-setup-company-title-invoicing": "Invoicing",
    "commerce-setup-company-label-orderNumberFormat": "Order number format",

    // invoice info
    "commerce-setup-company-title-company": "Invoice info",
    "commerce-setup-company-label-name": "Company",
    "commerce-setup-company-label-ico": "IČO",
    "commerce-setup-company-label-dic": "DIČ",
    "commerce-setup-company-label-legalNote": "Note",

    // address
    "commerce-setup-company-label-street": "Street",
    "commerce-setup-company-label-streetNumber": "Street no.",
    "commerce-setup-company-label-city": "City",
    "commerce-setup-company-label-postal": "Postal",
    "commerce-setup-company-label-country": "Country",

    // banking
    "commerce-setup-company-label-bankName": "Bank name",
    "commerce-setup-company-label-bankCode": "Bank code",
    "commerce-setup-company-label-accountNumber": "Account number",
    "commerce-setup-company-label-iban": "IBAN",
    "commerce-setup-company-label-swift": "SWIFT",

    // contact
    "commerce-setup-company-label-phone": "Phone",
    "commerce-setup-company-label-email": "E-mail",

    "commerce-settings-general-shop-name": "Shop name",
    "commerce-settings-payment-taxes": "VAT [%]",

    "commerce-orders-hide-column": "Hide column",
    "commerce-orders-select-multiple": "Select orders",
    "commerce-orders-cancel-selection": "Cancel selection",
    "commerce-orders-of-selected": "selected",
    "commerce-orders-of-all": "all",

    "commerce-courier-liftago-courierPosition": "Courier location",
    "commerce-courier-liftago-rideStatus-PROCESSING": "Looking for driver",
    "commerce-courier-liftago-rideStatus-ACCEPTED": "Driver selected",
    "commerce-courier-liftago-rideStatus-WAITING": "Driver awaiting pickup",
    "commerce-courier-liftago-rideStatus-IN_PROGRESS": "Items on board",
    "commerce-courier-liftago-rideStatus-COMPLETED": "Delivery finished",
    "commerce-courier-liftago-rideStatus-CANCELLED": "Delivery cancelled",
    "commerce-courier-liftago-rideStatus-REJECTED": "Couldn't find driver",
    "commerce-courier-liftago-stopState-IN_QUEUE": "In delivery",
    "commerce-courier-liftago-stopState-DELIVERED": "Delivered",
    "commerce-courier-liftago-stopState-UNDELIVERED": "Not delivered",
    "commerce-courier-liftago-handoverStatus-NEW": "Processed",
    "commerce-courier-liftago-handoverStatus-SCHEDULED": "Scheduled",
    "commerce-courier-liftago-handoverStatus-IN_RIDE": "Awaiting pickup",
    "commerce-courier-liftago-handoverStatus-ON_BOARD": "On board",
    "commerce-courier-liftago-handoverStatus-DELIVERING": "Delivering",
    "commerce-courier-liftago-handoverStatus-DELIVERED": "Delivered",
    "commerce-courier-liftago-handoverStatus-UNDELIVERED": "Undelivered",
    "commerce-courier-liftago-handoverStatus-RETURNING": "Returning",
    "commerce-courier-liftago-handoverStatus-RETURNED": "Returned",
    "commerce-courier-liftago-handoverStatus-CANCELED": "Canceled",
    "commerce-courier-liftago-handoverStatus-CANCELED_IN_RIDE": "Canceled during pickup",
    "commerce-courier-liftago-handoverStatus-CANCELED_WITH_POB": "Canceled after boarding",
    "commerce-courier-liftago-handoverStatus-CANCELED_BY_DRIVER": "Canceled by driver",
    "commerce-courier-liftago-handoverStatus-WONT_DELIVER": "Undeliverable",

    "commerce-inventory-table-col-image": "image",
    "commerce-inventory-table-col-title": "title",
    "commerce-inventory-table-col-type": "typ",
    "commerce-inventory-table-col-type-option-main": "main",
    "commerce-inventory-table-col-type-option-variant": "variant",
    "commerce-inventory-table-col-sku": "SKU",
    "commerce-inventory-table-col-price": "price",
    "commerce-inventory-table-col-vat": "VAT",
    "commerce-inventory-table-col-stock-stocked": "in stock",
    "commerce-inventory-table-col-stock-available": "available",
    "commerce-inventory-table-col-stock-reserved": "reserved",
    "commerce-inventory-table-col-stock-blocked": "blocked",
    "commerce-inventory-table-col-tags": "tags",
    "commerce-inventory-table-col-delivery-methods": "delivery",
    "commerce-inventory-table-col-kind": "category",
    "commerce-inventory-table-col-kind-option-physical": "physical",
    "commerce-inventory-table-col-kind-option-virtual": "electronic",
    "commerce-inventory-table-col-kind-option-no-delivery": "no delivery",
    "commerce-inventory-table-col-subkind": "subcategory",
    "commerce-inventory-table-col-subkind-option-booking": "booking",
    "commerce-inventory-table-col-subkind-option-giftCard": "gift card",
    "commerce-inventory-table-col-subkind-option-video-on-demand": "video on demand",
    "commerce-inventory-table-col-subkind-option-digital-product": "digital product",
    "commerce-inventory-table-col-visibility": "visibility",
    "commerce-inventory-table-col-visibility-option-auto": "by stock",
    "commerce-inventory-table-col-visibility-option-available": "available",
    "commerce-inventory-table-col-visibility-option-unavailable": "unavailable",
    "commerce-inventory-table-col-visibility-option-preorder": "preorder",
    "commerce-inventory-table-col-visibility-option-hide": "hidden",
    "commerce-inventory-table-col-whenUnavailable": "visibility sold out",
    "commerce-inventory-table-col-whenUnavailable-option-restrict": "disable",
    "commerce-inventory-table-col-whenUnavailable-option-preorder": "preorder",
    "commerce-inventory-table-col-whenUnavailable-option-hide": "hide",
    "commerce-inventory-table-col-created": "created",
    "commerce-inventory-table-col-updated": "updated",

    // identity: companies
    "identity-companies-col-label-name": "name",
    "identity-companies-col-label-created": "created",
    "identity-companies-col-label-updated": "updated",
    "identity-companies-add-form-title": "New company",
    "identity-companies-add-form-field-name-label": "name",
    "identity-companies-add-form-field-note-label": "note",
    "identity-companies-add-form-field-note-caption": "internal, not visible to customers",
    "identity-companies-add-form-action-save-label": "save",
    "identity-companies-add-form-action-cancel-label": "cancel",

    // identity: persons
    "identity-persons-col-label-nameFirst": "first name",
    "identity-persons-col-label-nameLast": "last name",
    "identity-persons-col-label-email": "e-mail",
    "identity-persons-col-label-phone": "phone",
    "identity-persons-col-label-created": "created",
    "identity-persons-col-label-updated": "updated",
    "identity-persons-add-form-title": "New person",
    "identity-persons-add-form-field-nameFirst-label": "first name",
    "identity-persons-add-form-field-nameLast-label": "last name",
    "identity-persons-add-form-field-email-label": "e-mail",
    "identity-persons-add-form-field-phone-label": "phone",
    "identity-persons-add-form-field-companies-label": "companies",
    "identity-persons-add-form-field-note-label": "note",
    "identity-persons-add-form-field-note-caption": "internal, not visible to customers",
    "identity-persons-add-form-action-save-label": "save",
    "identity-persons-add-form-action-cancel-label": "cancel",

    // balance
    "balance-balances-overview-total-available": "total available",
    "balance-balances-overview-lifetime-credit": "lifetime credit",
    "balance-balances-overview-lifetime-debit": "lifetime debit",
    "balance-balances-col-label-name": "name",
    "balance-balances-col-label-balanceGroupId": "group",
    "balance-balances-col-label-company": "company",
    "balance-balances-col-label-persons": "persons",
    "balance-balances-col-label-available": "available",
    "balance-balances-col-label-currency": "currency",
    "balance-balances-col-label-status": "status",
    "balance-balances-col-label-allowCredit": "credit",
    "balance-balances-col-label-allowDebit": "debit",
    "balance-balances-col-label-allowDebitPartial": "debit partial",
    "balance-balances-col-label-lifetimeCredit": "lifetime credit",
    "balance-balances-col-label-lifetimeDebit": "lifetime debit",
    "balance-balances-col-label-ruleLimits": "limits",
    "balance-balances-col-label-ruleChannels": "channels",
    "balance-balances-col-label-activeFrom": "active from",
    "balance-balances-col-label-activeTo": "active to",
    "balance-balances-col-label-created": "created",
    "balance-balances-col-label-updated": "updated",
    "balance-balances-col-value-status-active": "active",
    "balance-balances-col-value-status-locked": "locked",
    "balance-balances-col-value-status-suspended": "suspended",
    "balance-balances-col-value-status-expired": "expired",
    "balance-balances-col-value-status-closed": "closed",
    "balance-balance-detail-overview-available-label": "available",
    "balance-balance-detail-overview-lifetime-credit-label": "lifetime +",
    "balance-balance-detail-overview-lifetime-debit-label": "lifetime -",
    "balance-balance-detail-overview-currency-label": "ccy",
    "balance-balance-detail-overview-persons-label": "persons",
    "balance-balance-detail-overview-active-from": "active from",
    "balance-balance-detail-overview-active-to": "active to",
    "balance-balance-detail-overview-capabilities-label": "capabilities",
    "balance-balance-detail-overview-capabilities-allowCredit-label": "credit",
    "balance-balance-detail-overview-capabilities-allowDebit-label": "debit",
    "balance-balance-detail-overview-capabilities-allowDebitPartial-label": "debit partial",
    "balance-balance-detail-overview-merchantNote-label": "notes",
    "balance-balance-detail-overview-ruleLimits-label": "limits",
    "balance-balance-detail-overview-ruleLimits-noLimits": "no limits",
    "balance-balance-detail-overview-ruleLimits-table-col-type": "type",
    "balance-balance-detail-overview-ruleLimits-table-col-period": "period",
    "balance-balance-detail-overview-ruleLimits-table-col-amountMin": "min",
    "balance-balance-detail-overview-ruleLimits-table-col-amountMax": "max",
    "balance-balance-detail-overview-ruleLimits-table-col-setter": "setter",
    "balance-balance-detail-overview-ruleLimits-table-row-setter-merchant": "merchant",
    "balance-balance-detail-overview-ruleLimits-table-row-setter-customer": "customer",
    "balance-balance-detail-overview-ruleChannels-label": "channels",
    "balance-balance-detail-overview-ruleChannels-noChannels":
      "⚠️ no channels - debits possible only in admin",
    "balance-balance-detail-transactions-label": "transactions",

    "balance-balance-detail-dialog-status-title": "Change status",
    "balance-balance-detail-dialog-status-button-cancel-label": "cancel",
    "balance-balance-detail-dialog-status-button-save-label": "set",

    "balance-add-form-group-title": "Create balance group",
    "balance-add-form-single-title": "Create balance",
    "balance-add-form-group-description":
      "Balance groups are used to group balances together. Entitlements (Mobile Passes, Vouchers) are linked to balance groups. Customers are issued Entitlements, so they can spend from multiple balances at once.",
    "balance-add-form-single-description":
      "Entitlements (Mobile Passes, Vouchers) are linked to balance. Customers are issued Entitlements.",
    "balance-add-form-balances-group-label": "Balances",
    "balance-add-form-balances-group-item-label": "Balance",
    "balance-add-form-balances-group-add": "Balance",
    "balance-add-form-balances-group-caption": "Balances are ordered by debit priority",
    "balance-add-form-linkPerson-button-label": "Link Person",
    "balance-add-form-linkPerson-button-caption-group":
      "Select Person that will be able to spend from this Balance Group. This is typically your customer.",
    "balance-add-form-linkPerson-button-caption-single":
      "Select Person that will be able to spend from this Balance. This is typically your customer.",
    "balance-add-form-linkPerson-select-label": "Person",
    "balance-add-form-linkPerson-select-option-label-add": "New person",
    "balance-add-form-entitlements-heading": "Entitlements",
    "balance-add-form-entitlements-description":
      "Issue Entitlements that enable selected Person to spend from this Balance Group.",
    "balance-add-form-entitlements-online-label": "Online",
    "balance-add-form-entitlements-online-caption": "Online access restriction",
    "balance-add-form-entitlements-card-label": "Card",
    "balance-add-form-entitlements-card-caption": "Physical card",
    "balance-add-form-entitlements-pass-label": "Pass",
    "balance-add-form-entitlements-pass-caption": "Apple / Google Wallet pass",
    "balance-add-form-entitlements-voucher-label": "Voucher",
    "balance-add-form-entitlements-voucher-caption": "PDF voucher (coming soon)",
    "balance-add-form-entitlements-dispatch-label": "Send via e-mail",
    "balance-add-form-entitlements-dispatch-caption":
      "Send Entitlements to selected Person via e-mail after creation.",
    "balance-add-form-balance-heading": "Balance",
    "balance-add-form-balance-form-status-label": "Status",
    "balance-add-form-balance-form-status-option-active-label": "Active",
    "balance-add-form-balance-form-status-option-locked-label": "Locked",
    "balance-add-form-balance-form-status-option-suspended-label": "Suspended",
    "balance-add-form-balance-form-status-option-expired-label": "Expired",
    "balance-add-form-balance-form-status-option-closed-label": "Closed",
    "balance-add-form-balance-form-linkCompany-button-label": "Link Company",
    "balance-add-form-balance-form-linkCompany-button-caption":
      "For your reference, if this account is related to a Company (e.g. your B2B client)",
    "balance-add-form-balance-form-linkCompany-select-label": "Company",
    "balance-add-form-balance-form-linkCompany-select-option-label-add": "New company",
    "balance-add-form-balance-form-nameCustomer-label": "Name for customer",
    "balance-add-form-balance-form-nameCustomer-placeholder": "Work",
    "balance-add-form-balance-form-nameCustomer-caption":
      "For customer's reference - what they see in the app.",
    "balance-add-form-balance-form-nameMerchant-label": "Name",
    "balance-add-form-balance-form-nameMerchant-placeholder": "GoodCompany llc.",
    "balance-add-form-balance-form-nameMerchant-caption": "Internal, not visible to customer",
    "balance-add-form-balance-form-activeFrom-label": "Active from",
    "balance-add-form-balance-form-activeFrom-caption": "showing balance timezone",
    "balance-add-form-balance-form-activeTo-label": "Active to",
    "balance-add-form-balance-form-activeTo-caption": "showing balance timezone",

    "balance-add-form-balance-form-capabilities-heading": "Capabilities",
    "balance-add-form-balance-form-capabilities-allowCredit-label": "Crediting",
    "balance-add-form-balance-form-capabilities-allowCredit-caption": "Allow crediting of balance",
    "balance-add-form-balance-form-capabilities-allowDebit-label": "Debiting",
    "balance-add-form-balance-form-capabilities-allowDebit-caption": "Allow debiting of balance",
    "balance-add-form-balance-form-capabilities-allowDebitPartial-label": "Partial debiting",
    "balance-add-form-balance-form-capabilities-allowDebitPartial-caption":
      "Balance can be debited multiple times",

    "balance-add-form-balance-form-channels-label": "Channels",
    "balance-add-form-balance-form-channels-caption":
      "Select channels where this balance can be used",
    "balance-add-form-balance-form-limits-heading": "Limits",
    "balance-add-form-balance-form-limits-form-type-label": "Type",
    "balance-add-form-balance-form-limits-form-type-option-credit-label": "Credit",
    "balance-add-form-balance-form-limits-form-type-option-debit-label": "Debit",
    "balance-add-form-balance-form-limits-form-type-option-expiration_adjustment-label":
      "Expiration adjustment",
    "balance-add-form-balance-form-limits-form-period-label": "Period",
    "balance-add-form-balance-form-limits-form-period-option-lifetime-label": "Daily",
    "balance-add-form-balance-form-limits-form-period-option-year-label": "Year",
    "balance-add-form-balance-form-limits-form-period-option-month-label": "Month",
    "balance-add-form-balance-form-limits-form-period-option-week-label": "Week",
    "balance-add-form-balance-form-limits-form-period-option-day-label": "Day",
    "balance-add-form-balance-form-limits-form-period-option-hour-label": "Hour",
    "balance-add-form-balance-form-limits-form-period-option-balance_available-label":
      "Balance available",
    "balance-add-form-balance-form-limits-form-period-option-transaction-label": "Transaction",
    "balance-add-form-balance-form-limits-form-amountMin-label": "Min",
    "balance-add-form-balance-form-limits-form-amountMin-placeholder": "0",
    "balance-add-form-balance-form-limits-form-amountMax-label": "Max",
    "balance-add-form-balance-form-limits-form-amountMax-placeholder": "1000",
    "balance-add-form-balance-form-limits-form-actions-add-label": "add",
    "balance-add-form-balance-form-limits-form-actions-remove-label": "remove",
    "balance-add-form-balance-form-available-label": "Initial amount",
    "balance-add-form-balance-form-transactionDescription-label": "Transaction description",
    "balance-add-form-balance-form-transactionDescription-placeholder":
      "Yearly cafeteria allowance",
    "balance-add-form-balance-form-transactionDescription-caption":
      "Appears in transaction history",
    "balance-add-form-balance-form-note-label": "Note",
    "balance-add-form-balance-form-note-caption": "Internal, not visible to customers",
    "balance-add-form-balance-form-actions-save-label": "save",
    "balance-add-form-balance-form-actions-cancel-label": "cancel",

    "balance-add-form-actions-create-label": "create",
    "balance-add-form-actions-cancel-label": "cancel",

    "balance-transact-form-title": "Transaction",
    "balance-transact-form-type-debit": "debit",
    "balance-transact-form-type-credit": "credit",
    "balance-transact-form-amount-label": "amount",
    "balance-transact-form-amount-placeholder": "1000",
    "balance-transact-form-description-label": "description",
    "balance-transact-form-description-placeholder": "for order lala-123",
    "balance-transact-form-description-caption": "shared with customer",
    "balance-transact-form-note-merchant-label": "note",
    "balance-transact-form-note-merchant-placeholder": "agreed with CoolCorp on 2025-01-01",
    "balance-transact-form-note-merchant-caption": "internal, not visible to customers",
    "balance-transact-form-actions-cancel-label": "cancel",
    "balance-transact-form-actions-transact-debit-label": "debit",
    "balance-transact-form-actions-transact-credit-label": "credit",
    "balance-transact-form-actions-transactConfirm-label": "confirm",

    "booking-status-awaiting_payment": "awaiting payment",
    "booking-status-awaiting_confirmation": "awaiting confirmation",
    "booking-status-confirmed": "confirmed",
    "booking-status-in_progress": "in progress",
    "booking-status-completed": "completed",
    "booking-status-no_show": "no show",
    "booking-status-cancelled": "cancelled",

    "booking-resources-add-label": "resource",
    "booking-resources-table-col-name": "name",
    "booking-resources-table-col-type": "type",
    "booking-resources-table-col-timezone": "timezone",

    "booking-resources-add-form-field-name-label": "name",
    "booking-resources-add-form-field-name-caption":
      "room or person name where or whom with the service is fulfilled. for internal reference, not visible to customers.",
    "booking-resources-add-form-field-type-label": "type",
    "booking-resources-add-form-field-timezone-label": "timezone",
    "booking-resources-add-form-action-save-label": "save",
    "booking-resources-add-form-action-cancel-label": "cancel",

    "booking-services-add-button-label": "service",
    "booking-services-table-col-name": "name",
    "booking-services-table-col-duration": "duration (min)",
    "booking-services-table-col-paid": "paid",
    "booking-services-table-col-description": "description",

    "booking-services-add-form-field-name-label": "name",
    "booking-services-add-form-field-name-caption":
      "for internal reference, not visible to customers",
    "booking-services-add-form-field-description-label": "description",
    "booking-services-add-form-field-description-caption":
      "for internal reference, not visible to customers",
    "booking-services-add-form-field-duration-label": "duration (min)",
    "booking-services-add-form-field-duration-caption":
      "used for prefilling slot length when planning",
    "booking-services-add-form-field-paid-label": "paid",
    "booking-services-add-form-action-save-label": "save",
    "booking-services-add-form-action-cancel-label": "cancel",

    "booking-slots-add-button-label": "slot",
    "booking-slots-table-col-date": "date",
    "booking-slots-table-col-start": "start",
    "booking-slots-table-col-end": "end",
    "booking-slots-table-col-capacity": "capacity",
    "booking-slots-table-col-remaining": "remaining",
    "booking-slots-table-col-reservations": "reservations",
    "booking-slots-table-col-capacity-limited-by": "capacity limited by",
    "booking-slots-table-col-resource": "resource",
    "booking-slots-table-col-services": "services",
    "booking-slots-table-col-capacity-limited-by-option-count": "count",
    "booking-slots-table-col-capacity-limited-by-option-minutes": "minutes",

    "booking-slots-form-field-has-reservations-caption":
      "Slot has reservations -> only total capacity can be modified.",
    "booking-slots-form-field-resource-label": "resource",
    "booking-slots-form-field-start-label": "start",
    "booking-slots-form-field-start-caption": "showing resource timezone",
    "booking-slots-form-field-end-label": "end",
    "booking-slots-form-field-end-caption": "showing resource timezone",
    "booking-slots-form-field-capacity-limited-by-label": "capacity limited by",
    "booking-slots-form-field-capacity-limited-by-option-count": "count",
    "booking-slots-form-field-capacity-limited-by-option-minutes": "minutes",
    "booking-slots-form-field-services-label": "services",
    "booking-slots-form-field-services-caption": "services that can allocate this slot",
    "booking-slots-form-field-capacity-label": "capacity",
    "booking-slots-form-action-save-label": "save",
    "booking-slots-form-action-cancel-label": "cancel",

    "booking-reservations-add-button-label": "reservation",
    "booking-reservations-send-reminder-button-label": "send reminder",
    "booking-reservations-send-reminder-button-label-secondary": "confirm",
    "booking-reservations-change-status-button-label": "change status",
    "booking-reservations-change-status-dialog-title": "change status",
    "booking-reservations-change-status-dialog-button-label": "change",
    "booking-reservations-table-col-name": "name",
    "booking-reservations-table-col-code": "code",
    "booking-reservations-table-col-capacity": "capacity",
    "booking-reservations-table-col-status": "status",
    "booking-reservations-table-col-service": "service",
    "booking-reservations-table-col-date": "date",
    "booking-reservations-table-col-slot": "slot",
    "booking-reservations-table-col-email": "e-mail",
    "booking-reservations-table-col-phone": "phone",
    "booking-reservations-table-col-customer-note": "customer note",
    "booking-reservations-table-col-extra": "extra",

    "booking-reservations-form-field-service-label": "service",
    "booking-reservations-form-field-slot-label": "slot",
    "booking-reservations-form-field-status-label": "status",
    "booking-reservations-form-field-capacity-label": "capacity",
    "booking-reservations-form-field-customer-name-label": "customer name",
    "booking-reservations-form-field-customer-email-label": "e-mail",
    "booking-reservations-form-field-customer-phone-label": "phone",
    "booking-reservations-form-field-confirmed-by-vendor-label": "confirmed by vendor",
    "booking-reservations-form-field-confirmed-by-vendor-caption":
      "reservation is considered confirmed from your side",
    "booking-reservations-form-field-confirmed-by-customer-label": "confirmed by customer",
    "booking-reservations-form-field-confirmed-by-customer-caption":
      "Reservation is considered confirmed from customer's side. When bought though e-shop, this is after payment.",
    "booking-reservations-form-field-customer-note-label": "customer note",
    "booking-reservations-form-field-customer-note-caption": "customer's requests",
    "booking-reservations-form-field-internal-note-label": "internal note",
    "booking-reservations-form-field-internal-note-caption": "not visible to customer",
    "booking-reservations-form-field-preferred-language-label": "preferred language",
    "booking-reservations-form-field-all-guests-are-adults-label": "all guests are adults",
    "booking-reservations-form-action-save-label": "save",
    "booking-reservations-form-action-cancel-label": "cancel",

    "entitlement-entitlements-table-col-code": "code",
    "entitlement-entitlements-table-col-balance": "balance",
    "entitlement-entitlements-table-col-status": "status",
    "entitlement-entitlements-table-col-available": "available",
    "entitlement-entitlements-table-col-currency": "CCY",
    "entitlement-entitlements-table-col-person": "person",
    "entitlement-entitlements-table-col-type": "type",
    "entitlement-entitlements-table-col-validFrom": "valid from",
    "entitlement-entitlements-table-col-validTo": "valid to",
    "entitlement-entitlements-table-col-usedAt": "used at",
    "entitlement-entitlements-table-col-createdAt": "created at",
    "entitlement-entitlements-col-value-status-active": "active",
    "entitlement-entitlements-col-value-status-locked": "locked",
    "entitlement-entitlements-col-value-status-suspended": "suspended",
    "entitlement-entitlements-col-value-status-expired": "expired",
    "entitlement-entitlements-col-value-status-closed": "closed",
    "entitlement-entitlements-type-card": "card",
    "entitlement-entitlements-type-pass": "pass",
    "entitlement-entitlements-type-voucher": "voucher",

    "address-street": "Street",
    "address-number": "Building Number",
    "address-area": "Area",
    "address-city": "City",
    "address-zip": "ZIP code",
    "address-region": "Region",
    "address-country": "Country",
    "commerce-invoice-disclaimer": "Invoice disclaimer",

    // if you change this, change the client translation file
    "cart-main-title": "Cart",
    "cart-header-backToShop": "Continue shoppping",
    "cart-itemList-empty": "Nothing here. 😔 Pick something 🙃",
    "cart-promoCode-title": "Promo code",
    "cart-promoCode-insert": "Use promo code",
    "cart-promoCode-field-promoCode-label": "Do you have a promo code?",
    "cart-promoCode-field-promoCode-placeholder":
      "Magic key, that makes you save while spending. 😜",
    "cart-promoCode-field-promoCode-helpText": "ABRACADABRA",
    "cart-delivery-title": "Delivery",
    "cart-delivery-branch-title": "Branch",
    "cart-delivery-undeliverableItemsCombination":
      "This product combination can't be delivered. Please order separately.",
    "cart-delivery-deliveryOptions": "Delivery options",
    "cart-customerInfo-title": "My info",
    "cart-customerInfo-actionButtons-addBillingInfo": "Enter billing info",
    "cart-customerInfo-fields-customerEmail-label": "E-mail",
    "cart-customerInfo-fields-customerEmail-placeholder": "john@smith.com",
    "cart-customerInfo-fields-customerEmail-helpText":
      "We need your e-mail to inform you about your order state.",
    "cart-customerInfo-fields-customerName-label": "Name and surname",
    "cart-customerInfo-fields-customerName-placeholder": "John Smith",
    "cart-customerInfo-fields-customerName-helpText": "",
    "cart-customerInfo-fields-customerPhone-label": "Phone",
    "cart-customerInfo-fields-customerPhone-placeholder": "123 456 789",
    "cart-customerInfo-fields-customerPhone-helpText":
      "We need your phone in order to contact you promply in case of an unexpected event.",
    "cart-billingInfo-title": "Invoicing",
    "cart-billingInfo-actionButtons-fillManually": "Fill manually",
    "cart-billingInfo-actionButtons-companyAddress": "Invoice company",
    "cart-billingInfo-fields-billingAddress-label": "Address",
    "cart-billingInfo-fields-billingAddress-placeholder": "Main st. 1, Prague",
    "cart-billingInfo-fields-billingAddress-helpText": "",
    "cart-billingInfo-fields-billingAddressStreet-label": "Street",
    "cart-billingInfo-fields-billingAddressStreet-placeholder": "Main st. 1, Prague",
    "cart-billingInfo-fields-billingAddressStreet-helpText": "",
    "cart-billingInfo-fields-billingAddressCity-label": "City",
    "cart-billingInfo-fields-billingAddressCity-placeholder": "Prague",
    "cart-billingInfo-fields-billingAddressCity-helpText": "",
    "cart-billingInfo-fields-billingAddressPostal-label": "Postal",
    "cart-billingInfo-fields-billingAddressPostal-placeholder": "110 00",
    "cart-billingInfo-fields-billingAddressPostal-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyICO-label": "Reg. no.",
    "cart-billingInfo-fields-billingAddressCompanyICO-placeholder": "123 45 678",
    "cart-billingInfo-fields-billingAddressCompanyICO-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyName-label": "Company",
    "cart-billingInfo-fields-billingAddressCompanyName-placeholder": "Company s.r.o.",
    "cart-billingInfo-fields-billingAddressCompanyName-helpText": "",
    "cart-billingInfo-fields-invoiceNote-label": "Invoice note",
    "cart-billingInfo-fields-invoiceNote-placeholder": "Gift for the boss",
    "cart-billingInfo-fields-invoiceNote-helpText": "",
    "cart-deliveryInfo-title": "Delivery",
    "cart-deliveryInfo-actionButtons-fillManually": "Fill manually",
    "cart-deliveryInfo-actionButtons-companyAddress": "Deliver to a business",
    "cart-deliveryInfo-actionButtons-otherBillingInfo": "Other billing info",
    "cart-deliveryInfo-actionButtons-addBillingInfo": "Add billing info",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-true": "for me",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-false": "as a gift",
    "cart-deliveryInfo-fields-recipientName-label": "Name and surname",
    "cart-deliveryInfo-fields-recipientName-placeholder": "John Smith",
    "cart-deliveryInfo-fields-recipientName-helpText": "",
    "cart-deliveryInfo-fields-recipientPhone-label": "Phone",
    "cart-deliveryInfo-fields-recipientPhone-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-recipientPhone-helpText": "We need a phone for the delivery courier.",
    "cart-deliveryInfo-fields-deliveryAddress-label": "Adresa",
    "cart-deliveryInfo-fields-deliveryAddress-placeholder": "Main st. 1, Prague",
    "cart-deliveryInfo-fields-deliveryAddress-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressStreet-label": "Street",
    "cart-deliveryInfo-fields-deliveryAddressStreet-placeholder": "Main st. 1, Prague",
    "cart-deliveryInfo-fields-deliveryAddressStreet-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCity-label": "City",
    "cart-deliveryInfo-fields-deliveryAddressCity-placeholder": "Prague",
    "cart-deliveryInfo-fields-deliveryAddressCity-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressPostal-label": "Postal",
    "cart-deliveryInfo-fields-deliveryAddressPostal-placeholder": "110 00",
    "cart-deliveryInfo-fields-deliveryAddressPostal-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-label": "Reg. no.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-label": "Company",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-placeholder": "Company s.r.o.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-helpText": "",
    "cart-deliveryInfo-fields-deliveryNote-label": "Delivery note",
    "cart-deliveryInfo-fields-deliveryNote-placeholder": "",
    "cart-deliveryInfo-fields-deliveryNote-helpText": "",
    "cart-deliveryInfo-fields-recipientNote-label": "Recipient note",
    "cart-deliveryInfo-fields-recipientNote-placeholder": "",
    "cart-deliveryInfo-fields-recipientNote-helpText": "",
    "cart-deliveryInfo-fields-recipientInQuarantine-labelTrue": "Contactless delivery",
    "cart-deliverySlots-noMoreDaysAvailable": "no other days<br>planned yet 😔",
    "cart-deliverySlots-noMoreSlotsAvailable": "No more free<br>slots available 😔",
    "cart-note-title": "Note",
    "cart-note-insert": "Add note",
    "cart-note-fields-note-label": "Anything else on your mind?",
    "cart-note-fields-note-placeholder": "Load!",
    "cart-note-fields-note-helpText":
      "We will try to give you the moon! We're still people, though 😜",
    "cart-payment-title": "Payment",
    "cart-stripeWallet-otherMethod": "Other method",
    "cart-orderButton-terms-pre": "By payment I agree to",
    "cart-orderButton-terms-link": "terms",
    "cart-confirmation-orderNumber-heading": "order",
    "cart-confirmation-email-heading": "confirmation",
    "cart-confirmation-merchantNote": "Looking forward to next time!",
    "cart-confirmation-continueShoppingBtn": "something else? 😜",

    // confirmation texts - these are configured in delivery methods
    "cart-confirmation-method-recipientSameAsCustomer-title": "Thank you",
    "cart-confirmation-method-recipientSameAsCustomer-paragraph": "Your order is being processed.",
    "cart-confirmation-method-recipientNotSameAsCustomer-title": "Thank you",
    "cart-confirmation-method-recipientNotSameAsCustomer-paragraph":
      "Your order is being processed and soon will be dispatched to the recipient.",

    // labels for override form
    "override-section--postfix-label": "label",
    "override-section--postfix-placeholder": "placeholder",
    "override-section--postfix-helpText": "help",
    "override-section-cart-main": "main",
    "override-section-cart-main-key-cart-main-title": "cart title",
    "override-section-cart-header": "header",
    "override-section-cart-header-key-cart-header-backToShop": "close cart button",
    "override-section-cart-itemList": "items",
    "override-section-cart-itemList-key-cart-itemList-empty": "empty cart",
    "override-section-cart-promoCode": "promo code",
    "override-section-cart-promoCode-key-cart-promoCode-title": "promo code title",
    "override-section-cart-promoCode-key-cart-promoCode-field-promoCode": "promo code",
    "override-section-cart-delivery": "delivery",
    "override-section-cart-delivery-key-cart-delivery-title": "delivery title",
    "override-section-cart-delivery-key-cart-delivery-branch-title": "branch title",
    "override-section-cart-delivery-key-cart-delivery-undeliverableItemsCombination":
      "undeliveable items combination text",
    "override-section-cart-delivery-key-cart-delivery-deliveryOptions": "delivery options title",
    "override-section-cart-customerInfo": "customer",
    "override-section-cart-customerInfo-key-cart-customerInfo-title": "customer title",
    "override-section-cart-customerInfo-key-cart-customerInfo-actionButtons-addBillingInfo":
      "add billing info button",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerEmail": "e-mail",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerName": "name",
    "override-section-cart-customerInfo-key-cart-customerInfo-fields-customerPhone": "phone",
    "override-section-cart-billingInfo": "billing info",
    "override-section-cart-billingInfo-key-cart-billingInfo-title": "billing info title",
    "override-section-cart-billingInfo-key-cart-billingInfo-actionButtons-fillManually":
      "fill manually button",
    "override-section-cart-billingInfo-key-cart-billingInfo-actionButtons-companyAddress":
      "company address button",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddress": "address",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressStreet": "street",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCity": "city",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressPostal": "postal",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCompanyICO":
      "Reg. no.",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-billingAddressCompanyName":
      "Company",
    "override-section-cart-billingInfo-key-cart-billingInfo-fields-invoiceNote": "invoice note",
    "override-section-cart-deliveryInfo": "delivery info",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-title": "delivery info title",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-fillManually":
      "fill manually button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-companyAddress":
      "company address button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-otherBillingInfo":
      "other billing info button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-actionButtons-addBillingInfo":
      "add billing info button",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-recipientSameAsCustomerOptions-true":
      "recipient is same as buyer",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-recipientSameAsCustomerOptions-false":
      "recipient is different from buyer",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientName": "name",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientPhone": "phone",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddress": "address",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressStreet":
      "street",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCity": "city",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressPostal":
      "postal",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCompanyICO":
      "reg. no.",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryAddressCompanyName":
      "company",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-deliveryNote": "delivery note",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientNote":
      "recipient note",
    "override-section-cart-deliveryInfo-key-cart-deliveryInfo-fields-recipientInQuarantine-labelTrue":
      "recipient in quarantine",
    "override-section-cart-deliverySlots": "delivery slots",
    "override-section-cart-deliverySlots-key-cart-deliverySlots-noMoreDaysAvailable":
      "no more days available",
    "override-section-cart-deliverySlots-key-cart-deliverySlots-noMoreSlotsAvailable":
      "no more slots available",
    "override-section-cart-note": "note",
    "override-section-cart-note-key-cart-note-title": "note title",
    "override-section-cart-note-key-cart-note-fields-note": "note",
    "override-section-cart-payment": "payment",
    "override-section-cart-payment-key-cart-payment-title": "payment title",
    "override-section-cart-confirmation": "confirmation",
    "override-section-cart-confirmation-key-cart-confirmation-orderNumber-heading":
      "order number heading",
    "override-section-cart-confirmation-key-cart-confirmation-email-heading": "e-mail heading",
    "override-section-cart-confirmation-key-cart-confirmation-merchantNote": "merchant note",
    "override-section-cart-confirmation-key-cart-confirmation-continueShoppingBtn":
      "continue shopping button",
    "override-section-cart-orderButton": "order",
    "override-section-cart-orderButton-key-cart-orderButton-terms-pre": "terms - perex",
    "override-section-cart-orderButton-key-cart-orderButton-terms-link": "terms - link",

    "product-field-title": "Title",
    "product-field-description": "Description",
    "product-field-google-product-category": "Product Category ID",
    "product-tags": "Tags",
    "product-sku": "SKU",
    "product-options": "Options",
    "product-variants": "Variants",
    "product-dynamic-sections": "Dynamic sections",
    "product-customer-form": "Customer form",
    "product-kind": "Kind",
    "product-sub-kind": "Subkind",
    "product-images": "Images",
    "product-gift-card-images": "Images for gift card",
    "product-gift-card-settings": "Gift Card settings",
    "product-booking-settings": "Booking settings",
    "product-quantity": "Stock & visibility",
    "product-quantity-stock": "Stock",
    "product-quantity-available": "available",
    "product-quantity-blocked": "blocked",
    "product-quantity-reserved": "reserved",
    "product-quantity-when-sold-out": "when sold out",
    "product-shipping": "Shipping",
    "product-shipping-dimensions": "Dimensions",
    "product-shipping-available-deliveries": "Available delivery",
    "product-channels": "Channels",
    "item-labels": "attributes",
    "item-labels-adding-name": "name",
    "item-labels-group": "attributes group",
    "item-labels-add": "add attribute",
    "item-vod-videos": "videos on demand",
    "item-vod-free-preview": "free preview",
    "item-vod-seconds": "seconds",
    "item-vod-or": "or",
    "item-vod-separate-video": "add separate video",
    "item-vod-remove": "remove",
    "item-vod-confirm": "confirm",
    "item-vod-processing": "processing",
    "item-vod-notified-via-email": "When processing is done, you will be notified via e-mail.",
    "system-settings": "System settings",
    "other-settings": "Other",
    "channel-online": "Online",
    "product-files": "Files",
    "add-a-new": "Add another:",
    "button-add-variant": "Add variant",
    "button-add-product": "Product",
    "button-add-option": "+ Add option",
    "button-add-attribute": "+ Add atrribute",
    "product-field-type": "Type",
    "product-field-name": "Name",
    "question-product-opt-delete": "Do you really want to delete this option?",
    "question-product-product-delete": "Do you really want to delete this product?",
    "question-product-product-variants-delete":
      "Do you really want to delete this product and its variants?",
    "product-enable-stock-track": "track stock",
    "product-min-order": "Minimal order amount:",
    "product-when-sold-out": "When sold out:",
    "product-on-sold-hide": "hide",
    "product-on-sold-disable": "restrict",
    "product-on-sold-preorder": "preorder",
    "product-show-as-preorder": "preorder",
    "product-show-as": "Visibility",
    "product-show-as-hide": "hidden",
    "product-show-as-unavailable": "unavailable",
    "product-show-as-available": "available",
    "product-show-as-auto": "by stock",
    "product-show-stock-counts": "Show stock counts",
    "product-pricing": "Pricing",
    "product-enable-reservation": "Allow reservation",
    "product-reservation-timeout": "Time to keep product in cart for order",
    "commerce-pricing-base": "Base",
    "commerce-pricing-tax": "Tax",
    "commerce-pricing-final": "Final",
    "commerce-pricing-purchased": "Purchased",
    "commerce-pricing-commission": "Commission",
    "commerce-pricing-profit": "Profit",
    "commerce-package-dimensions-length": "l",
    "commerce-package-dimensions-width": "w",
    "commerce-package-dimensions-height": "h",
    "shipping-weight": "Weight [kg]",
    "shipping-dimensions": "Dimensions [cm]",
    "question-product-file-attr-delete": "Do you really want to delete this attribute?",
    "question-product-file-delete": "Do you really want to delete this file?",

    "signup-email": "What is your email?",
    "signup-site-domain": "What will be your site domain?",
    "signup-done": "... check your email inbox.",

    "products-prepared": "Ready -> Sent",

    "button-cancel": "Cancel",
    "button-confirm": "Confirm",
    "button-remove": "Remove",
    "button-save-draft": "Save draft",
    "button-publish": "Publish",
    "button-publish-all": "Publish all",
    "button-save": "Save",
    "button-add": "Add",
    "button-add-file": "Add file",
    "button-change-file": "Change file",
    "button-add-font": "Add font",
    "button-delete": "Delete",
    "button-all": "All",
    "button-signup": "Signup",

    "order-incomming-new": "New order!",
    // "order-incomming-new" : "New order! [%0]",
    "orders-customer": "Customer",
    "orders-products": "Products",
    "orders-shipping": "Shipping",
    "order-payment-not-paid": "Not paid yet",
    "order-payment-paid": "Paid at:",
    "order-customer-note": "Customer note",
    "order-customer-notify": "Notify customer",
    "order-note-add": "Add note",
    "order-pay-products": "Products",
    "order-pay-delivery": "Shipping",
    "order-pay-payment": "Payment",
    "order-pay-discount": "Discount",
    "order-pay-total": "Customer pays",
    "order-pay-method-card-online": "Card online",
    "order-pay-method-card-site": "Card in a shop",
    "order-pay-method-card-deliv": "Card at the delivery",
    "order-pay-method-cash-site": "Cash in a shop",
    "order-pay-method-cash-deliv": "Cash at the delivery",
    "order-pay-method-bank-wire": "bank wire",
    "order-pay-method-paypal": "Paypal",
    "order-shipping-method-branch": "Branch pickup",
    "order-shipping-method-branch-pob1-onspot": "Branch pickup",
    "order-shipping-method-messanger": "Kurýr",
    "order-shipping-method-ppl": "PPL",
    "order-shipping-method-ups": "UPS",
    "order-shipping-method-zasilkovna": "Zásilkovna",
    "order-shipping-method-cpost": "Czech post",
    "order-shipping-method-cpost-ob": "Czech post - business package",
    "order-shipping-method-custom": "Custom",
    "order-shipping-method-popup": "Pop-up",
    "order-shipping-method-popup-praktika1": "Pop-up Praktika Letná",
    "order-shipping-method-no-delivery": "No delivery",

    oa_state_unpaid: "Unpaid",
    oa_state_paid: "Paid",
    oa_state_preparing: "Preparing products",
    oa_state_printing: "Printing labels",
    oa_state_packing: "Packing",
    oa_state_dispatching: "Dispatching",
    oa_state_sent: "Sent",
    oa_state_pickup: "Forpickup",
    oa_state_refunding: "Refunding",
    oa_state_cancelled: "Cancelled",
    oa_state_archived: "Archived",
    oa_go_paid: "Was paid",
    oa_go_prepare: "Prepare products",
    oa_go_print: "Print Label",
    oa_go_pack: "Pack goods",
    oa_go_cancel: "Cancel",
    oa_go_dispatch: "Dispatch",
    oa_go_send: "Send",
    oa_go_pickup: "Ready to pickup",
    oa_go_refund: "Refund",
    oa_go_archive: "Archive",
    oa_go_open: "Open",

    "user-action-user-looked": "Order opened",
    "user-action-moving-state": "State changed",
    "user-action-info-note-checked": "Customer note checked",
    "user-action-info-note-unchecked": "Customer note unchecked",
    "user-action-courier-note-checked": "Courier note checked",
    "user-action-courier-note-unchecked": "Courier note unchecked",
    "user-action-recipient-note-checked": "Recipient note checked",
    "user-action-recipient-note-unchecked": "Recipient note unchecked",
    "user-action-contactless-checked": "Contactless delivery checked",
    "user-action-contactless-unchecked": "Contactless delivery unchecked",
    "user-action-item-checked": "Item checked",
    "user-action-item-unchecked": "Item unchecked",
    "user-action-updated-private-note": "Private note updated",
    "user-action-deleted-private-note": "Private note deleted",
    "user-action-invoice-downloaded": "Invoice downloaded",
    "user-action-invoice-sent": "Invoice manually sent to customer",
    "user-action-refundSlip-downloaded": "Refund slip downloaded",
    "cart-created": "Cart created",
    "cart-item-added": "Item added",
    "cart-item-updated": "Item updated",
    "cart-item-removed": "Item removed",
    "order-created": "Order created",
    "stripe-payment-created": "Payment started",
    "stripe-payment-requires-action": "Payment requires action",
    "stripe-payment-succeeded": "Payment succeeded",
    "stripe-payment-failed": "Payment failed",
    "resend-sent": "E-mail sent",
    "resend-delivery_delayed": "E-mail delayed",
    "resend-bounced": "E-mail bounced",
    "resend-delivered": "E-mail delivered",
    "resend-opened": "E-mail opened",
    "resend-clicked": "Link in e-mail opened",
    "resend-complained": "E-mail reported as spam",
    "sendgrid-processed": "E-mail processed",
    "sendgrid-deferred": "E-mail deferred",
    "sendgrid-delivered": "E-mail delivered",
    "sendgrid-open": "E-mail opened",
    "sendgrid-click": "Link in e-mail opened",
    "sendgrid-bounce": "E-mail bounced",
    "sendgrid-dropped": "E-mail dropped",
    "sendgrid-blocked": "E-mail blocked",
    "sendgrid-spamreport": "E-mail marked as spam",
    "sendNotification-sent": "E-mail sent",
    "sendNotification-error": "E-mail not sent",
    "invoice-download": "invoice download by customer",
    "digital-product-download": "digital product download by customer",
    "giftCard-download-by-customer": "gift card download by customer",
    "giftCard-download-by-recipient": "gift card download by recipient",

    "stripe-error-card-expired": "Card expired. Please use another card.",
    "stripe-decline-card-expired": "Card expired. Please use another card.",
    "stripe-decline-auth-required": "Payment needs authorization. Please try again.",
    "stripe-decline-try-again": "Please try again or contact your bank.",
    "stripe-decline-contact-bank": "Please contact your bank.",
    "stripe-decline-duplicate": "Duplicate payment.",
    "stripe-decline-incorrect-number": "Incorrect card number",
    "stripe-decline-incorrect-cvc": "Incorrect CVC",
    "stripe-decline-incorrect-expiry": "Incorrect expiry",
    "stripe-decline-insufficient-funds": "Please check your account balance.",
    "stripe-decline-test-card": "Do not use test card.",
    "stripe-decline-test-mode-live-card": "Live card used in test mode",

    "stripe-requires-action-3ds2": "3D secure verification",

    "billing-address": "Billing address",
    "delivery-address": "Delivery details",

    "block-config-name": "Block name",
    "block-config-tag": "Tag",
    "block-config-shared": "Shared",
    "block-config-region": "Region",
    "block-config-viewports": "Viewports",

    "forms-field-id": "ID",
    "forms-field-type": "Field type",
    "target-window-same": "Same window",
    "target-window-new": "New window",

    "form-font-name": "Font name",
    "form-font-weight": "Font weight",
    "form-font-url": "Font URL",
    "form-font-source-file": "Font file",

    "page-create-new": "New page",
    "page-create-new-abort": "Abort adding new page",
    "page-create-new-info": "Now select parental page of the new one",
    "page-new-name": "New page",
    "page-new-slug": "newpage",
    "page-new-created": "New page created",
    "page-new-not-created": "The page was not created.",
    "shared-blocks-label": "Shared",
    "private-blocks-label": "Private",

    "settings-css-header": "Styles",
    "settings-scripts-header": "Scripts",
    "settings-config-header": "Styles config",
    "settings-fonts-header": "Fonts",

    "template-editor": "Templates",
    "theme-label-fonts": "Fonts",
    "theme-label-scripts": "Script files",

    "field-listing-source": "Source",
    "field-listing-root-page": "Root page",

    "question-publish-all": "Do you relly want to publish all?",
    "message-info-site-published": "Site has been published.",
    "error-login-nonce-request":
      "Login failed. Please, repeat the process one more time, and in case it didn't work either, please do not hesitate to contact us.",
    "error-email-invlaid": "E-mail address is not valid!",
    "error-email-not-found": "E-mail address was not found!",
    "error-sitemName-already-used": "This site name is already used!",
    "error-email-already-used": "This E-mail address is already used!",
    "error-both-already-used": "Both values are already used!",
    "error-invlaid-values": "The form is not filled properly!",

    // Profile
    "profile-change-password": "Change password",
    "profile-passkeys": "Passkeys",
    "profile-notifications": "Notifikace",
    "profile-admin-lang": "Admin language",

    // View titles
    "view-title-apps": "apps",
    "view-title-sites": "sites",
    "view-title-site-settings": "site settings",
    "view-title-site-settings-sections": "sections",
    "view-title-site-settings-domains": "domains",
    "view-title-pages": "pages",
    "view-title-page-settings": "page settings",
    "view-title-balances": "balances",
    "view-title-balances-detail": "balance",
    "view-title-balances-transact": "balance transaction",
    "view-title-identity-companies": "companies",
    "view-title-identity-persons": "persons",
    "view-title-booking": "booking",
    "view-title-booking-back": "back",
    "view-title-booking-reservations": "reservations",
    "view-title-booking-slots": "slots",
    "view-title-booking-services": "services",
    "view-title-booking-resources": "resources",
    "view-title-commerce": "commerce",
    "view-title-commerce-carts": "carts",
    "view-title-commerce-orders": "orders",
    "view-title-commerce-orders-items": "order items",
    "view-title-commerce-inventory": "inventory",
    "view-title-commerce-inventory-product": "product",
    "view-title-commerce-inventory-products": "products",
    "view-title-commerce-settings": "commerce settings",
    "view-title-commerce-order-flow": "order flow",
    "view-title-commerce-entitlements": "entitlements",
    "view-title-commerce-promocodes": "promo codes",
    "view-title-commerce-giftcards": "gift cards",
    "view-title-commerce-giftcard-detail": "gift card",
    "view-title-commerce-giftcard-validation": "gift card validation",
    "view-title-commerce-delivery-planning": "delivery planning",
    "view-title-commerce-fee-reports": "fee reports",
    "view-title-commerce-fee-billing": "billing",
    "view-title-profile": "my profile",
    "view-title-profile-changepassword": "password change",
    "view-title-profile-passkeys": "Passkeys",
    "view-title-profile-notifications": "Notifikace",
    "view-title-commerce-promocode-detail": "code detail",
    "view-title-commerce-promocode-import": "code import",
    "view-title-commerce-settings-general": "general",
    "view-title-commerce-settings-invoicing": "invoicing",
    "view-title-commerce-settings-payment": "payment",
    "view-title-commerce-settings-delivery": "delivery",
    "view-title-commerce-settings-delivery-method": "method",
    "view-title-commerce-settings-cart": "cart",
    "view-title-commerce-settings-channels": "channels",
    "view-title-commerce-export": "export",
    "view-title-platform": "platform",
    "view-title-platform-wizard": "wizard",
    "view-title-platform-clients": "clients",
    "view-title-platform-clients-detail": "client",
    "view-title-platform-sites": "sites",
    "view-title-platform-sites-detail": "site",
    "view-title-platform-users": "users",
    "view-title-platform-users-detail": "user",
    "view-title-platform-shops": "shops",
    "view-title-platform-shops-detail": "shop",
    "view-title-platform-events": "events",
    "view-title-platform-events-detail": "event",
    "view-title-platform-api-keys": "api keys",
    "view-title-platform-api-scopes": "api scopes",
    "view-title-platform-entitlements-pass-projects": "ent. pass projects",
    "view-title-platform-entitlements-pass-apple-pki": "ent. pass apple pki",
    "view-title-platform-system": "system",
    "view-title-platform-orgs": "orgs",
    "view-title-tools": "tools",
    "view-title-tools-pos-emulator": "POS emulator",

    "delivery-method-constraints-label": "Delivery constraints",
    "delivery-method-constraints-min-dispatch-time": "Dispatched after",
    "delivery-method-constraints-min-dispatch-time-since-order": "since order",
    "delivery-method-constraints-shipping-from": "Dispatched from",
    "delivery-method-constraints-shipping-to": "Dispatched till",
  },
}
