import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc} from "firebase/firestore"
import {useUserStore} from "./user"
import {useUserPermissionsStore} from "./userPermissions"

export const useUserRolesStore = defineStore("userRoles", () => {
  // stores
  const userStore = useUserStore()
  const userPermissionsStore = useUserPermissionsStore()

  // state
  const roles = ref({})
  const godMode = ref(false)
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const userRolesLoaded = computed(() => status.value == "loaded")
  const isPlatformAdmin = computed(() => roles.value?.global?.includes("platform_admin"))

  // actions
  async function bind(args) {
    const userId = userStore.userId || args.userId
    const docRef = doc(firestore, `users/${userId}/auth/roles`)
    let response = await this.attach("roles", docRef)
    userPermissionsStore.set()
    return response
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    roles,
    godMode,
    status,
    unsubscribe,

    // getters
    userRolesLoaded,
    isPlatformAdmin,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserRolesStore, import.meta.hot))
}
