import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  where,
  query,
  getDoc,
  getDocs,
  addDoc,
  writeBatch,
} from "firebase/firestore"
import {useShopStore} from "./shop"
import {useShopItemsStockStore} from "./shopItemsStock"
import {useShopItemsHoldsStore} from "./shopItemsHolds"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const useShopItemsStore = defineStore("shopItems", () => {
  // stores
  const shopStore = useShopStore()
  const shopItemsStockStore = useShopItemsStockStore()
  const shopItemsHoldsStore = useShopItemsHoldsStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const items = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemsLoaded = computed(() => status.value == "loaded")
  function getItemStock(args) {
    return computed(() => {
      const shopItemsStock = computed(() => shopItemsStockStore.stock)
      const shopItemsHolds = computed(() => shopItemsHoldsStore.holds)
      const itemId = args
      const item = shopItemsStock.value.find((p) => p._itemId == itemId)
      const stock = item ? item.count : 0
      const blocked = shopItemsHolds.value.filter(
        (p) => p._itemId == itemId && p.kind == "blocked"
      ).length
      const reserved = shopItemsHolds.value.filter(
        (p) => p._itemId == itemId && p.kind == "reserved"
      ).length
      return {
        stock,
        reserved,
        blocked,
        available: stock - reserved - blocked,
      }
    })
  }

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/products`)
    const q = query(collectionRef, where("meta.deleted", "==", false))
    return await this.attach("items", q)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const shopId = shopStore.shopId
    if (!args || !args.product || !args.product.sku) {
      args = {product: {sku: ""}}
    }
    try {
      // First verify and create the Item Model
      let modelId
      if (args.product.modelId == null || args.product.modelId === undefined) {
        // Create new model
        try {
          const modelCollectionRef = collection(firestore, `shops/${shopId}/models`)
          const modelData = {
            itemFields: [],
          }
          let model = await addDoc(modelCollectionRef, modelData)
          modelId = model.id
        } catch (err) {
          console.error("create new model error: ", err)
          return null
        }
      } else {
        // Try to find the model
        try {
          const modelDoc = doc(firestore, `shops/${shopId}/models/${args.product.modelId}`)
          let model = await getDoc(modelDoc)
          if (model.data()) {
            modelId = args.product.modelId
          }
          // console.log('Using existing model', modelId)
        } catch (err) {
          console.error("find model error: ", err)
          return null
        }
      }

      // get fee categories
      try {
        const feeCategoriesCollectionRef = collection(firestore, `shops/${shopId}/feeCategories`)
        const feeCategories = await getDocs(feeCategoriesCollectionRef)
        var feeCategory = ""
        if (feeCategories.docs && feeCategories.docs.length == 1)
          feeCategory = feeCategories.docs[0].id
      } catch (err) {
        console.error("get fee categories error: ", err)
        return null
      }

      const itemsCollectionRef = collection(firestore, `shops/${shopId}/products`)
      let newProduct =
        args.product.parentId === undefined
          ? // The created product is the main product
            {
              modelId: modelId,
              parentId: "main",
              sku: args.product.sku,
              attribs: {},
              variants: [],
              desc: {
                title: {},
                description: {},
              },
              config: {
                customerForm: [],
                feeCategory,
                productKind: "physical",
                quantity: {
                  enableHold: false,
                  holdDuration: 0,
                  stock: {
                    available: false,
                    trackingEnabled: false,
                    whenUnavailable: "restrict",
                  },
                },
                showAs: "hide",
                shipping: {
                  maxDimension: {
                    d: 1,
                    h: 1,
                    w: 1,
                  },
                  maxWeight: 1,
                  methods: [],
                },
                settings: {},
              },
              price: [
                {
                  brutto: null,
                  netto: null,
                  vat: null,
                  vat_pct: shopStore.shop.payment.taxes[0],
                  model: "fixed",
                  currency: "CZK",
                },
              ],
              tags: [],
            }
          : // The created product is a variant
            args.product

      newProduct.meta = {
        created: serverTimestamp(),
        updated: null,
        deleted: false,
      }

      // create new product
      try {
        // console.log('Creating new product', newProduct)
        var addedItem = await addDoc(itemsCollectionRef, newProduct)
        // console.log('Commerce: Product has been created', addedItem.id)
      } catch (err) {
        console.error("create new product error: ", err)
        return null
      }

      // If some parent has new child
      if (args.product.parentId != undefined) {
        const parentItem = this.items.find((p) => p.id == args.product.parentId)
        let parentVariantIds = parentItem.variants
        parentVariantIds.push(addedItem.id)

        try {
          const parentId = args.product.parentId
          const parentItemDocRef = doc(firestore, `shops/${shopId}/products/${parentId}`)
          const parentData = {
            variants: parentVariantIds,
            "meta.updated": serverTimestamp(),
          }
          await updateDoc(parentItemDocRef, parentData)
        } catch (err) {
          console.error("update product error: ", err)
          return null
        }
      }

      return {
        id: addedItem.id,
      }
    } catch (err) {
      console.error(err)
      return null
    }
  }

  async function update(args) {
    console.log("shopItems update:", args)
    if (args.meta?.fieldId) formSaveStateStore.markSaving(args)

    const shopId = shopStore.shopId
    const itemId = args.itemId
    const docRef = doc(firestore, `shops/${shopId}/products/${itemId}`)
    const data = {
      ...args.data,
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    if (args.meta?.fieldId) formSaveStateStore.markSaved(args)
    return
  }

  async function remove(args) {
    const itemId = args
    const shopId = shopStore.shopId
    const item = this.items.find((i) => i.id == itemId)
    const variantIds = item.variants
    const itemHasVariants = variantIds.length > 1
    const itemIsVariant = item.parentId != "main"
    let docsToDelete = [itemId]

    // also delete variants
    if (itemHasVariants) docsToDelete = [...docsToDelete, ...item.variants]

    let batch = writeBatch(firestore)
    docsToDelete.forEach((id) => {
      const docRef = doc(firestore, `shops/${shopId}/products/${id}`)
      batch.delete(docRef)
    })

    await batch.commit()

    // update parent product variant Ids if only variant was removed
    if (itemIsVariant) {
      let parentItem = this.items.find((i) => i.id == item.parentId)
      if (parentItem) {
        const data = {
          itemId: item.parentId,
          data: {
            variants: parentItem.variants,
          },
        }
        await this.update(data)
      }
    }
  }

  return {
    // state
    items,
    status,
    unsubscribe,

    // getters
    shopItemsLoaded,
    getItemStock,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemsStore, import.meta.hot))
}
