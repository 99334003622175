import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, where, query, orderBy} from "firebase/firestore"
import {useShopStore} from "./shop"
import {useUserStore} from "./user"

export const useShopOrderFlowStatesStore = defineStore("shopOrderFlowStates", () => {
  // stores
  const shopStore = useShopStore()
  const userStore = useUserStore()

  // state
  const states = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopOrderFlowStatesLoaded = computed(() => status.value == "loaded")
  const visibleStates = computed(() => {
    let visibleStates = []

    // forEach so it is ordered properly
    visibleStatesCodes.value.forEach((s) => {
      let stateDetail = states.value.find((sd) => sd.code == s)
      if (stateDetail.visible) {
        visibleStates.push(stateDetail)
      }
    })
    return visibleStates
  })
  const visibleStatesCodes = computed(() => {
    let statesCodes = []
    // TODO: user should have config for each shop separately
    let userColumns = userStore.userConfig.orderColumns || false

    statesCodes = states.value.reduce((accumulator, item) => {
      if (
        item.visible &&
        (userColumns === false || (userColumns && userColumns.includes(item.code)))
      )
        accumulator.push(item.code)
      return accumulator
    }, [])

    return statesCodes
  })
  const statesCodes = computed(() => {
    let statesCodes = []
    statesCodes = states.value.reduce((accumulator, item) => {
      accumulator.push(item.code)
      return accumulator
    }, [])

    return statesCodes
  })

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/orderFlowStates`)
    const q = query(collectionRef, where("meta.deletedAt", "==", "no"), orderBy("order", "asc"))
    return await this.attach("states", q)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    states,
    status,
    unsubscribe,

    // getters
    shopOrderFlowStatesLoaded,
    visibleStates,
    visibleStatesCodes,
    statesCodes,

    // actions
    bind,
    unbind,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopOrderFlowStatesStore, import.meta.hot))
}
