import moment from "moment"
import {randomBytes} from "./utils/randomBytes.js"
import {generate} from "random-words"

export function getRelativeTime(date, currentTime) {
  if (date === undefined) return "-"

  let now = moment(currentTime)
  let from = moment(date)
  let diff = Math.abs(now.diff(from, "seconds")) // future dates support
  let isFuture = from.diff(now, "seconds") < 0
  let text = isFuture ? "- " : ""

  // console.log(diff)

  // more than a month
  if (diff >= 2419200)
    text += moment
      .utc(moment.duration(diff, "seconds").asMilliseconds())
      .format("M [M] D [d]")

  // more than a day
  if (diff >= 86400 && diff < 2419200)
    text += moment
      .utc(moment.duration(diff, "seconds").asMilliseconds())
      .format("D [d]")

  // more than an hour
  if (diff >= 3600 && diff < 86400)
    text += moment
      .utc(moment.duration(diff, "seconds").asMilliseconds())
      .format("H [h] mm [min]")

  // less than an hour
  if (diff < 3600 && diff >= 60)
    text += moment
      .utc(moment.duration(diff, "seconds").asMilliseconds())
      .format("m [min]")

  // less than a minute
  if (diff < 60)
    text += moment
      .utc(moment.duration(diff, "seconds").asMilliseconds())
      .format("s [s]")

  return text
}

export function getCamelCase(string) {
  if (!string) return ""
  const newString = string
    .replace(/[^a-zA-Z0-9]/g, " ")
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join("")
  return newString
}

export function randomKey(length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length)
}

// firestore-like id
// https://github.com/firebase/firebase-js-sdk/blob/master/packages/firestore/src/util/misc.ts#L40-L50
export function randomId(length = 20) {
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // let result = '';
  // for (let i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * characters.length));
  // }

  // Alphanumeric characters
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  // The largest byte value that is a multiple of `char.length`.
  const maxMultiple = Math.floor(256 / chars.length) * chars.length

  let autoId = ""
  while (autoId.length < length) {
    const bytes = randomBytes(40)
    for (let i = 0; i < bytes.length; ++i) {
      // Only accept values that are [0, maxMultiple), this ensures they can
      // be evenly mapped to indices of `chars` via a modulo operation.
      if (autoId.length < length && bytes[i] < maxMultiple) {
        autoId += chars.charAt(bytes[i] % chars.length)
      }
    }
  }
  return autoId
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// https://www.npmjs.com/package/random-words
export function randomWords(args) {
  const params = {
    exactly: 3,
    join: "-",
  }
  if (args && typeof args === "number") {
    params.exactly = args
  }
  if (args && typeof args === "object") {
    Object.assign(params, args)
  }
  return generate(params)
}
