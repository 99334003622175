import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, deleteDoc, doc} from "firebase/firestore"
import {useSiteStore} from "./site"

export const useFilesSiteImagesStore = defineStore("filesSiteImages", () => {
  // stores
  const siteStore = useSiteStore()

  // state
  const images = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const filesSiteImagesLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const siteId = siteStore.siteId
    const collectionRef = collection(firestore, `sites/${siteId}/images`)
    return await this.attach("images", collectionRef, serializeFiles)
  }

  async function unbind() {
    return await this.detach()
  }

  async function removeImage(args) {
    const imageId = args.id
    const siteId = siteStore.siteId
    const docRef = doc(firestore, `sites/${siteId}/images/${imageId}`)
    await deleteDoc(docRef)
    // TODO: remove file from storage
  }

  return {
    // state
    images,
    status,
    unsubscribe,

    // getters
    filesSiteImagesLoaded,

    // actions
    bind,
    unbind,
    removeImage,
  }
})

// extend with properties
const serializeFiles = (snapshot, data) => {
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _module: {
      value: "filebrowser",
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFilesSiteImagesStore, import.meta.hot))
}
