import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const usePlatformUsersStore = defineStore("platformUsers", () => {
  // stores
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const users = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const platformUsersLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const collectionRef = collection(firestore, `users`)
    return await this.attach("users", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    const userId = args.userId
    const batch = writeBatch(firestore)
    const userDocRef = doc(firestore, `users/${userId}`)
    const rolesDocRef = doc(firestore, `users/${userId}/auth/roles`)

    batch.set(userDocRef, {
      roles: rolesDocRef,
      config: {
        lang: "cs",
      },
      meta: {
        created: serverTimestamp(),
        updated: null,
        deleted: null,
      },
    })

    batch.set(rolesDocRef, {
      sites: {},
      shops: {},
      meta: {
        created: serverTimestamp(),
        updated: null,
        deleted: null,
      },
    })

    return await batch.commit()
  }

  async function update(args) {
    formSaveStateStore.markSaving(args.fieldId)

    const userId = args.data.id
    const docRef = doc(firestore, `users/${userId}`)
    const data = {
      config: args.data.config,
      name: args.data.name || "",
      surname: args.data.surname || "",
      note: args.data.note || "",
      "meta.updated": serverTimestamp(),
    }

    await updateDoc(docRef, data)
    formSaveStateStore.markSaved(args)
  }

  async function remove(args) {
    const userId = args.userId
    const docRolesRef = doc(firestore, `users/${userId}/auth/roles`)
    const docUserRef = doc(firestore, `users/${userId}`)
    await Promise.all([deleteDoc(docRolesRef), deleteDoc(docUserRef)])
    return
  }

  return {
    // state
    users,
    status,
    unsubscribe,

    // getters
    platformUsersLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlatformUsersStore, import.meta.hot))
}
