<script setup>
import FirebaseMessaging from "../components/FirebaseMessaging.vue"
import {useAppStore} from "../stores/app.js"
import {useUserStore} from "../stores/user.js"
import {useUserRolesStore} from "../stores/userRoles"
import {useSiteStore} from "../stores/site.js"
import {useSitesStore} from "../stores/sites.js"
import {useOrgStore} from "../stores/org"
import {useOrgSpacesStore} from "../stores/orgSpaces"
import {useOrgSpacesEnvironmentsStore} from "../stores/orgSpacesEnvironments"
import {storeToRefs} from "pinia"
import {watch, ref, computed, onMounted} from "vue"
import {OneViewAdmin, OneButton} from "@jirizavadil/framework.one/ui"
import Logo from "../assets/img/logo/platformaone_logo.svg?url"
import LogoWhite from "../assets/img/logo/platformaone_logo_white.svg?url"
import LogoIcon from "../assets/img/logo/platformaone_icon.svg?url"
import LogoIconWhite from "../assets/img/logo/platformaone_icon_white.svg?url"
import {useRoute, useRouter} from "vue-router"
import {useAuth} from "@/composables/auth"
import langs from "../langs/index.js"
import ColorSchemeToggle from "../components/ColorSchemeToggle.vue"

const route = useRoute()
const router = useRouter()

const auth = useAuth()
const {allowed, logout} = auth

const appStore = useAppStore()
const userStore = useUserStore()
const userRolesStore = useUserRolesStore()
const siteStore = useSiteStore()
const sitesStore = useSitesStore()
const orgStore = useOrgStore()
const orgSpacesStore = useOrgSpacesStore()
const orgSpacesEnvironmentsStore = useOrgSpacesEnvironmentsStore()

const {app} = storeToRefs(appStore)
const {user, userAuth, userLoaded, userSelectedSiteId} = storeToRefs(userStore)
const {isPlatformAdmin} = storeToRefs(userRolesStore)
const {site, siteName, siteLoaded} = storeToRefs(siteStore)
const {sitesSorted, sitesPinnedSorted, sitesUnpinnedSorted} = storeToRefs(sitesStore)
const {org, orgLoaded} = storeToRefs(orgStore)
const {spaces, orgSpacesLoaded} = storeToRefs(orgSpacesStore)
const {environments, orgEnvironmentsLoaded} = storeToRefs(orgSpacesEnvironmentsStore)

// const loaded = computed(
//   () =>
//     userLoaded.value &&
//     siteLoaded.value &&
//     ((app.value.orgId && orgLoaded.value) || !app.value.orgId) &&
//     ((app.value.spaceId && orgSpacesLoaded.value) || !app.value.spaceId) &&
//     ((app.value.environmentId && orgEnvironmentsLoaded.value) || !app.value.environmentId)
// )

const loaded = computed(() => userLoaded.value && siteLoaded.value)

const menuItems = computed(() => [
  {
    title: "dashboard",
    icon: "Home",
    route: {name: "DashboardView", params: {siteName: siteName.value}},
    views: ["DashboardView"],
    minimumPermission: "site",
  },
  {
    title: langs.get("view-title-commerce-orders"),
    icon: "Inbox",
    route: {name: "ShopOrdersView", params: {siteName: siteName.value}},
    views: ["ShopOrdersView", "ShopOrderDetailView"],
    minimumPermission: "shop.orders.read",
  },
  {
    title: langs.get("view-title-commerce-carts"),
    icon: "ShoppingCart",
    route: {name: "ShopCartsView", params: {siteName: siteName.value}},
    views: ["ShopCartsView"],
    minimumPermission: "shop.carts.read",
  },
  {
    title: langs.get("view-title-booking"),
    icon: "CalendarCheck",
    route: {name: "BookingReservationsView", params: {siteName: siteName.value}},
    views: [
      "BookingView",
      "BookingReservationsView",
      "BookingReservationsAddView",
      "BookingReservationsDetailView",
      "BookingSlotsView",
      "BookingSlotsAddView",
      "BookingSlotsEditView",
      "BookingSlotsDetailView",
      "BookingServicesView",
      "BookingServicesAddView",
      "BookingServicesEditView",
      "BookingServicesDetailView",
      "BookingResourcesView",
      "BookingResourcesAddView",
      "BookingResourcesDetailView",
    ],
    minimumPermission: "site.booking.read",
    children: [
      {
        title: langs.get("view-title-booking-reservations"),
        icon: "CalendarCheck",
        route: {name: "BookingReservationsView", params: {siteName: siteName.value}},
        views: [
          "BookingReservationsView",
          "BookingReservationsAddView",
          "BookingReservationsDetailView",
        ],
        minimumPermission: "site.booking.read",
      },
      {
        title: langs.get("view-title-booking-slots"),
        icon: "CalendarClock",
        route: {name: "BookingSlotsView", params: {siteName: siteName.value}},
        views: [
          "BookingSlotsView",
          "BookingSlotsAddView",
          "BookingSlotsEditView",
          "BookingSlotsDetailView",
        ],
        minimumPermission: "site.booking.read",
      },
      {
        title: langs.get("view-title-booking-services"),
        icon: "ConciergeBell",
        route: {name: "BookingServicesView", params: {siteName: siteName.value}},
        views: [
          "BookingServicesView",
          "BookingServicesAddView",
          "BookingServicesEditView",
          "BookingServicesDetailView",
        ],
        minimumPermission: "site.booking.read",
      },
      {
        title: langs.get("view-title-booking-resources"),
        icon: "Album",
        route: {name: "BookingResourcesView", params: {siteName: siteName.value}},
        views: ["BookingResourcesView", "BookingResourcesAddView", "BookingResourcesDetailView"],
        minimumPermission: "site.booking.read",
      },
    ],
  },
  {
    title: langs.get("view-title-commerce-inventory"),
    icon: "Box",
    route: {name: "ShopInventoryView", params: {siteName: siteName.value}},
    views: ["ShopInventoryView", "ShopInventoryItemsDetailView", "ShopInventoryItemDetailView"],
    minimumPermission: "shop.inventory.read",
  },
  {
    title: langs.get("view-title-commerce-delivery-planning"),
    icon: "CalendarClock",
    route: {name: "ShopDeliveryPlanningView", params: {siteName: siteName.value}},
    views: ["ShopDeliveryPlanningView"],
    minimumPermission: "shop.deliveryplanning.read",
  },

  {
    title: langs.get("view-title-pages"),
    icon: "Earth",
    route: {name: "SitePagesView", params: {siteName: siteName.value}},
    views: ["SitePagesview", "SitePageSettingsView"],
    minimumPermission: "site.pages.read",
  },
  {
    title: langs.get("cms"),
    icon: "Database",
    route: {name: "CmsEntriesView", params: {siteName: siteName.value}},
    views: [
      "CmsView",
      "CmsEntriesView",
      "CmsEntriesAddView",
      "CmsEntriesVersionDetailView",
      "CmsModelsView",
      "CmsModelsAddView",
      "CmsModelsDetailView",
      "CmsModelsFieldsAddView",
      "CmsModelsFieldsDetailView",
      "CmsModelsFieldsView",
      "CmsTagsAddView",
      "CmsTagsView",
      "CmsTokensAddView",
      "CmsTokensView",
    ],
    minimumPermission: "site.cms.read",
    children: [
      {
        title: "models",
        icon: "LayoutPanelTop",
        route: {name: "CmsModelsView", params: {siteName: siteName.value}},
        views: [
          "CmsModelsView",
          "CmsModelsAddView",
          "CmsModelsDetailView",
          "CmsModelsFieldsAddView",
          "CmsModelsFieldsDetailView",
          "CmsModelsFieldsView",
        ],
      },
      {
        title: "entries",
        icon: "Text",
        route: {name: "CmsEntriesView", params: {siteName: siteName.value}},
        views: ["CmsEntriesView", "CmsEntriesAddView", "CmsEntriesVersionDetailView"],
        minimumPermission: "site.cms.read",
      },
      {
        title: "tokens",
        icon: "Puzzle",
        route: {name: "CmsTokensView", params: {siteName: siteName.value}},
        views: ["CmsTokensView", "CmsTokensAddView"],
        minimumPermission: "site.cms.read",
      },
      {
        title: "tags",
        icon: "Tag",
        route: {name: "CmsTagsView", params: {siteName: siteName.value}},
        views: ["CmsTagsView", "CmsTagsAddView"],
        minimumPermission: "site.cms.read",
      },
    ],
  },
  {
    title: langs.get("crm"),
    icon: "BriefcaseBusiness",
    route: {name: "CrmView", params: {siteName: siteName.value}},
    views: [
      "CrmView",
      "CrmObjectView",
      "CrmMailingView",
      "CrmMailingSettingsView",
      "CrmMailingTemplatesView",
      "CrmMailingTemplatesAddView",
      "CrmMailingTemplatesDetailView",
      "CrmMailingQueueView",
      "CrmMailingWorkersView",
      "CrmMailingWorkersDetailView",
      "CrmImportView",
    ],
    minimumPermission: "site.crm.read",
    children: [
      {
        title: "objects",
        icon: "Briefcase",
        route: {name: "CrmView", params: {siteName: siteName.value}},
        views: ["CrmView", "CrmObjectView"],
        minimumPermission: "site.crm.read",
      },
      {
        title: "mailing templates",
        icon: "Mail",
        route: {name: "CrmMailingTemplatesView", params: {siteName: siteName.value}},
        views: [
          "CrmMailingView",
          "CrmMailingTemplatesView",
          "CrmMailingTemplatesAddView",
          "CrmMailingTemplatesDetailView",
        ],
        minimumPermission: "site.crm.read",
      },
      // {
      //   title: "mailing projects",
      //   icon: "Mail",
      //   route: {name: "CrmMailingTemplatesView", params: {siteName: siteName.value}},
      //   views: [
      //     "CrmMailingView",
      //     "CrmMailingTemplatesView",
      //     "CrmMailingTemplatesAddView",
      //     "CrmMailingTemplatesDetailView",
      //   ],
      //   minimumPermission: "site.crm.read",
      // },
      {
        title: "mailing workers",
        icon: "Mail",
        route: {name: "CrmMailingWorkersView", params: {siteName: siteName.value}},
        views: [
          "CrmMailingView",
          "CrmMailingWorkersView",
          // "CrmMailingWorkersAddView",
          "CrmMailingWorkersDetailView",
        ],
        minimumPermission: "site.crm.read",
      },
      {
        title: "mailing queue",
        icon: "Mail",
        route: {name: "CrmMailingQueueView", params: {siteName: siteName.value}},
        views: ["CrmMailingView", "CrmMailingQueueView"],
        minimumPermission: "site.crm.read",
      },
      {
        title: "import",
        icon: "CloudUpload",
        route: {name: "CrmImportView", params: {siteName: siteName.value}},
        views: ["CrmImportView"],
        minimumPermission: "site.crm.read",
      },
    ],
  },
  {
    title: langs.get("view-title-commerce-fee-reports"),
    icon: "AreaChart",
    route: {name: "ShopFeeReportsView", params: {siteName: siteName.value}},
    views: ["ShopFeeReportsView"],
    minimumPermission: "shop.fees.read",
  },
  {
    title: langs.get("view-title-commerce-promocodes"),
    icon: "CirclePercent",
    route: {name: "ShopPromoCodesView", params: {siteName: siteName.value}},
    views: ["ShopPromoCodesView", "ShopPromoCodeDetailView", "ShopPromoCodeImportView"],
    minimumPermission: "platform",
  },
  {
    title: langs.get("view-title-commerce-giftcards"),
    icon: "Gift",
    route: {name: "ShopGiftCardsView", params: {siteName: siteName.value}},
    views: ["ShopGiftCardsView", "ShopGiftCardDetailView", "ShopGiftCardValidationView"],
    minimumPermission: "shop.giftcards.get",
  },
  {
    title: langs.get("view-title-commerce-fee-billing"),
    icon: "Wallet",
    route: {name: "ShopBillingView", params: {siteName: siteName.value}},
    views: ["ShopBillingView"],
    minimumPermission: "shop.fees.read",
  },
  {
    title: langs.get("view-title-commerce-settings"),
    icon: "Cog",
    route: {name: "ShopSettingsView", params: {siteName: siteName.value}},
    views: [
      "ShopSettingsView",
      "ShopSettingsCartView",
      "ShopSettingsDeliveryMethodView",
      "ShopSettingsDeliveryView",
      "ShopSettingsGeneralView",
      "ShopSettingsInvoicingView",
      "ShopSettingsPaymentView",
    ],
    minimumPermission: "shop.config.read",
  },
  {
    title: langs.get("view-title-commerce-export"),
    icon: "CloudDownload",
    route: {name: "ShopExportView", params: {siteName: siteName.value}},
    views: ["ShopExportView"],
    minimumPermission: "shop.config.read",
  },
  {
    title: langs.get("view-title-commerce-order-flow"),
    icon: "Workflow",
    route: {name: "ShopFlowView", params: {siteName: siteName.value}},
    views: ["ShopFlowView"],
    minimumPermission: "shop.config.read",
  },
  {
    title: langs.get("view-title-platform"),
    icon: "Bolt",
    route: {name: "PlatformView", params: {siteName: siteName.value}},
    views: [
      "PlatformView",
      "PlatformAddWizardView",
      "PlatformClientDetailView",
      "PlatformClientsView",
      "PlatformEventDetailView",
      "PlatformEventsView",
      "PlatformShopDetailView",
      "PlatformShopsView",
      "PlatformSiteDetailView",
      "PlatformSitesView",
      "PlatformSystemView",
      "PlatformUserAddView",
      "PlatformUserDetailView",
      "PlatformUsersView",
    ],
    minimumPermission: "platform",
  },
  // -----------------
  // {, params: {siteName: siteName.value}
])
const sidebarState = ref("expanded")

const sidebarProjects = computed(() => {
  const projects = []
  // pinned
  sitesPinnedSorted.value.map((site) => {
    projects.push({
      id: site.id,
      title: site.config.title.cs || site.config.title.en || site.name,
      subtitle: site.domains[0] || site.name,
      active: site.id === userSelectedSiteId.value,
      site,
      // route: {name: "DashboardView", params: {siteName: site.name}},
    })
  })

  projects.push({
    id: "separator",
  })

  // unpinned
  sitesUnpinnedSorted.value.map((site) => {
    projects.push({
      id: site.id,
      title: site.config.title.cs || site.config.title.en || site.name,
      subtitle: site.domains[0] || site.name,
      active: site.id === userSelectedSiteId.value,
      site,
      // route: {name: "DashboardView", params: {siteName: site.name}},
    })
  })

  return projects
})

const sidebarMenuItems = computed(() => {
  if (!userSelectedSiteId.value) {
    return []
  }
  const items = []
  const currentMenuItem = menuItems.value.find((item) => {
    return item.views.includes(route.name)
  })
  const currentMenuItemHasChildren = !!currentMenuItem?.children?.length

  // console.log({
  //   currentMenuItem,
  //   currentMenuItemHasChildren,
  //   currentRoute: route.name,
  //   siteName: siteName.value,
  // })

  // sub-routes
  if (currentMenuItemHasChildren) {
    // back
    items.push({
      title: langs.get("view-title-booking-back"),
      icon: "ArrowLeft",
      route: {name: "DashboardView", params: {siteName: siteName.value}},
    })
    currentMenuItem.children.forEach((child) => {
      const menuItem = {
        ...child,
        // dont include route when undefined
        route: siteName.value ? {name: child.route.name, params: {siteName: siteName.value}} : {},
        active: child.views.includes(route.name),
      }

      if (allowed(child.minimumPermission)) {
        items.push(menuItem)
      }
    })
  }
  // root
  else {
    menuItems.value.map((item) => {
      const menuItem = {
        ...item,
        // dont include route when undefined
        route: siteName.value ? {name: item.route.name, params: {siteName: siteName.value}} : {},
        active: item.views.includes(route.name),
      }

      if (allowed(item.minimumPermission)) {
        items.push(menuItem)
      }
    })
  }

  // console.log({items})
  return items
})

const viewAdminAttrs = computed(() => {
  if (["xShopOrdersView"].includes(route.name)) {
    return {
      mainScrollable: false,
      mainPadding: false,
    }
  } else {
    return {}
  }
})

const sidebarUser = computed(() => {
  return {
    title: user.value.name || "thy gracious name",
    subtitle: userAuth.value.email,
    imageURL: userAuth.value.photoURL,
  }
})

function onProjectSelected({project, e}) {
  // console.log("onProjectSelected", project, e)
  siteStore.onClick({site: project, e})
}

watch(
  userLoaded,
  (value) => {
    if (value) {
      // console.log("userLoaded", userLoaded.value)
      // console.log("userSelectedSiteId", userSelectedSiteId.value)
      // TODO: make this dynamic
      // set new org/space/env system (used for cms)
      // salabka (production | local_dev)
      // if (["CLPbVM5yMxX2xrBAsoCL", "x291zbwTyMOheOSYNKBj"].includes(userSelectedSiteId.value)) {
      //   console.log("setting salabka")
      //   app.value.orgId = "salabka"
      //   app.value.spaceId = "salabka-cz"
      //   app.value.environmentId = "main"
      // }
    }
  },
  {immediate: true}
)

watch(
  siteLoaded,
  async (value) => {
    if (value) {
      app.value.orgId = site.value.scope?.orgId
      app.value.spaceId = site.value.scope?.spaceId
      app.value.environmentId = site.value.scope?.environmentId
      // console.log("site loaded", site.value)
      await orgStore.bind()
      await orgSpacesStore.bind()
      orgSpacesEnvironmentsStore.bind()
    }
  },
  {immediate: true}
)

// TODO: remember selected space and environment in user settings
watch(
  orgSpacesLoaded,
  (value) => {
    if (value) {
      // console.log("org spaces loaded")

      // select first space
      app.value.spaceId = spaces.value?.[0]?.id
    }
  },
  {immediate: true}
)

watch(orgEnvironmentsLoaded, (value) => {
  if (value) {
    // console.log("org environments loaded")

    // select first environment
    app.value.environmentId = environments.value?.[0]?.id
  }
})
onMounted(() => {
  siteStore.bind()
})
</script>

<template>
  <OneViewAdmin
    :sidebarProjects
    :sidebarMenuItems
    :sidebarUser
    :mainScrollable="!['CrmObjectView', 'ShopGiftCardsView'].includes($route.name)"
    v-bind="viewAdminAttrs"
    @clickResizeHandle="sidebarState = $event"
    @projectSelected="onProjectSelected"
  >
    <template #logo>
      <div class="pb-4 pt-2" v-show="sidebarState === 'expanded'">
        <img :src="Logo" class="h-auto w-40 dark:hidden" />
        <img :src="LogoWhite" class="hidden h-auto w-40 opacity-50 dark:block" />
      </div>

      <div class="pb-6 pt-2" v-show="sidebarState === 'collapsed'">
        <img :src="LogoIcon" class="mx-auto h-auto w-4 dark:hidden" />
        <img :src="LogoIconWhite" class="mx-auto hidden h-auto w-4 opacity-50 dark:block" />
      </div>
    </template>

    <template #userPopup>
      <div class="p-0">
        <!-- mode -->
        <ColorSchemeToggle class="m-2" />

        <!-- profile -->
        <OneButton
          label="my profile"
          variant="primary"
          kind="naked"
          rounded="xl"
          align="start"
          fullWidth
          @action="router.push({name: 'UserView'})"
        />

        <!-- sign out -->
        <OneButton
          label="sign out"
          variant="error"
          kind="naked"
          rounded="xl"
          align="start"
          fullWidth
          @action="logout"
        />
      </div>
    </template>

    <template #main>
      <RouterView v-if="loaded" />
    </template>
  </OneViewAdmin>
  <FirebaseMessaging v-if="userLoaded" />
</template>
