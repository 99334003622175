//
//
//
//
//
//
//
//
//
// if you add/remove roles, update firestore.rules accordingly

/**
 * Service component for working with user roles/permissions
 */
export default {
  getAvailableRoles() {
    return {
      platform: {
        platform_admin: {
          title: "platform admin",
          desc: "can do all things",
        },
      },
      site: {
        site_owner: {
          title: "owner",
          desc: "can do anything with the site",
        },
        site_admin: {
          title: "admin",
          desc: "can edit & publish",
        },
        site_editor: {
          title: "editor",
          desc: "can edit content (can't publish)",
        },
        site_cms_editor: {
          title: "cms editor",
          desc: "can edit cms",
        },
        site_booking_manager: {
          title: "booking manager",
          desc: "can manage bookings",
        },
      },
      shop: {
        shop_owner: {
          title: "owner",
          desc: "can do anything with the shop",
        },
        shop_admin: {
          title: "admin",
          desc: "can edit anything & change config",
        },
        shop_orders_manager: {
          title: "orders manager",
          desc: "can process orders",
        },
        shop_inventory_manager: {
          title: "inventory manager",
          desc: "can edit inventory",
        },
        shop_delivery_manager: {
          title: "delivery manager",
          desc: "can edit delivery slots",
        },
        // TODO: properly split discounts and giftcards
        shop_promotion_manager: {
          title: "promotion manager",
          desc: "can edit promotions",
        },
        shop_giftcard_validator: {
          title: "giftcard validator",
          desc: "can validate known giftcard (w/o listing)",
        },
        shop_balance_admin: {
          title: "balance admin",
          desc: "can do anything with balances, companies & persons",
        },
      },
    }
  },

  getAvailablePermissions() {
    return {
      platform: {
        "platform.config.write": {
          desc: "edit platform config",
        },
      },
      site: {
        site: {
          desc: "access sites menu",
        },
        "site.pages.read": {
          desc: "view pages / blocks",
        },
        "site.pages.edit": {
          desc: "edit pages / blocks",
        },
        "site.pages.publish": {
          desc: "publish site",
        },
        "site.config.read": {
          desc: "read site settings",
        },
        "site.config.write": {
          desc: "edit site settings",
        },
      },
      shop: {
        shop: {
          desc: "access shop menu",
        },
        "shop.orders.read": {
          desc: "view orders",
        },
        "shop.orders.write": {
          desc: "process orders",
        },
        "shop.inventory.read": {
          desc: "view products",
        },
        "shop.inventory.write": {
          desc: "add / adit / remove produts",
        },
        "shop.deliveryplanning.read": {
          desc: "view delivery slots",
        },
        "shop.deliveryplanning.write": {
          desc: "add / edit / remove delivery slots",
        },
        "shop.fees.manage": {
          desc: "create & edit product fee categories (for platform admins)",
        },
        "shop.promotions.read": {
          desc: "view promotions",
        },
        "shop.promotions.write": {
          desc: "add / edit / remove promotions",
        },
        "shop.giftcards.get": {
          desc: "get giftcard by code",
        },
        "shop.giftcards.list": {
          desc: "list giftcards",
        },
        "shop.giftcards.validate": {
          desc: "validate giftcard",
        },
        "shop.orderstates.read": {
          desc: "view order states",
        },
        "shop.orderstates.write": {
          desc: "add / edit / remove order states",
        },
        "shop.config.read": {
          desc: "view shop settings",
        },
        "shop.config.write": {
          desc: "edit shop settings",
        },
        "shop.balance.read": {
          desc: "view balances",
        },
        "shop.identity.companies.read": {
          desc: "view companies",
        },
        "shop.identity.persons.read": {
          desc: "view persons",
        },
      },
    }
  },

  getUserPermissions(roles) {
    let result = []

    // godmode - can do all things
    if (roles.includes("platform_admin")) {
      result = [
        // sites
        "site",
        "site.pages.read",
        "site.pages.edit",
        "site.pages.publish",
        "site.config.read",
        "site.config.write",

        // shops
        "shop",
        "shop.orders.read",
        "shop.orders.write",
        "shop.inventory.read",
        "shop.inventory.write",
        "shop.deliveryplanning.read",
        "shop.deliveryplanning.write",
        "shop.fees.manage",
        "shop.fees.read",
        "shop.fees.write",
        "shop.promotions.read",
        "shop.promotions.write",
        "shop.giftcards.get",
        "shop.giftcards.list",
        "shop.giftcards.validate",
        "shop.orderstates.read",
        "shop.orderstates.write",
        "shop.config.read",
        "shop.config.write",
        "shop.balance.read",
        "shop.identity.companies.read",
        "shop.identity.persons.read",

        // platform
        "platform.config.write",
      ]
    }

    // SITES
    if (roles.includes("site_owner")) {
      result = [
        ...result,
        "site",
        "site.pages.read",
        "site.pages.edit",
        "site.pages.publish",
        "site.config.read",
        "site.config.write",
      ]
    }

    if (roles.includes("site_admin")) {
      result = [
        ...result,
        "site",
        "site.pages.read",
        "site.pages.edit",
        "site.pages.publish",
        "site.config.read",
        "site.config.write",
      ]
    }

    if (roles.includes("site_editor")) {
      result = [...result, "site", "site.pages.read", "site.pages.edit"]
    }

    if (roles.includes("site_booking_manager")) {
      result = [...result, "site", "site.booking.read", "site.booking.write"]
    }

    // CMS
    if (roles.includes("site_cms_editor")) {
      result = [...result, "site.cms.read", "site.cms.write"]
    }

    // SHOPS
    if (roles.includes("shop_owner")) {
      result = [
        ...result,
        "shop",
        "shop.orders.read",
        "shop.orders.write",
        "shop.inventory.read",
        "shop.inventory.write",
        "shop.deliveryplanning.read",
        "shop.deliveryplanning.write",
        "shop.fees.read",
        "shop.fees.write",
        "shop.promotions.read",
        "shop.promotions.write",
        "shop.giftcards.get",
        "shop.giftcards.list",
        "shop.giftcards.validate",
        "shop.orderstates.read",
        "shop.orderstates.write",
        "shop.config.read",
        "shop.config.write",
      ]
    }

    if (roles.includes("shop_admin")) {
      result = [
        ...result,
        "shop",
        "shop.orders.read",
        "shop.orders.write",
        "shop.inventory.read",
        "shop.inventory.write",
        "shop.deliveryplanning.read",
        "shop.deliveryplanning.write",
        "shop.fees.read",
        "shop.fees.write",
        "shop.promotions.read",
        "shop.promotions.write",
        "shop.giftcards.get",
        "shop.giftcards.list",
        "shop.giftcards.validate",
        "shop.orderstates.read",
        "shop.orderstates.write",
        "shop.config.read",
        "shop.config.write",
      ]
    }

    if (roles.includes("shop_orders_manager")) {
      result = [...result, "shop", "shop.orders.read", "shop.orders.write"]
    }

    if (roles.includes("shop_inventory_manager")) {
      result = [...result, "shop", "shop.inventory.read", "shop.inventory.write"]
    }

    if (roles.includes("shop_delivery_manager")) {
      result = [...result, "shop", "shop.deliveryplanning.read", "shop.deliveryplanning.write"]
    }

    if (roles.includes("shop_promotion_manager")) {
      result = [
        ...result,
        "shop",
        "shop.promotions.read",
        "shop.promotions.write",
        "shop.giftcards.get",
        "shop.giftcards.list",
        "shop.giftcards.validate",
      ]
    }

    if (roles.includes("shop_giftcard_validator")) {
      result = [...result, "shop", "shop.giftcards.get", "shop.giftcards.validate"]
    }

    if (roles.includes("shop_balance_admin")) {
      result = [
        ...result,
        "shop.balance.read",
        "shop.identity.companies.read",
        "shop.identity.persons.read",
      ]
    }

    return result
  },
}
