import {ref} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {computed} from "vue"

export const useEditorStore = defineStore("editor", () => {
  // state
  const previewLang = ref(null)
  const activeTab = ref("content")
  const previewSize = ref({
    index: 0,
    config: {},
  })
  const previewBackground = ref("bg-light")
  const previewScale = ref(100)
  const selectedBlockId = ref(null)
  const selectedBlockIndex = ref(null)
  const publishingState = ref({
    page: "waitingToBePublished", // publishing-inprogress | nothingToPublish | publishing-requested | waitingToBePublished | failed | published
    site: "init", // not yet implemented
  })

  // getters
  const showForm = computed(() => selectedBlockId?.value?.length > 0)

  // actions
  function setPreviewLang(args) {
    this.previewLang = args
    const iframe = window.document.getElementById("page-preview-iframe")
    const message = {
      action: "siteVar",
      siteVar: {},
    }
    iframe?.contentWindow.postMessage(message, "*")
  }

  return {
    // state
    previewLang,
    activeTab,
    previewSize,
    previewBackground,
    previewScale,
    selectedBlockId,
    selectedBlockIndex,
    publishingState,
    showForm,

    // actions
    setPreviewLang,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEditorStore, import.meta.hot))
}
