import * as Sentry from "@sentry/vue"
import {BrowserTracing} from "@sentry/tracing"
import {CaptureConsole as CaptureConsoleIntegration} from "@sentry/integrations"
import {router} from "@/router"

export default {
  install(app) {
    if (["staging", "production"].includes(import.meta.env.VITE_ENVIRONMENT)) {
      let integrations = [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          attachProps: true,
          logErrors: true, // passthrough to Vue logError handler
        }),
        new CaptureConsoleIntegration({
          // levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ["error", "warn"],
        }),
      ]

      // do not use Replay in Safari, because it throws CORS errors
      // https://github.com/getsentry/sentry-javascript/issues/6560
      if (!navigator.userAgent.search("Safari")) {
        integrations.push(
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          })
        )
      }

      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `app-admin@${__VERSION__}+${__BUILD__}`,
        environment: import.meta.env.VITE_ENVIRONMENT,
        debug: false,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations,
        denyUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Facebook flakiness
          /graph\.facebook\.com/i,
        ],
      })
    }
  },
}
