import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc, serverTimestamp, updateDoc} from "firebase/firestore"

export const useUserStore = defineStore("user", () => {
  // state
  const user = ref({})
  const userAuth = ref({})
  const userSignedIn = ref(null) // null - undetermined yet | true | false - determined
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const userLoaded = computed(() => status.value == "loaded")
  const userId = computed(() => user.value.id)
  const userConfig = computed(() => user.value.config)
  const userLang = computed(() => user.value.config?.lang)
  const userPinnedSites = computed(() => user.value.config?.pinned?.sites || [])
  const userSelectedSiteId = computed(() => user.value.config?.selectedSiteId)

  // actions
  async function bind(args) {
    const uid = args.userId
    const docRef = doc(firestore, `users/${uid}`)
    return await this.attach("user", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function update(args) {
    const uid = this.user.id
    const docRef = doc(firestore, `users/${uid}`)
    const data = {
      ...args.data,
      "meta.updated": serverTimestamp(),
    }
    console.log("updating user with data:", data)
    return await updateDoc(docRef, data)
  }

  async function updateSelectedSite(args) {
    if (args.siteId != this.user.config.selectedSiteId) {
      await this.update({data: {"config.selectedSiteId": args.siteId}})
    }
  }

  return {
    // state
    user,
    userAuth,
    userSignedIn,
    status,
    unsubscribe,

    // getters
    userLoaded,
    userId,
    userConfig,
    userLang,
    userPinnedSites,
    userSelectedSiteId,

    // actions
    bind,
    unbind,
    update,
    updateSelectedSite,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
