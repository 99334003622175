import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collectionGroup, doc, updateDoc, setDoc, query, where} from "firebase/firestore"
import {useShopStore} from "./shop"

export const useShopItemsStockStore = defineStore("shopItemsStock", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const stock = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemsStockLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collectionGroup(firestore, `stock`)
    const q = query(collectionRef, where("shopId", "==", shopId))
    return await this.attach("stock", q, serializeStock)
  }

  async function unbind() {
    return await this.detach()
  }

  async function updateCount(args) {
    const shopId = shopStore.shopId
    const productId = args.productId
    const stockId = args.stockId
    const productRef = doc(firestore, `shops/${shopId}/products/${productId}`)
    const stockRef = doc(firestore, `shops/${shopId}/products/${productId}/stock/${stockId}`)
    const stockData = {
      count: args.value,
      shopId,
    }
    const productData = {
      "config.quantity.stock.available": args.value > 0,
    }
    await Promise.all([setDoc(stockRef, stockData), updateDoc(productRef, productData)])
  }

  return {
    // state
    stock,
    status,
    unsubscribe,

    // getters
    shopItemsStockLoaded,

    // actions
    bind,
    unbind,
    updateCount,
  }
})

// extend stock with custom local properties
const serializeStock = (snapshot, data) => {
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _itemId: {
      value: snapshot.ref.parent.parent.id,
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemsStockStore, import.meta.hot))
}
