import {createApp} from "vue"
import {router} from "./router"
import App from "./App.vue"
import ShortKey from "vue3-shortkey"
import ods from "@jirizavadil/one-design-system"
import globalComponents from "@platformaone/common/app/plugins/globalComponents"
import analytics from "./plugins/analytics"
import localization from "@platformaone/common/app/plugins/localization"
import datePrototypes from "./plugins/datePrototypes"
import sentry from "./plugins/sentry"
import {createPinia} from "pinia"

// FIXME: these two imports cause "Uncaught Error: Component firestore has not been registered yet" on development and fail on production
// try https://vitejs.dev/guide/build.html#library-mode
// import {attachFirestore} from "@platformaone/common/app/stores/plugins/attachFirestore.js"
// import {resetState} from "@platformaone/common/app/stores/plugins/resetState.js"
import {attachFirestore} from "./plugins/attachFirestore.js"
import {resetState} from "./plugins/resetState.js"

import "@/themes/themes.less"

window.app = {
  version: __VERSION__,
  build: __BUILD__,
  commit: __RELEASE_SHA__,
  date: __BUILD_DATE__,
}

const pinia = createPinia()
pinia.use(attachFirestore)
pinia.use(resetState)

const app = createApp(App)
app.use(sentry)
app.use(pinia)
app.use(ods)
app.use(globalComponents)
app.use(analytics)
app.use(localization)
app.use(datePrototypes)
app.use(ShortKey)
app.use(router)

// framework.one UI component overrides
const OneUIConfig = {
  Tag: {
    classes: {
      tag: {
        propsClasses: {
          status: {
            green: {
              kindSolid: "bg-green-200",
            },
            orange: {
              kindSolid: "bg-orange-200",
            },
          },
        },
      },
      text: {
        propsClasses: {
          status: {
            green: {
              kindSolid: "text-green-500",
            },
            orange: {
              kindSolid: "text-orange-500",
            },
          },
        },
      },
    },
  },
}
app.provide("one-ui-config", OneUIConfig)

app.mount("#app")
