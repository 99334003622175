// templates
let t = {
  image: {
    cs: {
      contentType: "image/jpeg",
      imageId: "placeholder",
    },
    en: {
      contentType: "image/jpeg",
      imageId: "placeholder",
    },
    langsSync: true,
  },
  imagePortrait: {
    cs: {
      contentType: "image/jpeg",
      imageId: "placeholder-portrait",
    },
    en: {
      contentType: "image/jpeg",
      imageId: "placeholder-portrait",
    },
    langsSync: true,
  },
  images: [
    {
      contentType: "image/jpeg",
      imageId: "placeholder",
    },
    {
      contentType: "image/jpeg",
      imageId: "placeholder",
    },
    {
      contentType: "image/jpeg",
      imageId: "placeholder",
    },
  ],
  logo: {
    regular: {
      cs: {
        contentType: "image/svg+xml",
        imageId: "placeholder-logo",
      },
      en: {
        contentType: "image/svg+xml",
        imageId: "placeholder-logo",
      },
      langsSync: true,
    },
    white: {
      cs: {
        contentType: "image/svg+xml",
        imageId: "placeholder-logo-white",
      },
      en: {
        contentType: "image/svg+xml",
        imageId: "placeholder-logo-white",
      },
      langsSync: true,
    },
    black: {
      cs: {
        contentType: "image/svg+xml",
        imageId: "placeholder-logo-black",
      },
      en: {
        contentType: "image/svg+xml",
        imageId: "placeholder-logo-black",
      },
      langsSync: true,
    },
  },
  header: {
    cs: "Nadpis",
    en: "Header",
  },
  caption: {
    cs: "Podnadpis",
    en: "Subtitle",
  },
  paragraph: {
    cs: "V žádné organismů tras u pohromy milionů uvažovat malých, myslí běžně však řekl by procent nejlépe stavu tutanchamónovy akci. Bohatých nikoho s vodě turistiky o druh v snažil, akcí k další o zanesl začít apokalyptická, led začal vládců propouští o kameny jemu kráse lidském, s zuří rodičů drahého předpokládanou. OSN mor jí sloní. Vy ráno dne indiánský a cípu rodičů horečky, křídy by období zimu EU přepůlené izolaci pak bojem u katastrofou i interpretace jiné mraky. Izolované cíle pepřem financovala velkou, přetvořit jiného nejen výzkumného lyžaře nemohou u živin i liší cítíte vybuchl ve nakažený rozšiřování.",
    en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus in turpis suscipit facilisis. Vivamus malesuada, eros vitae iaculis porttitor, libero velit condimentum arcu, eget aliquam nulla neque vel odio. Morbi consequat diam at dictum tincidunt. Nullam tellus nulla, scelerisque vitae congue nec, semper eu quam. Curabitur a ex ullamcorper, ultrices justo a, varius est. Phasellus ac imperdiet purus, sit amet consequat mauris. Nunc id blandit arcu.",
  },
  personName: {
    cs: "Jan Novák",
    en: "John Smith",
  },
  personCaption: {
    cs: "ředitel",
    en: "director",
  },
  itemTitle: {
    cs: "Položka",
    en: "Item",
  },
  gridListItem: {
    label: {
      cs: "Popis",
      en: "Label",
    },
    value: {
      cs: "Hodnota",
      en: "Value",
    },
  },
  phone: {
    cs: "+420 123 456 789",
    en: "+420 123 456 789",
  },
  email: {
    cs: "muj@email.cz",
    en: "my@email.com",
  },
  facebook: {
    cs: "mujfacebook",
    en: "myfacebook",
  },
  instagram: {
    cs: "mujinstagram",
    en: "myinstagram",
  },
  linkedin: {
    cs: "mujlinkedin",
    en: "mylinkedin",
  },
  twitter: {
    cs: "mujtwitter",
    en: "mytwitter",
  },
  github: {
    cs: "mujgithub",
    en: "mygithub",
  },
  dribbble: {
    cs: "mujdribbble",
    en: "mydribbble",
  },
  tripadvisor: {
    cs: "mujtripadvisor",
    en: "mytripadvisor",
  },
}

export default {
  t,
  "main/cards": {
    block: {
      config: {
        variant: 1,
      },
    },
    content: {
      content: {
        items: [
          {
            link: {},
            heading: t.header,
            caption: t.caption,
            logo: t.logo.white,
            backgroundImage: t.image,
          },
        ],
      },
      stylesConfig: {
        heading: {
          textColor: {
            universal: {
              unit: null,
              value: "white",
            },
          },
        },
        caption: {
          textColor: {
            universal: {
              unit: null,
              value: "white",
            },
          },
        },
      },
    },
  },
  "main/button": {
    block: {
      config: {},
    },
    content: {
      content: {
        ctaButton: {},
      },
      stylesConfig: {},
    },
  },
  "main/logoCloud": {
    block: {
      config: {},
    },
    content: {
      content: {
        items: [
          {
            link: {},
            image: t.logo.black,
          },
        ],
      },
      stylesConfig: {},
    },
  },
  "main/popup": {
    block: {
      config: {
        showCloseButton: false,
        popupFrequency: "everyTime",
      },
    },
    content: {
      content: {
        text: {
          cs: "text",
          en: "text",
        },
        button: {
          cs: "tlačítko",
          en: "button",
        },
      },
      stylesConfig: {},
    },
  },
  "main/footer": {
    block: {
      config: {
        variant: 1,
      },
    },
    content: {
      content: {
        logo: t.logo.regular,
        caption: t.caption,
        phone: t.phone,
        email: t.email,
        facebook: t.facebook,
        instagram: t.instagram,
        linkedin: t.linkedin,
        twitter: t.twitter,
        github: t.github,
        dribbble: t.dribbble,
        tripadvisor: t.tripadvisor,
      },
      stylesConfig: {},
    },
  },
  "main/gallery": {
    block: {
      config: {},
    },
    content: {
      content: {
        images: t.images,
        layout: "masonry",
      },
      stylesConfig: {},
    },
  },
  "main/instagram": {
    block: {
      config: {
        showLogo: true,
        maxImages: 8,
      },
    },
    content: {
      content: {},
      stylesConfig: {
        socialInstagram: {
          columns: {
            mobile: {
              value: 2,
              unit: null,
            },
            tablet: {
              value: 4,
              unit: null,
            },
            desktop: {
              value: 6,
              unit: null,
            },
          },
          gap: {
            mobile: {
              value: 2,
              unit: null,
            },
            tablet: {
              value: 2,
              unit: null,
            },
            desktop: {
              value: 2,
              unit: null,
            },
          },
        },
      },
    },
  },
  "main/team": {
    block: {
      config: {
        variant: 1,
      },
    },
    content: {
      content: {
        items: [
          {
            name: t.personName,
            caption: t.personCaption,
            portrait: t.imagePortrait,
          },
        ],
      },
      stylesConfig: {
        heading: {
          textColor: {
            universal: {
              unit: null,
              value: "default",
            },
          },
        },
        caption: {
          textColor: {
            universal: {
              unit: null,
              value: "default",
            },
          },
        },
      },
    },
  },
  "main/gridList": {
    block: {
      config: {},
    },
    content: {
      content: {
        items: [t.gridListItem, t.gridListItem, t.gridListItem],
      },
      stylesConfig: {},
    },
  },
  "main/header": {
    block: {
      config: {},
    },
    content: {
      content: {
        header: t.header,
        level: 2,
      },
      stylesConfig: {
        container: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
  "main/hero": {
    block: {
      config: {
        backgroundKind: "image",
        slideshow: {
          speed: 2500,
          delay: 5000,
        },
      },
    },
    content: {
      content: {
        heading: t.header,
        caption: t.caption,
        background: t.image,
        logo: t.logo.white,
        ctaButton: {},
      },
      stylesConfig: {
        heading: {
          textColor: {
            universal: {
              unit: null,
              value: "white",
            },
          },
          textAlign: {
            mobile: {
              unit: null,
              value: "center",
            },
            tablet: {
              unit: null,
              value: "center",
            },
            desktop: {
              unit: null,
              value: "center",
            },
          },
        },
        caption: {
          textColor: {
            universal: {
              unit: null,
              value: "white",
            },
          },
          textAlign: {
            mobile: {
              unit: null,
              value: "center",
            },
            tablet: {
              unit: null,
              value: "center",
            },
            desktop: {
              unit: null,
              value: "center",
            },
          },
        },
      },
    },
  },
  "main/menu": {
    block: {
      config: {
        variant: 1,
        openedSize: "small",
        showBackButton: true, // non-homepage only
        backButtonPosition: "left",
        showHamburgerButton: true,
        hamburgerWrapPosition: "left",
        logoPosition: "center",
        fixed: true,
        autohide: true,
        background: "transparent",
        backgroundOnScroll: "transparent",
        cartButtonIcon: "Cart1", // commerce only
        cartButtonPosition: "right", // commerce only
      },
    },
    content: {
      content: {
        logo: t.logo.regular,
        menuItems: [
          {
            title: t.itemTitle,
            kind: "page",
            link: {},
          },
        ],
      },
      stylesConfig: {},
    },
  },
  "main/separator": {
    block: {
      config: {},
    },
    content: {
      content: {},
      stylesConfig: {},
    },
  },
  "main/singleImage": {
    block: {
      config: {},
    },
    content: {
      content: {
        image: t.image,
      },
      stylesConfig: {},
    },
  },
  "main/singleVideo": {
    block: {
      config: {},
    },
    content: {
      content: {
        service: "youtube",
        videoKind: "video",
        controls: true,
        allowFullscreen: true,
      },
      stylesConfig: {},
    },
  },
  "main/spacer": {
    block: {
      config: {},
    },
    content: {
      content: {
        height: 10,
      },
      stylesConfig: {},
    },
  },
  "main/text": {
    block: {
      config: {},
    },
    content: {
      content: {
        paragraph: t.paragraph,
      },
      stylesConfig: {
        textAlign: "left",
      },
    },
  },
  "main/textWithHeader": {
    block: {
      config: {},
    },
    content: {
      content: {
        header: t.header,
        level: 2,
        paragraph: t.paragraph,
      },
      stylesConfig: {
        container: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
  "main/imageText": {
    block: {
      config: {},
    },
    content: {
      content: {
        header: t.header,
        headerLevel: 2,
        paragraph: t.paragraph,
        image: t.image,
        buttonLabel: {
          cs: "tlačítko",
          en: "button",
        },
        link: {
          kind: "page",
          pageId: null,
          url: {},
        },
      },
      stylesConfig: {
        imageAlign: "left",
        textAlign: "left",
        textVerticalAlign: "top",
        imageBlobMask: false,
      },
    },
  },
  "commerce/productDetail": {
    block: {
      config: {
        previewImageFit: "cover",
        height: "fullscreen",
        displayPriceInAddToCartBtn: true,
        displayAddToCartAmount: false,
        descriptionPosition: "end",
      },
    },
    content: {
      content: {},
      stylesConfig: {
        container: {},
      },
    },
  },
  "commerce/productListing": {
    block: {
      config: {
        layout: "grid",
        productDetailDisplay: "page",
        filterByTags: [],
        showAllButton: false,
        showItemsAvailable: true,
        showItemsUnavailable: true,
        showItemsForPreorder: true,
        showAddToCartButton: true,
        limit: false,
        limitCount: 1,
        previewImageRatio: "square",
      },
    },
    content: {
      content: {},
      stylesConfig: {},
    },
  },
  "commerce/productImages": {
    block: {
      config: {},
    },
    content: {
      content: {},
      stylesConfig: {},
    },
  },
  "commerce/productPriceSlider": {
    block: {
      config: {},
    },
    content: {
      content: {},
      stylesConfig: {
        container: {},
      },
    },
  },
}
