import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {doc, serverTimestamp, updateDoc} from "firebase/firestore"
import {useSiteStore} from "./site"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const useShopStore = defineStore("shop", () => {
  // stores
  const siteStore = useSiteStore()
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const shop = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopLoaded = computed(() => status.value == "loaded")
  const shopId = computed(() => shop.value.id)
  const shopLangsAvailable = computed(() => shop.value.config?.language?.available)
  const shopLangDefault = computed(() => shop.value.config?.language?.default)
  const shopPaymentTaxes = computed(() => shop.value.payment?.taxes)
  const shopDeliveryDefaultSlots = computed(() => shop.value.deliveryDefaultSlots)
  const shopDeliveryMethods = computed(() => shop.value.delivery?.methods)
  const shopDeliveryMethodsEnabled = computed(() =>
    shop.value.delivery?.methods?.filter((i) => i && i.enabled)
  )
  const shopDeliveryMethodsEnabledCodes = computed(() => {
    let c = []
    shop.value.delivery?.methods?.forEach((i) => {
      if (i && i.enabled) c.push(i.code)
    })
    return c
  })
  const shopCountries = computed(() => shop.value.countries)
  const shopCountriesEnabled = computed(() => shop.value.countries?.filter((i) => i && i.enabled))
  const shopCountriesEnabledCodes = computed(() => {
    let c = []
    shop.value.countries?.forEach((i) => {
      if (i && i.enabled) c.push(i.code)
    })
    return c
  })
  const shopCurrencies = computed(() => shop.value.currencies)
  const shopCurrenciesEnabled = computed(() => shop.value.currencies?.filter((i) => i && i.enabled))
  const shopCurrenciesEnabledCodes = computed(() => {
    let c = []
    shop.value.currencies?.forEach((i) => {
      if (i && i.enabled) c.push(i.code)
    })
    return c
  })
  const shopPaymentMethods = computed(() => shop.value.payment?.methods)
  const shopPaymentMethodsEnabled = computed(() =>
    shop.value.payment?.methods?.filter((i) => i && i.enabled)
  )
  const shopPaymentMethodsEnabledCodes = computed(() => {
    let c = []
    shop.value.payment?.methods.forEach((i) => {
      if (i && i.enabled) c.push(i.code)
    })
    return c
  })

  // actions
  async function bind(args) {
    console.log("bindShop()", args)
    const shopId = args?.shopId || siteStore.siteShopId
    const docRef = doc(firestore, `shops/${shopId}`)
    return await this.attach("shop", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function bindUserSelectedShop() {
    let shopId = siteStore.site.shopId
    // console.log("bindUserSelectedShop()", shopId, siteStore.site.id)
    if (this.status != "init") await this.unbind()
    if (!shopId) return
    await this.bind({shopId})
  }

  async function update(args) {
    formSaveStateStore.markSaving(args)

    const shopId = siteStore.site.shopId
    const data = {
      ...args.data,
      "meta.updated": serverTimestamp(),
    }

    const shopRef = doc(firestore, `shops/${shopId}`)
    await updateDoc(shopRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  return {
    // state
    shop,
    status,
    unsubscribe,

    // getters
    shopLoaded,
    shopId,
    shopLangsAvailable,
    shopLangDefault,
    shopPaymentTaxes,
    shopDeliveryDefaultSlots,
    shopDeliveryMethods,
    shopDeliveryMethodsEnabled,
    shopDeliveryMethodsEnabledCodes,
    shopCountries,
    shopCountriesEnabled,
    shopCountriesEnabledCodes,
    shopCurrencies,
    shopCurrenciesEnabled,
    shopCurrenciesEnabledCodes,
    shopPaymentMethods,
    shopPaymentMethodsEnabled,
    shopPaymentMethodsEnabledCodes,

    // actions
    bind,
    unbind,
    bindUserSelectedShop,
    update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopStore, import.meta.hot))
}
