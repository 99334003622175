import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection, collectionGroup, getDocs, writeBatch, query, where} from "firebase/firestore"
import {useShopStore} from "./shop"

export const useShopItemsHoldsStore = defineStore("shopItemsHolds", () => {
  // stores
  const shopStore = useShopStore()

  // state
  const holds = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopItemsHoldsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collectionGroup(firestore, `holds`)
    const q = query(collectionRef, where("shopId", "==", shopId))
    return await this.attach("holds", q, serializeHolds)
  }

  async function unbind() {
    return await this.detach()
  }

  async function reset(args) {
    const shopId = shopStore.shopId
    const productId = args.productId
    const kind = args.kind
    const holdsRef = collection(firestore, `shops/${shopId}/products/${productId}/holds`)
    const q = query(holdsRef, where("kind", "==", kind))
    const holds = await getDocs(q)
    const batch = writeBatch(firestore)
    holds.forEach((h) => {
      batch.delete(h.ref)
    })
    return await batch.commit()
  }

  return {
    // state
    holds,
    status,
    unsubscribe,

    // getters
    shopItemsHoldsLoaded,

    // actions
    bind,
    unbind,
    reset,
  }
})

// extend holds with custom local properties
const serializeHolds = (snapshot, data) => {
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _itemId: {
      value: snapshot.ref.parent.parent.id,
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopItemsHoldsStore, import.meta.hot))
}
