import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore, functions} from "@/firebase/index.js"
import {httpsCallable} from "firebase/functions"
import {
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore"
import {useFormSaveStateStore} from "@/stores/formSaveState.js"

export const usePlatformClientsStore = defineStore("platformClients", () => {
  // stores
  const formSaveStateStore = useFormSaveStateStore()

  // state
  const clients = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const platformClientsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const collectionRef = collection(firestore, `clients`)
    return await this.attach("clients", collectionRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function add() {
    const manageFn = httpsCallable(functions, "calls-admin-platform-manage")
    const response = await manageFn({action: "create-client"})
    return {clientId: response.data.clientId}
  }

  async function update(args) {
    formSaveStateStore.markSaving(args.fieldId)

    const docRef = doc(firestore, `clients/${args.data.id}`)
    const data = {
      name: args.data.name || "",
      note: args.data.note || "",
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(docRef, data)

    formSaveStateStore.markSaved(args)
    return
  }

  async function remove(args) {
    const clientId = args.clientId
    const docRef = doc(firestore, `clients/${clientId}`)
    return await deleteDoc(docRef)
  }

  return {
    // state
    clients,
    status,
    unsubscribe,

    // getters
    platformClientsLoaded,

    // actions
    bind,
    unbind,
    add,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlatformClientsStore, import.meta.hot))
}
