import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore} from "@/firebase/index.js"
import {collection} from "firebase/firestore"
import {useSiteStore} from "./site"

export const useFilesSiteVideosStore = defineStore("filesSiteVideos", () => {
  // stores
  const siteStore = useSiteStore()

  // state
  const videos = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const filesSiteVideosLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const siteId = siteStore.siteId
    const collectionRef = collection(firestore, `sites/${siteId}/videos`)
    return await this.attach("videos", collectionRef, serializeFiles)
  }

  async function unbind() {
    return await this.detach()
  }

  return {
    // state
    videos,
    status,
    unsubscribe,

    // getters
    filesSiteVideosLoaded,

    // actions
    bind,
    unbind,
  }
})

// extend with properties
const serializeFiles = (snapshot, data) => {
  return Object.defineProperties(data, {
    id: {
      value: snapshot.id,
      writable: false,
      enumerable: false,
    },
    _module: {
      value: "filebrowser",
      writable: false,
      enumerable: false,
    },
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFilesSiteVideosStore, import.meta.hot))
}
